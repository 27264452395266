'use client';
import React, { useEffect, useState } from 'react';
import { Button, Upload } from 'antd';
import type { UploadFile } from 'antd';
import toast from 'react-hot-toast';
import { useFormContext } from 'react-hook-form';

interface Props {
  checkSentEmail: boolean;
}

const BtnAttachFile = ({ checkSentEmail }: Props) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { setValue, watch, handleSubmit, reset } = useFormContext();

  // fileId: generateUUID(),
  // fileInfo: file,
  // url: URL.createObjectURL(file),
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleDeleteFile = (index: number) => {
    //
    setFileList((arr) => arr.filter((val, ind) => ind !== index));
  };

  useEffect(() => {
    if (checkSentEmail) setFileList([]);
  }, [checkSentEmail]);

  useEffect(() => {
    setValue('attachedFiles', fileList);
  }, [fileList]);

  const beforeUpload = (file, fileListBefore) => {
    if (fileList.length + fileListBefore.length > 3) {
      toast.error(`Chỉ được hiển thị tối đa 3 file`);
      return Upload.LIST_IGNORE;
    }
    if (file.type !== 'image/jpeg' && file.type !== 'application/pdf') {
      toast.error(`File không đúng định dạng`);
      return Upload.LIST_IGNORE;
    }
    if (file.size > 1 * 1024 * 1024) {
      // 1MB limit
      toast.error(`Tải file thất bại (vượt quá 1MB)`);
      return Upload.LIST_IGNORE;
    }
    return true;
  };
  const customRequest = ({ onSuccess }) => {
    // Disable actual uploading and handle manually
  };

  return (
    <>
      <div className='flex gap-5'>
        {fileList.map((file, i) => {
          return (
            <div className='flex gap-1' key={i}>
              <p>{file?.name}</p>
              <p
                className='text-red-7 cursor-pointer font-semibold'
                onClick={() => handleDeleteFile(i)}>
                x
              </p>
            </div>
          );
        })}
      </div>
      <Upload
        customRequest={() => customRequest}
        fileList={fileList}
        onChange={onChange}
        showUploadList={false}
        multiple
        beforeUpload={beforeUpload}
        accept='image/jpeg, application/pdf'>
        <Button>Chọn file đính kèm</Button>
      </Upload>
    </>
  );
};

export default BtnAttachFile;
