import { AdminChangeUserPasswordRes } from '@api/partnership/web_partnership/partnership_user_pb';
import { Button, ButtonLoading, CloseIcon, Form, FormInput } from '@common-ui';
import { IModal } from '@sky-booking-config/types';
import { Modal } from 'antd';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

type Props = {
  modalInfo: TModalInfo;
  isLoadingChangePassword?: boolean;
  handleUpdateModalInfo: (modalInfo: TModalInfo) => void;
  handleChangePassword: (
    newPassword: string
  ) => Promise<AdminChangeUserPasswordRes.AsObject>;
};

export enum ESwitchModal {
  NONE = 'NONE',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
}

export type TModalInfo = IModal & {
  state: ESwitchModal;
  userId: string;
};

type TFormModal = { newPassword: string };

const ModalChangePassword = ({
  modalInfo,
  handleChangePassword,
  handleUpdateModalInfo,
  isLoadingChangePassword,
}: Props) => {
  const changePasswordMethod = useForm<TFormModal>({
    defaultValues: {
      newPassword: '',
    },
  });

  const handleSubmit = async (data: TFormModal) => {
    try {
      const { isSuccess, errorCode } = await handleChangePassword(
        data.newPassword
      );

      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }
      toast.success('Cập nhật mật khẩu thành công!');
      handleUpdateModalInfo({ ...modalInfo, open: false });
      changePasswordMethod.reset();
    } catch (error) {
      //   const errorMessage = getApiErrorMessages(error)[0];
      toast.error('Lỗi cập nhật mật khẩu!');
      handleUpdateModalInfo({ ...modalInfo, open: false });
    }
  };

  const onOk = async (modalInfo: TModalInfo) => {
    switch (modalInfo.state) {
      case ESwitchModal.CHANGE_PASSWORD:
        await changePasswordMethod.handleSubmit(handleSubmit)();
        break;
      default:
        break;
    }
  };
  const onCancel = (modalInfo: TModalInfo) => {
    switch (modalInfo.state) {
      case ESwitchModal.CHANGE_PASSWORD:
        handleUpdateModalInfo({ ...modalInfo, open: false, userId: '' });
        break;

      default:
        break;
    }
  };
  const genModalTitle = (modalInfo: TModalInfo) => {
    switch (modalInfo.state) {
      case ESwitchModal.CHANGE_PASSWORD:
        return 'Đổi mật khẩu';

      default:
        return;
    }
  };
  const genModalContent = (modalInfo: TModalInfo) => {
    switch (modalInfo.state) {
      case ESwitchModal.CHANGE_PASSWORD:
        return (
          <Form
            methods={changePasswordMethod}
            onSubmit={handleSubmit}
            className='flex flex-col gap-y-5'>
            <FormInput
              name='newPassword'
              label='Mật khẩu'
              placeholder='Nhập mật khẩu'
              rules={{
                required: 'Nhập mật khẩu',
                validate: {
                  minLength: (value: string) =>
                    value.length >= 8 || 'Tối thiểu 8 kí tự',
                },
                onChange(event) {
                  changePasswordMethod.clearErrors('newPassword');
                },
              }}
            />
          </Form>
        );
      default:
        return <></>;
    }
  };
  const genModalFooter = (modalInfo: TModalInfo) => {
    switch (modalInfo.state) {
      case ESwitchModal.CHANGE_PASSWORD:
        return (
          <div className='flex w-full space-x-2.5 whitespace-nowrap'>
            <Button
              disabled={isLoadingChangePassword}
              theme='neutral'
              className='flex-1'
              onClick={() => onCancel(modalInfo)}>
              Hủy bỏ
            </Button>
            <ButtonLoading
              loading={isLoadingChangePassword}
              isShowChildWhenLoading
              className='flex-1'
              onClick={() => onOk(modalInfo)}>
              Cập nhật
            </ButtonLoading>
          </div>
        );

      default:
        return <></>;
    }
  };

  return (
    <Modal
      centered
      open={modalInfo.open}
      title={genModalTitle(modalInfo)}
      footer={genModalFooter(modalInfo)}
      closeIcon={
        modalInfo.closable ? (
          <CloseIcon
            className='w-5 h-5 text-[#999999]'
            onClick={() => onCancel(modalInfo)}
          />
        ) : null
      }>
      {genModalContent(modalInfo)}
    </Modal>
  );
};

export default ModalChangePassword;
