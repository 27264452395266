import { HotelServiceClient } from '@api/hotel/web/hotel_grpc_web_pb';
import {
  GetBookingSelectionReq,
  GetBookingSelectionRes,
} from '@api/hotel/web/hotel_pb';

import { BaseApi, BaseApiOptions } from '@core/grpc-client';

export class HotelServiceApiWeb extends BaseApi<HotelServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new HotelServiceClient(this.url, null, this.optsDev);
  }

  getBookingSelection = ({
    selectionId,
  }: GetBookingSelectionReq.AsObject): Promise<GetBookingSelectionRes.AsObject> => {
    const req = new GetBookingSelectionReq();
    req.setSelectionId(selectionId);
    return this.grpc<
      GetBookingSelectionReq,
      GetBookingSelectionRes,
      GetBookingSelectionRes.AsObject
    >(this.serviceClient.getBookingSelection, req, {});
  };
}

export const hotelApiServiceWeb = new HotelServiceApiWeb();
export default hotelApiServiceWeb;
