import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TPagination } from './useManageOfficeId';
import {
  LabelTransferBookingRequestStatus,
} from '@sky-booking-config/utils';
import cn from 'classnames';
import {
  ETransferBookingRequestStatus,
  ITransferBookingRequestInfo,
} from '@tixlabs/grpc-client';
import { useTime } from '@sky-booking-config/hooks/internals';

type Props = {
  data: ITransferBookingRequestInfo[];
  isLoading?: boolean;
  pagination: TPagination;
  onChangePage: (pageNumber: number) => Promise<void>;
};

const TitleColumns = ({ title }: { title: string }) => {
  return (
    <span className='font-semibold leading-[25px] text-neutral-7'>{title}</span>
  );
};

const StatusTransferRequest = ({
  status,
}: {
  status: ETransferBookingRequestStatus;
}) => {
  return (
    <span
      className={cn('font-bold', {
        'text-green-7':
          status ===
          ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_ISSUED,
        'text-blue-500':
          status ===
          ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_PROCESSING,
        'text-red-500':
          status ===
          ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_CANCELED,
        'text-orange-500':
          status ===
          ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_PENDING,
        'text-orange-300':
          status ===
          ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_EXPIRED,
        'text-red-300':
          status ===
          ETransferBookingRequestStatus.TRANSFERBOOKINGREQUESTSTATUS_REJECTED,
      })}>
      {LabelTransferBookingRequestStatus[status]}
    </span>
  );
};

export const TableContent = ({
  data,
  isLoading,
  onChangePage,
  pagination,
}: Props) => {
  const { formatDateTime } = useTime();
  // const { userData } = useUserStore();
  const columns: ColumnsType<ITransferBookingRequestInfo> = [
    {
      key: 'requestId',
      dataIndex: 'requestId',
      title: <TitleColumns title='RequestID' />,
      render: (_, record) => {
        return <span>{record.requestId}</span>;
      },
    },
    {
      key: 'requesterName',
      dataIndex: 'requesterName',
      title: <TitleColumns title='Người gửi' />,
      render: (_, record) => {
        return <span>{record.requesterName}</span>;
      },
    },
    {
      key: 'agentCode',
      dataIndex: 'agentCode',
      title: <TitleColumns title='Đại lý' />,
      render: (_, record) => {
        return <span>{record.requesterAgencyCode}</span>;
      },
    },
    {
      key: 'expiredAt',
      dataIndex: 'expiredAt',
      title: <TitleColumns title='Thời hạn xuất vé' />,
      render: (_, record) => {
        return <span>{formatDateTime(record.expiredAt)}</span>;
      },
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: <TitleColumns title='Thời gian gửi' />,
      render: (_, record) => {
        return <span>{formatDateTime(record.createdAt)}</span>;
      },
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: <TitleColumns title='Trạng thái' />,
      render: (_, record) => {
        return <StatusTransferRequest status={record.status} />;
      },
    },
    {
      key: 'issuerId',
      dataIndex: 'issuerId',
      title: <TitleColumns title='Người nhận' />,
      render: (_, record) => {
        if (record.issuerName === '') {
          return <span>-</span>;
        }
        return <span>{record.issuerName}</span>;
      },
    },
  ];

  return (
    <Table
      dataSource={data}
      columns={columns}
      size='small'
      loading={isLoading}
      pagination={
        pagination.totalPage > 1 && {
          pageSize: pagination.pageLimit,
          current: pagination.pageCurrent,
          total: pagination.totalRecord,
          className: 'space-x-2.5',
          onChange: onChangePage,
        }
      }
    />
  );
};

export default TableContent;
