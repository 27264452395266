export function getEnv(key: string): string {
  if (!key) return '';

  return process.env[key] || '';
}

export const sleep = (second = 1000) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, second);
  });
};

export const convertArrayToObject = <T>(
  data: T[],
  key: keyof T
): Record<string, T> => {
  if (!key) {
    return {};
  }

  return data.reduce((result, item) => {
    const keyValue = item[key];

    if (typeof keyValue === 'string') {
      return { ...result, [keyValue]: { ...item } };
    }

    return result;
  }, {});
};

export const generateUUID = () => {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function (c) {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    }
  );

  return uuid;
};

export function getApiErrorMessages(error: any): string[] {
  let message = '';

  // if (error instanceof Error) {
  //   message = error.message;
  // }
  // if (error instanceof RpcError) {
  //   message = error.message;
  // }
  if ('message' in error) {
    message = error.message;
  }

  if ('error' in error) {
    //
    if (error instanceof Error) {
      message = error.message;
    }
  }

  return [message];
}

export const validatePasswordByCase = (password: string) => {
  const hasCharacter = !!password.match(/^(?=.*[a-zA-Z])/);
  const hasUpperCase = !!password.match(/^(?=.*[A-Z])/);
  const hasNumeric = !!password.match(/^(?=.*\d)/);
  const hasSpecialCharacter = !!password.match(
    /^(?=.*[\s!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])/
  );

  return {
    hasCharacter,
    hasUpperCase,
    hasNumeric,
    hasSpecialCharacter,
  };
};

export const validateEmail = (value: string) => {
  const patternEmail =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return !!value.match(patternEmail) || 'Email không hợp lệ';
};
