/**
 * @fileoverview gRPC-Web generated client stub for hotel.web
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: hotel/web/hotel.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var base_base_pb = require('../../base/base_pb.js')

var hotel_base_pb = require('../../hotel/base_pb.js')

var hotel_hotel_pb = require('../../hotel/hotel_pb.js')

var hotel_booking_pb = require('../../hotel/booking_pb.js')

var validate_validate_pb = require('../../validate/validate_pb.js')

var hotel_booking_selection_pb = require('../../hotel/booking_selection_pb.js')
const proto = {};
proto.hotel = {};
proto.hotel.web = require('./hotel_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.hotel.web.HotelServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.hotel.web.HotelServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web.SearchHotelReq,
 *   !proto.hotel.web.SearchHotelRes>}
 */
const methodDescriptor_HotelService_SearchHotel = new grpc.web.MethodDescriptor(
  '/hotel.web.HotelService/SearchHotel',
  grpc.web.MethodType.UNARY,
  proto.hotel.web.SearchHotelReq,
  proto.hotel.web.SearchHotelRes,
  /**
   * @param {!proto.hotel.web.SearchHotelReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web.SearchHotelRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web.SearchHotelReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web.SearchHotelRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web.SearchHotelRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web.HotelServiceClient.prototype.searchHotel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web.HotelService/SearchHotel',
      request,
      metadata || {},
      methodDescriptor_HotelService_SearchHotel,
      callback);
};


/**
 * @param {!proto.hotel.web.SearchHotelReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web.SearchHotelRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web.HotelServicePromiseClient.prototype.searchHotel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web.HotelService/SearchHotel',
      request,
      metadata || {},
      methodDescriptor_HotelService_SearchHotel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web.SearchDestinationsReq,
 *   !proto.hotel.web.SearchDestinationsRes>}
 */
const methodDescriptor_HotelService_SearchDestinations = new grpc.web.MethodDescriptor(
  '/hotel.web.HotelService/SearchDestinations',
  grpc.web.MethodType.UNARY,
  proto.hotel.web.SearchDestinationsReq,
  proto.hotel.web.SearchDestinationsRes,
  /**
   * @param {!proto.hotel.web.SearchDestinationsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web.SearchDestinationsRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web.SearchDestinationsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web.SearchDestinationsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web.SearchDestinationsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web.HotelServiceClient.prototype.searchDestinations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web.HotelService/SearchDestinations',
      request,
      metadata || {},
      methodDescriptor_HotelService_SearchDestinations,
      callback);
};


/**
 * @param {!proto.hotel.web.SearchDestinationsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web.SearchDestinationsRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web.HotelServicePromiseClient.prototype.searchDestinations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web.HotelService/SearchDestinations',
      request,
      metadata || {},
      methodDescriptor_HotelService_SearchDestinations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web.PriceCheckReq,
 *   !proto.hotel.web.PriceCheckRes>}
 */
const methodDescriptor_HotelService_PriceCheck = new grpc.web.MethodDescriptor(
  '/hotel.web.HotelService/PriceCheck',
  grpc.web.MethodType.UNARY,
  proto.hotel.web.PriceCheckReq,
  proto.hotel.web.PriceCheckRes,
  /**
   * @param {!proto.hotel.web.PriceCheckReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web.PriceCheckRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web.PriceCheckReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web.PriceCheckRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web.PriceCheckRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web.HotelServiceClient.prototype.priceCheck =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web.HotelService/PriceCheck',
      request,
      metadata || {},
      methodDescriptor_HotelService_PriceCheck,
      callback);
};


/**
 * @param {!proto.hotel.web.PriceCheckReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web.PriceCheckRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web.HotelServicePromiseClient.prototype.priceCheck =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web.HotelService/PriceCheck',
      request,
      metadata || {},
      methodDescriptor_HotelService_PriceCheck);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web.GetHotelInfoReq,
 *   !proto.hotel.web.GetHotelInfoRes>}
 */
const methodDescriptor_HotelService_GetHotelInfo = new grpc.web.MethodDescriptor(
  '/hotel.web.HotelService/GetHotelInfo',
  grpc.web.MethodType.UNARY,
  proto.hotel.web.GetHotelInfoReq,
  proto.hotel.web.GetHotelInfoRes,
  /**
   * @param {!proto.hotel.web.GetHotelInfoReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web.GetHotelInfoRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web.GetHotelInfoReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web.GetHotelInfoRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web.GetHotelInfoRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web.HotelServiceClient.prototype.getHotelInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web.HotelService/GetHotelInfo',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetHotelInfo,
      callback);
};


/**
 * @param {!proto.hotel.web.GetHotelInfoReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web.GetHotelInfoRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web.HotelServicePromiseClient.prototype.getHotelInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web.HotelService/GetHotelInfo',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetHotelInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web.GetHotelRoomDetailsReq,
 *   !proto.hotel.web.GetHotelRoomDetailsRes>}
 */
const methodDescriptor_HotelService_GetHotelRoomDetails = new grpc.web.MethodDescriptor(
  '/hotel.web.HotelService/GetHotelRoomDetails',
  grpc.web.MethodType.UNARY,
  proto.hotel.web.GetHotelRoomDetailsReq,
  proto.hotel.web.GetHotelRoomDetailsRes,
  /**
   * @param {!proto.hotel.web.GetHotelRoomDetailsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web.GetHotelRoomDetailsRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web.GetHotelRoomDetailsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web.GetHotelRoomDetailsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web.GetHotelRoomDetailsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web.HotelServiceClient.prototype.getHotelRoomDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web.HotelService/GetHotelRoomDetails',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetHotelRoomDetails,
      callback);
};


/**
 * @param {!proto.hotel.web.GetHotelRoomDetailsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web.GetHotelRoomDetailsRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web.HotelServicePromiseClient.prototype.getHotelRoomDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web.HotelService/GetHotelRoomDetails',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetHotelRoomDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web.GetRecommendedHotelsReq,
 *   !proto.hotel.web.GetRecommendedHotelsRes>}
 */
const methodDescriptor_HotelService_GetRecommendedHotels = new grpc.web.MethodDescriptor(
  '/hotel.web.HotelService/GetRecommendedHotels',
  grpc.web.MethodType.UNARY,
  proto.hotel.web.GetRecommendedHotelsReq,
  proto.hotel.web.GetRecommendedHotelsRes,
  /**
   * @param {!proto.hotel.web.GetRecommendedHotelsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web.GetRecommendedHotelsRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web.GetRecommendedHotelsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web.GetRecommendedHotelsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web.GetRecommendedHotelsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web.HotelServiceClient.prototype.getRecommendedHotels =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web.HotelService/GetRecommendedHotels',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetRecommendedHotels,
      callback);
};


/**
 * @param {!proto.hotel.web.GetRecommendedHotelsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web.GetRecommendedHotelsRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web.HotelServicePromiseClient.prototype.getRecommendedHotels =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web.HotelService/GetRecommendedHotels',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetRecommendedHotels);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web.GetHotelReviewsReq,
 *   !proto.hotel.web.GetHotelReviewsRes>}
 */
const methodDescriptor_HotelService_GetHotelReviews = new grpc.web.MethodDescriptor(
  '/hotel.web.HotelService/GetHotelReviews',
  grpc.web.MethodType.UNARY,
  proto.hotel.web.GetHotelReviewsReq,
  proto.hotel.web.GetHotelReviewsRes,
  /**
   * @param {!proto.hotel.web.GetHotelReviewsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web.GetHotelReviewsRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web.GetHotelReviewsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web.GetHotelReviewsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web.GetHotelReviewsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web.HotelServiceClient.prototype.getHotelReviews =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web.HotelService/GetHotelReviews',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetHotelReviews,
      callback);
};


/**
 * @param {!proto.hotel.web.GetHotelReviewsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web.GetHotelReviewsRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web.HotelServicePromiseClient.prototype.getHotelReviews =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web.HotelService/GetHotelReviews',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetHotelReviews);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web.AddBookingContactInfoReq,
 *   !proto.hotel.web.AddBookingContactInfoRes>}
 */
const methodDescriptor_HotelService_AddBookingContactInfo = new grpc.web.MethodDescriptor(
  '/hotel.web.HotelService/AddBookingContactInfo',
  grpc.web.MethodType.UNARY,
  proto.hotel.web.AddBookingContactInfoReq,
  proto.hotel.web.AddBookingContactInfoRes,
  /**
   * @param {!proto.hotel.web.AddBookingContactInfoReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web.AddBookingContactInfoRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web.AddBookingContactInfoReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web.AddBookingContactInfoRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web.AddBookingContactInfoRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web.HotelServiceClient.prototype.addBookingContactInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web.HotelService/AddBookingContactInfo',
      request,
      metadata || {},
      methodDescriptor_HotelService_AddBookingContactInfo,
      callback);
};


/**
 * @param {!proto.hotel.web.AddBookingContactInfoReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web.AddBookingContactInfoRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web.HotelServicePromiseClient.prototype.addBookingContactInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web.HotelService/AddBookingContactInfo',
      request,
      metadata || {},
      methodDescriptor_HotelService_AddBookingContactInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web.RetrieveBookingReq,
 *   !proto.hotel.web.RetrieveBookingRes>}
 */
const methodDescriptor_HotelService_RetrieveBooking = new grpc.web.MethodDescriptor(
  '/hotel.web.HotelService/RetrieveBooking',
  grpc.web.MethodType.UNARY,
  proto.hotel.web.RetrieveBookingReq,
  proto.hotel.web.RetrieveBookingRes,
  /**
   * @param {!proto.hotel.web.RetrieveBookingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web.RetrieveBookingRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web.RetrieveBookingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web.RetrieveBookingRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web.RetrieveBookingRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web.HotelServiceClient.prototype.retrieveBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web.HotelService/RetrieveBooking',
      request,
      metadata || {},
      methodDescriptor_HotelService_RetrieveBooking,
      callback);
};


/**
 * @param {!proto.hotel.web.RetrieveBookingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web.RetrieveBookingRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web.HotelServicePromiseClient.prototype.retrieveBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web.HotelService/RetrieveBooking',
      request,
      metadata || {},
      methodDescriptor_HotelService_RetrieveBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web.GetStaticBookingBySessionReq,
 *   !proto.hotel.web.GetStaticBookingBySessionRes>}
 */
const methodDescriptor_HotelService_GetStaticBookingBySession = new grpc.web.MethodDescriptor(
  '/hotel.web.HotelService/GetStaticBookingBySession',
  grpc.web.MethodType.UNARY,
  proto.hotel.web.GetStaticBookingBySessionReq,
  proto.hotel.web.GetStaticBookingBySessionRes,
  /**
   * @param {!proto.hotel.web.GetStaticBookingBySessionReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web.GetStaticBookingBySessionRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web.GetStaticBookingBySessionReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web.GetStaticBookingBySessionRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web.GetStaticBookingBySessionRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web.HotelServiceClient.prototype.getStaticBookingBySession =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web.HotelService/GetStaticBookingBySession',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetStaticBookingBySession,
      callback);
};


/**
 * @param {!proto.hotel.web.GetStaticBookingBySessionReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web.GetStaticBookingBySessionRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web.HotelServicePromiseClient.prototype.getStaticBookingBySession =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web.HotelService/GetStaticBookingBySession',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetStaticBookingBySession);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web.ReserveBookingReq,
 *   !proto.hotel.web.ReserveBookingRes>}
 */
const methodDescriptor_HotelService_ReserveBooking = new grpc.web.MethodDescriptor(
  '/hotel.web.HotelService/ReserveBooking',
  grpc.web.MethodType.UNARY,
  proto.hotel.web.ReserveBookingReq,
  proto.hotel.web.ReserveBookingRes,
  /**
   * @param {!proto.hotel.web.ReserveBookingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web.ReserveBookingRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web.ReserveBookingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web.ReserveBookingRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web.ReserveBookingRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web.HotelServiceClient.prototype.reserveBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web.HotelService/ReserveBooking',
      request,
      metadata || {},
      methodDescriptor_HotelService_ReserveBooking,
      callback);
};


/**
 * @param {!proto.hotel.web.ReserveBookingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web.ReserveBookingRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web.HotelServicePromiseClient.prototype.reserveBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web.HotelService/ReserveBooking',
      request,
      metadata || {},
      methodDescriptor_HotelService_ReserveBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hotel.web.GetBookingSelectionReq,
 *   !proto.hotel.web.GetBookingSelectionRes>}
 */
const methodDescriptor_HotelService_GetBookingSelection = new grpc.web.MethodDescriptor(
  '/hotel.web.HotelService/GetBookingSelection',
  grpc.web.MethodType.UNARY,
  proto.hotel.web.GetBookingSelectionReq,
  proto.hotel.web.GetBookingSelectionRes,
  /**
   * @param {!proto.hotel.web.GetBookingSelectionReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hotel.web.GetBookingSelectionRes.deserializeBinary
);


/**
 * @param {!proto.hotel.web.GetBookingSelectionReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hotel.web.GetBookingSelectionRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hotel.web.GetBookingSelectionRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hotel.web.HotelServiceClient.prototype.getBookingSelection =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hotel.web.HotelService/GetBookingSelection',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetBookingSelection,
      callback);
};


/**
 * @param {!proto.hotel.web.GetBookingSelectionReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hotel.web.GetBookingSelectionRes>}
 *     Promise that resolves to the response
 */
proto.hotel.web.HotelServicePromiseClient.prototype.getBookingSelection =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hotel.web.HotelService/GetBookingSelection',
      request,
      metadata || {},
      methodDescriptor_HotelService_GetBookingSelection);
};


module.exports = proto.hotel.web;

