import { BasicRes, Empty, OrderByItem, PaginationReq } from '@api/base/base_pb';
import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';
import { RoomOccupancyRequest } from '@api/hotel/booking_pb';
import { HotelServiceClient } from '@api/hotel/web_partnership/hotel_grpc_web_pb';
import {
  ExportBookingsReq,
  ExportBookingsRes,
  GetBookingDetailRequest,
  GetBookingDetailResponse,
  ListBookingsFilter,
  ListBookingsRequest,
  ListBookingsResponse,
} from '@api/hotel/web_partnership/hotel_pb';

class HotelServiceApi extends BaseApi<HotelServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new HotelServiceClient(this.url, null, this.optsDev);
  }
  listBookings = ({
    filter,
    pagination = {
      pageLimit: 100,
      pageNumber: 1,
    },
  }: ListBookingsRequest.AsObject): Promise<ListBookingsResponse.AsObject> => {
    const req = new ListBookingsRequest();
    const filterObj = new ListBookingsFilter();
    const paginationObj = new PaginationReq();
    if (filter) {
      filter.to && filterObj.setTo(filter.to);
      filter.from && filterObj.setFrom(filter.from);
      filter.orderCode &&
        filterObj.setOrderCode(filter.orderCode?.trim() || '');
      filterObj.setStatusList(filter.statusList);
      filterObj.setNotInStatusesList(filter.notInStatusesList);
    }

    req.setFilter(filterObj);

    paginationObj.setPageNumber(pagination.pageNumber);
    paginationObj.setPageLimit(pagination.pageLimit);
    req.setPagination(paginationObj);

    return this.grpc<
      ListBookingsRequest,
      ListBookingsResponse,
      ListBookingsResponse.AsObject
    >(this.serviceClient?.listBookings, req, {});
  };

  getBookingDetail = ({
    orderCode,
  }: GetBookingDetailRequest.AsObject): Promise<GetBookingDetailResponse.AsObject> => {
    const req = new GetBookingDetailRequest();
    req.setOrderCode(orderCode);

    return this.grpc<
      GetBookingDetailRequest,
      GetBookingDetailResponse,
      GetBookingDetailResponse.AsObject
    >(this.serviceClient?.getBookingDetail, req, {});
  };

  exportBookings = ({
    filter,
  }: ExportBookingsReq.AsObject): Promise<ExportBookingsRes.AsObject> => {
    const req = new ExportBookingsReq();
    const filterObj = new ListBookingsFilter();
    if (filter) {
      filter.to && filterObj.setTo(filter.to);
      filter.from && filterObj.setFrom(filter.from);
      filter.orderCode &&
        filterObj.setOrderCode(filter.orderCode?.trim() || '');
      filterObj.setStatusList(filter.statusList);
      filterObj.setNotInStatusesList(filter.notInStatusesList);
    }

    req.setFilter(filterObj);

    return this.grpc<
      ExportBookingsReq,
      ExportBookingsRes,
      ExportBookingsRes.AsObject
    >(this.serviceClient?.exportBookings, req, {});
  };

  // cancelBooking = ({
  //   orderCode,
  // }: CancelBookingReq.AsObject): Promise<CancelBookingRes.AsObject> => {
  //   const req = new CancelBookingReq();
  //   req.setOrderCode(orderCode);

  //   return this.grpc<
  //     CancelBookingReq,
  //     CancelBookingRes,
  //     CancelBookingRes.AsObject
  //   >(this.serviceClient?.cancelBooking, req, {});
  // };
}

export const hotelApiServiceWebPartnership = new HotelServiceApi();
export default hotelApiServiceWebPartnership;
