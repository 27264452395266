// source: hotel/web/hotel.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var base_base_pb = require('../../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
var hotel_base_pb = require('../../hotel/base_pb.js');
goog.object.extend(proto, hotel_base_pb);
var hotel_hotel_pb = require('../../hotel/hotel_pb.js');
goog.object.extend(proto, hotel_hotel_pb);
var hotel_booking_pb = require('../../hotel/booking_pb.js');
goog.object.extend(proto, hotel_booking_pb);
var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
var hotel_booking_selection_pb = require('../../hotel/booking_selection_pb.js');
goog.object.extend(proto, hotel_booking_selection_pb);
goog.exportSymbol('proto.hotel.web.AddBookingContactInfoReq', null, global);
goog.exportSymbol('proto.hotel.web.AddBookingContactInfoRes', null, global);
goog.exportSymbol('proto.hotel.web.GetBookingSelectionReq', null, global);
goog.exportSymbol('proto.hotel.web.GetBookingSelectionRes', null, global);
goog.exportSymbol('proto.hotel.web.GetHotelInfoReq', null, global);
goog.exportSymbol('proto.hotel.web.GetHotelInfoRes', null, global);
goog.exportSymbol('proto.hotel.web.GetHotelReviewsReq', null, global);
goog.exportSymbol('proto.hotel.web.GetHotelReviewsRes', null, global);
goog.exportSymbol('proto.hotel.web.GetHotelRoomDetailsReq', null, global);
goog.exportSymbol('proto.hotel.web.GetHotelRoomDetailsRes', null, global);
goog.exportSymbol('proto.hotel.web.GetRecommendedHotelsReq', null, global);
goog.exportSymbol('proto.hotel.web.GetRecommendedHotelsRes', null, global);
goog.exportSymbol('proto.hotel.web.GetStaticBookingBySessionReq', null, global);
goog.exportSymbol('proto.hotel.web.GetStaticBookingBySessionRes', null, global);
goog.exportSymbol('proto.hotel.web.PriceCheckReq', null, global);
goog.exportSymbol('proto.hotel.web.PriceCheckRes', null, global);
goog.exportSymbol('proto.hotel.web.ReserveBookingReq', null, global);
goog.exportSymbol('proto.hotel.web.ReserveBookingRes', null, global);
goog.exportSymbol('proto.hotel.web.RetrieveBookingReq', null, global);
goog.exportSymbol('proto.hotel.web.RetrieveBookingRes', null, global);
goog.exportSymbol('proto.hotel.web.SearchDestinationsReq', null, global);
goog.exportSymbol('proto.hotel.web.SearchDestinationsRes', null, global);
goog.exportSymbol('proto.hotel.web.SearchHotelReq', null, global);
goog.exportSymbol('proto.hotel.web.SearchHotelRes', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web.SearchDestinationsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web.SearchDestinationsReq.repeatedFields_, null);
};
goog.inherits(proto.hotel.web.SearchDestinationsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web.SearchDestinationsReq.displayName = 'proto.hotel.web.SearchDestinationsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web.SearchDestinationsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web.SearchDestinationsRes.repeatedFields_, null);
};
goog.inherits(proto.hotel.web.SearchDestinationsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web.SearchDestinationsRes.displayName = 'proto.hotel.web.SearchDestinationsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web.SearchHotelReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web.SearchHotelReq.repeatedFields_, null);
};
goog.inherits(proto.hotel.web.SearchHotelReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web.SearchHotelReq.displayName = 'proto.hotel.web.SearchHotelReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web.SearchHotelRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web.SearchHotelRes.repeatedFields_, null);
};
goog.inherits(proto.hotel.web.SearchHotelRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web.SearchHotelRes.displayName = 'proto.hotel.web.SearchHotelRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web.PriceCheckReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web.PriceCheckReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web.PriceCheckReq.displayName = 'proto.hotel.web.PriceCheckReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web.PriceCheckRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web.PriceCheckRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web.PriceCheckRes.displayName = 'proto.hotel.web.PriceCheckRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web.AddBookingContactInfoReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web.AddBookingContactInfoReq.repeatedFields_, null);
};
goog.inherits(proto.hotel.web.AddBookingContactInfoReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web.AddBookingContactInfoReq.displayName = 'proto.hotel.web.AddBookingContactInfoReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web.AddBookingContactInfoRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web.AddBookingContactInfoRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web.AddBookingContactInfoRes.displayName = 'proto.hotel.web.AddBookingContactInfoRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web.GetHotelInfoReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web.GetHotelInfoReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web.GetHotelInfoReq.displayName = 'proto.hotel.web.GetHotelInfoReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web.GetHotelInfoRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web.GetHotelInfoRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web.GetHotelInfoRes.displayName = 'proto.hotel.web.GetHotelInfoRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web.GetHotelRoomDetailsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web.GetHotelRoomDetailsReq.repeatedFields_, null);
};
goog.inherits(proto.hotel.web.GetHotelRoomDetailsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web.GetHotelRoomDetailsReq.displayName = 'proto.hotel.web.GetHotelRoomDetailsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web.GetHotelRoomDetailsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web.GetHotelRoomDetailsRes.repeatedFields_, null);
};
goog.inherits(proto.hotel.web.GetHotelRoomDetailsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web.GetHotelRoomDetailsRes.displayName = 'proto.hotel.web.GetHotelRoomDetailsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web.GetRecommendedHotelsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web.GetRecommendedHotelsReq.repeatedFields_, null);
};
goog.inherits(proto.hotel.web.GetRecommendedHotelsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web.GetRecommendedHotelsReq.displayName = 'proto.hotel.web.GetRecommendedHotelsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web.GetRecommendedHotelsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web.GetRecommendedHotelsRes.repeatedFields_, null);
};
goog.inherits(proto.hotel.web.GetRecommendedHotelsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web.GetRecommendedHotelsRes.displayName = 'proto.hotel.web.GetRecommendedHotelsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web.GetHotelReviewsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web.GetHotelReviewsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web.GetHotelReviewsReq.displayName = 'proto.hotel.web.GetHotelReviewsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web.GetHotelReviewsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web.GetHotelReviewsRes.repeatedFields_, null);
};
goog.inherits(proto.hotel.web.GetHotelReviewsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web.GetHotelReviewsRes.displayName = 'proto.hotel.web.GetHotelReviewsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web.RetrieveBookingReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web.RetrieveBookingReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web.RetrieveBookingReq.displayName = 'proto.hotel.web.RetrieveBookingReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web.RetrieveBookingRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web.RetrieveBookingRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web.RetrieveBookingRes.displayName = 'proto.hotel.web.RetrieveBookingRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web.GetStaticBookingBySessionReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web.GetStaticBookingBySessionReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web.GetStaticBookingBySessionReq.displayName = 'proto.hotel.web.GetStaticBookingBySessionReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web.GetStaticBookingBySessionRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web.GetStaticBookingBySessionRes.repeatedFields_, null);
};
goog.inherits(proto.hotel.web.GetStaticBookingBySessionRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web.GetStaticBookingBySessionRes.displayName = 'proto.hotel.web.GetStaticBookingBySessionRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web.ReserveBookingReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web.ReserveBookingReq.repeatedFields_, null);
};
goog.inherits(proto.hotel.web.ReserveBookingReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web.ReserveBookingReq.displayName = 'proto.hotel.web.ReserveBookingReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web.ReserveBookingRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web.ReserveBookingRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web.ReserveBookingRes.displayName = 'proto.hotel.web.ReserveBookingRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web.GetBookingSelectionReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web.GetBookingSelectionReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web.GetBookingSelectionReq.displayName = 'proto.hotel.web.GetBookingSelectionReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web.GetBookingSelectionRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web.GetBookingSelectionRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web.GetBookingSelectionRes.displayName = 'proto.hotel.web.GetBookingSelectionRes';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web.SearchDestinationsReq.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web.SearchDestinationsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web.SearchDestinationsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web.SearchDestinationsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.SearchDestinationsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    language: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f),
    orderByList: jspb.Message.toObjectList(msg.getOrderByList(),
    base_base_pb.OrderByItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web.SearchDestinationsReq}
 */
proto.hotel.web.SearchDestinationsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web.SearchDestinationsReq;
  return proto.hotel.web.SearchDestinationsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web.SearchDestinationsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web.SearchDestinationsReq}
 */
proto.hotel.web.SearchDestinationsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 3:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = new base_base_pb.OrderByItem;
      reader.readMessage(value,base_base_pb.OrderByItem.deserializeBinaryFromReader);
      msg.addOrderBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web.SearchDestinationsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web.SearchDestinationsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web.SearchDestinationsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.SearchDestinationsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
  f = message.getOrderByList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      base_base_pb.OrderByItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.hotel.web.SearchDestinationsReq.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.SearchDestinationsReq} returns this
 */
proto.hotel.web.SearchDestinationsReq.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string language = 2;
 * @return {string}
 */
proto.hotel.web.SearchDestinationsReq.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.SearchDestinationsReq} returns this
 */
proto.hotel.web.SearchDestinationsReq.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional base.PaginationReq pagination = 3;
 * @return {?proto.base.PaginationReq}
 */
proto.hotel.web.SearchDestinationsReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 3));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.hotel.web.SearchDestinationsReq} returns this
*/
proto.hotel.web.SearchDestinationsReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web.SearchDestinationsReq} returns this
 */
proto.hotel.web.SearchDestinationsReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web.SearchDestinationsReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated base.OrderByItem order_by = 4;
 * @return {!Array<!proto.base.OrderByItem>}
 */
proto.hotel.web.SearchDestinationsReq.prototype.getOrderByList = function() {
  return /** @type{!Array<!proto.base.OrderByItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, base_base_pb.OrderByItem, 4));
};


/**
 * @param {!Array<!proto.base.OrderByItem>} value
 * @return {!proto.hotel.web.SearchDestinationsReq} returns this
*/
proto.hotel.web.SearchDestinationsReq.prototype.setOrderByList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.base.OrderByItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.base.OrderByItem}
 */
proto.hotel.web.SearchDestinationsReq.prototype.addOrderBy = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.base.OrderByItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web.SearchDestinationsReq} returns this
 */
proto.hotel.web.SearchDestinationsReq.prototype.clearOrderByList = function() {
  return this.setOrderByList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web.SearchDestinationsRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web.SearchDestinationsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web.SearchDestinationsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web.SearchDestinationsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.SearchDestinationsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    destinationsList: jspb.Message.toObjectList(msg.getDestinationsList(),
    hotel_base_pb.Destination.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web.SearchDestinationsRes}
 */
proto.hotel.web.SearchDestinationsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web.SearchDestinationsRes;
  return proto.hotel.web.SearchDestinationsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web.SearchDestinationsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web.SearchDestinationsRes}
 */
proto.hotel.web.SearchDestinationsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new hotel_base_pb.Destination;
      reader.readMessage(value,hotel_base_pb.Destination.deserializeBinaryFromReader);
      msg.addDestinations(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web.SearchDestinationsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web.SearchDestinationsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web.SearchDestinationsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.SearchDestinationsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDestinationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      hotel_base_pb.Destination.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * repeated hotel.Destination destinations = 1;
 * @return {!Array<!proto.hotel.Destination>}
 */
proto.hotel.web.SearchDestinationsRes.prototype.getDestinationsList = function() {
  return /** @type{!Array<!proto.hotel.Destination>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.Destination, 1));
};


/**
 * @param {!Array<!proto.hotel.Destination>} value
 * @return {!proto.hotel.web.SearchDestinationsRes} returns this
*/
proto.hotel.web.SearchDestinationsRes.prototype.setDestinationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.hotel.Destination=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.Destination}
 */
proto.hotel.web.SearchDestinationsRes.prototype.addDestinations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.hotel.Destination, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web.SearchDestinationsRes} returns this
 */
proto.hotel.web.SearchDestinationsRes.prototype.clearDestinationsList = function() {
  return this.setDestinationsList([]);
};


/**
 * optional base.PaginationRes pagination = 2;
 * @return {?proto.base.PaginationRes}
 */
proto.hotel.web.SearchDestinationsRes.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 2));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.hotel.web.SearchDestinationsRes} returns this
*/
proto.hotel.web.SearchDestinationsRes.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web.SearchDestinationsRes} returns this
 */
proto.hotel.web.SearchDestinationsRes.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web.SearchDestinationsRes.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool is_success = 3;
 * @return {boolean}
 */
proto.hotel.web.SearchDestinationsRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web.SearchDestinationsRes} returns this
 */
proto.hotel.web.SearchDestinationsRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string error_code = 4;
 * @return {string}
 */
proto.hotel.web.SearchDestinationsRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.SearchDestinationsRes} returns this
 */
proto.hotel.web.SearchDestinationsRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web.SearchHotelReq.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web.SearchHotelReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web.SearchHotelReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web.SearchHotelReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.SearchHotelReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && hotel_base_pb.HotelFilterRequest.toObject(includeInstance, f),
    destination: (f = msg.getDestination()) && hotel_base_pb.Destination.toObject(includeInstance, f),
    checkIn: jspb.Message.getFieldWithDefault(msg, 3, ""),
    checkOut: jspb.Message.getFieldWithDefault(msg, 4, ""),
    occupanciesList: jspb.Message.toObjectList(msg.getOccupanciesList(),
    hotel_base_pb.OccupancyRequest.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f),
    sorts: (f = msg.getSorts()) && hotel_base_pb.SortItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web.SearchHotelReq}
 */
proto.hotel.web.SearchHotelReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web.SearchHotelReq;
  return proto.hotel.web.SearchHotelReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web.SearchHotelReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web.SearchHotelReq}
 */
proto.hotel.web.SearchHotelReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new hotel_base_pb.HotelFilterRequest;
      reader.readMessage(value,hotel_base_pb.HotelFilterRequest.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new hotel_base_pb.Destination;
      reader.readMessage(value,hotel_base_pb.Destination.deserializeBinaryFromReader);
      msg.setDestination(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckIn(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckOut(value);
      break;
    case 5:
      var value = new hotel_base_pb.OccupancyRequest;
      reader.readMessage(value,hotel_base_pb.OccupancyRequest.deserializeBinaryFromReader);
      msg.addOccupancies(value);
      break;
    case 6:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 7:
      var value = new hotel_base_pb.SortItem;
      reader.readMessage(value,hotel_base_pb.SortItem.deserializeBinaryFromReader);
      msg.setSorts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web.SearchHotelReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web.SearchHotelReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web.SearchHotelReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.SearchHotelReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      hotel_base_pb.HotelFilterRequest.serializeBinaryToWriter
    );
  }
  f = message.getDestination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hotel_base_pb.Destination.serializeBinaryToWriter
    );
  }
  f = message.getCheckIn();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCheckOut();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOccupanciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      hotel_base_pb.OccupancyRequest.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
  f = message.getSorts();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      hotel_base_pb.SortItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional hotel.HotelFilterRequest filter = 1;
 * @return {?proto.hotel.HotelFilterRequest}
 */
proto.hotel.web.SearchHotelReq.prototype.getFilter = function() {
  return /** @type{?proto.hotel.HotelFilterRequest} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.HotelFilterRequest, 1));
};


/**
 * @param {?proto.hotel.HotelFilterRequest|undefined} value
 * @return {!proto.hotel.web.SearchHotelReq} returns this
*/
proto.hotel.web.SearchHotelReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web.SearchHotelReq} returns this
 */
proto.hotel.web.SearchHotelReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web.SearchHotelReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional hotel.Destination destination = 2;
 * @return {?proto.hotel.Destination}
 */
proto.hotel.web.SearchHotelReq.prototype.getDestination = function() {
  return /** @type{?proto.hotel.Destination} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.Destination, 2));
};


/**
 * @param {?proto.hotel.Destination|undefined} value
 * @return {!proto.hotel.web.SearchHotelReq} returns this
*/
proto.hotel.web.SearchHotelReq.prototype.setDestination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web.SearchHotelReq} returns this
 */
proto.hotel.web.SearchHotelReq.prototype.clearDestination = function() {
  return this.setDestination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web.SearchHotelReq.prototype.hasDestination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string check_in = 3;
 * @return {string}
 */
proto.hotel.web.SearchHotelReq.prototype.getCheckIn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.SearchHotelReq} returns this
 */
proto.hotel.web.SearchHotelReq.prototype.setCheckIn = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string check_out = 4;
 * @return {string}
 */
proto.hotel.web.SearchHotelReq.prototype.getCheckOut = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.SearchHotelReq} returns this
 */
proto.hotel.web.SearchHotelReq.prototype.setCheckOut = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated hotel.OccupancyRequest occupancies = 5;
 * @return {!Array<!proto.hotel.OccupancyRequest>}
 */
proto.hotel.web.SearchHotelReq.prototype.getOccupanciesList = function() {
  return /** @type{!Array<!proto.hotel.OccupancyRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.OccupancyRequest, 5));
};


/**
 * @param {!Array<!proto.hotel.OccupancyRequest>} value
 * @return {!proto.hotel.web.SearchHotelReq} returns this
*/
proto.hotel.web.SearchHotelReq.prototype.setOccupanciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.hotel.OccupancyRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.OccupancyRequest}
 */
proto.hotel.web.SearchHotelReq.prototype.addOccupancies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.hotel.OccupancyRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web.SearchHotelReq} returns this
 */
proto.hotel.web.SearchHotelReq.prototype.clearOccupanciesList = function() {
  return this.setOccupanciesList([]);
};


/**
 * optional base.PaginationReq pagination = 6;
 * @return {?proto.base.PaginationReq}
 */
proto.hotel.web.SearchHotelReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 6));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.hotel.web.SearchHotelReq} returns this
*/
proto.hotel.web.SearchHotelReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web.SearchHotelReq} returns this
 */
proto.hotel.web.SearchHotelReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web.SearchHotelReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional hotel.SortItem sorts = 7;
 * @return {?proto.hotel.SortItem}
 */
proto.hotel.web.SearchHotelReq.prototype.getSorts = function() {
  return /** @type{?proto.hotel.SortItem} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.SortItem, 7));
};


/**
 * @param {?proto.hotel.SortItem|undefined} value
 * @return {!proto.hotel.web.SearchHotelReq} returns this
*/
proto.hotel.web.SearchHotelReq.prototype.setSorts = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web.SearchHotelReq} returns this
 */
proto.hotel.web.SearchHotelReq.prototype.clearSorts = function() {
  return this.setSorts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web.SearchHotelReq.prototype.hasSorts = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web.SearchHotelRes.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web.SearchHotelRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web.SearchHotelRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web.SearchHotelRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.SearchHotelRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hotelList: jspb.Message.toObjectList(msg.getHotelList(),
    hotel_base_pb.HotelSummary.toObject, includeInstance),
    filter: (f = msg.getFilter()) && hotel_base_pb.FilterOptions.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web.SearchHotelRes}
 */
proto.hotel.web.SearchHotelRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web.SearchHotelRes;
  return proto.hotel.web.SearchHotelRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web.SearchHotelRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web.SearchHotelRes}
 */
proto.hotel.web.SearchHotelRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = new hotel_base_pb.HotelSummary;
      reader.readMessage(value,hotel_base_pb.HotelSummary.deserializeBinaryFromReader);
      msg.addHotel(value);
      break;
    case 3:
      var value = new hotel_base_pb.FilterOptions;
      reader.readMessage(value,hotel_base_pb.FilterOptions.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 4:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web.SearchHotelRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web.SearchHotelRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web.SearchHotelRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.SearchHotelRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHotelList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      hotel_base_pb.HotelSummary.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      hotel_base_pb.FilterOptions.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.hotel.web.SearchHotelRes.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.SearchHotelRes} returns this
 */
proto.hotel.web.SearchHotelRes.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated hotel.HotelSummary hotel = 2;
 * @return {!Array<!proto.hotel.HotelSummary>}
 */
proto.hotel.web.SearchHotelRes.prototype.getHotelList = function() {
  return /** @type{!Array<!proto.hotel.HotelSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.HotelSummary, 2));
};


/**
 * @param {!Array<!proto.hotel.HotelSummary>} value
 * @return {!proto.hotel.web.SearchHotelRes} returns this
*/
proto.hotel.web.SearchHotelRes.prototype.setHotelList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.hotel.HotelSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.HotelSummary}
 */
proto.hotel.web.SearchHotelRes.prototype.addHotel = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.hotel.HotelSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web.SearchHotelRes} returns this
 */
proto.hotel.web.SearchHotelRes.prototype.clearHotelList = function() {
  return this.setHotelList([]);
};


/**
 * optional hotel.FilterOptions filter = 3;
 * @return {?proto.hotel.FilterOptions}
 */
proto.hotel.web.SearchHotelRes.prototype.getFilter = function() {
  return /** @type{?proto.hotel.FilterOptions} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.FilterOptions, 3));
};


/**
 * @param {?proto.hotel.FilterOptions|undefined} value
 * @return {!proto.hotel.web.SearchHotelRes} returns this
*/
proto.hotel.web.SearchHotelRes.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web.SearchHotelRes} returns this
 */
proto.hotel.web.SearchHotelRes.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web.SearchHotelRes.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional base.PaginationRes pagination = 4;
 * @return {?proto.base.PaginationRes}
 */
proto.hotel.web.SearchHotelRes.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 4));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.hotel.web.SearchHotelRes} returns this
*/
proto.hotel.web.SearchHotelRes.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web.SearchHotelRes} returns this
 */
proto.hotel.web.SearchHotelRes.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web.SearchHotelRes.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool is_success = 5;
 * @return {boolean}
 */
proto.hotel.web.SearchHotelRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web.SearchHotelRes} returns this
 */
proto.hotel.web.SearchHotelRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string error_code = 6;
 * @return {string}
 */
proto.hotel.web.SearchHotelRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.SearchHotelRes} returns this
 */
proto.hotel.web.SearchHotelRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web.PriceCheckReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web.PriceCheckReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web.PriceCheckReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.PriceCheckReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stateful: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web.PriceCheckReq}
 */
proto.hotel.web.PriceCheckReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web.PriceCheckReq;
  return proto.hotel.web.PriceCheckReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web.PriceCheckReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web.PriceCheckReq}
 */
proto.hotel.web.PriceCheckReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStateful(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web.PriceCheckReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web.PriceCheckReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web.PriceCheckReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.PriceCheckReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStateful();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.hotel.web.PriceCheckReq.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.PriceCheckReq} returns this
 */
proto.hotel.web.PriceCheckReq.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool stateful = 2;
 * @return {boolean}
 */
proto.hotel.web.PriceCheckReq.prototype.getStateful = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web.PriceCheckReq} returns this
 */
proto.hotel.web.PriceCheckReq.prototype.setStateful = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web.PriceCheckRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web.PriceCheckRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web.PriceCheckRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.PriceCheckRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rate: (f = msg.getRate()) && hotel_hotel_pb.HubRateData.toObject(includeInstance, f),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web.PriceCheckRes}
 */
proto.hotel.web.PriceCheckRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web.PriceCheckRes;
  return proto.hotel.web.PriceCheckRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web.PriceCheckRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web.PriceCheckRes}
 */
proto.hotel.web.PriceCheckRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = new hotel_hotel_pb.HubRateData;
      reader.readMessage(value,hotel_hotel_pb.HubRateData.deserializeBinaryFromReader);
      msg.setRate(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web.PriceCheckRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web.PriceCheckRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web.PriceCheckRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.PriceCheckRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hotel_hotel_pb.HubRateData.serializeBinaryToWriter
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.hotel.web.PriceCheckRes.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.PriceCheckRes} returns this
 */
proto.hotel.web.PriceCheckRes.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional hotel.HubRateData rate = 2;
 * @return {?proto.hotel.HubRateData}
 */
proto.hotel.web.PriceCheckRes.prototype.getRate = function() {
  return /** @type{?proto.hotel.HubRateData} */ (
    jspb.Message.getWrapperField(this, hotel_hotel_pb.HubRateData, 2));
};


/**
 * @param {?proto.hotel.HubRateData|undefined} value
 * @return {!proto.hotel.web.PriceCheckRes} returns this
*/
proto.hotel.web.PriceCheckRes.prototype.setRate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web.PriceCheckRes} returns this
 */
proto.hotel.web.PriceCheckRes.prototype.clearRate = function() {
  return this.setRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web.PriceCheckRes.prototype.hasRate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool is_success = 3;
 * @return {boolean}
 */
proto.hotel.web.PriceCheckRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web.PriceCheckRes} returns this
 */
proto.hotel.web.PriceCheckRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string error_code = 4;
 * @return {string}
 */
proto.hotel.web.PriceCheckRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.PriceCheckRes} returns this
 */
proto.hotel.web.PriceCheckRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web.AddBookingContactInfoReq.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web.AddBookingContactInfoReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web.AddBookingContactInfoReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web.AddBookingContactInfoReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.AddBookingContactInfoReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    invoicingInfo: (f = msg.getInvoicingInfo()) && hotel_booking_pb.InvoicingInformation.toObject(includeInstance, f),
    bedOptionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    roomOccupanciesList: jspb.Message.toObjectList(msg.getRoomOccupanciesList(),
    hotel_booking_pb.RoomOccupancyRequest.toObject, includeInstance),
    specialRequest: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web.AddBookingContactInfoReq}
 */
proto.hotel.web.AddBookingContactInfoReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web.AddBookingContactInfoReq;
  return proto.hotel.web.AddBookingContactInfoReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web.AddBookingContactInfoReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web.AddBookingContactInfoReq}
 */
proto.hotel.web.AddBookingContactInfoReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 2:
      var value = new hotel_booking_pb.InvoicingInformation;
      reader.readMessage(value,hotel_booking_pb.InvoicingInformation.deserializeBinaryFromReader);
      msg.setInvoicingInfo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBedOptionId(value);
      break;
    case 4:
      var value = new hotel_booking_pb.RoomOccupancyRequest;
      reader.readMessage(value,hotel_booking_pb.RoomOccupancyRequest.deserializeBinaryFromReader);
      msg.addRoomOccupancies(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecialRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web.AddBookingContactInfoReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web.AddBookingContactInfoReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web.AddBookingContactInfoReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.AddBookingContactInfoReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInvoicingInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hotel_booking_pb.InvoicingInformation.serializeBinaryToWriter
    );
  }
  f = message.getBedOptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRoomOccupanciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      hotel_booking_pb.RoomOccupancyRequest.serializeBinaryToWriter
    );
  }
  f = message.getSpecialRequest();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.hotel.web.AddBookingContactInfoReq.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.AddBookingContactInfoReq} returns this
 */
proto.hotel.web.AddBookingContactInfoReq.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional hotel.InvoicingInformation invoicing_info = 2;
 * @return {?proto.hotel.InvoicingInformation}
 */
proto.hotel.web.AddBookingContactInfoReq.prototype.getInvoicingInfo = function() {
  return /** @type{?proto.hotel.InvoicingInformation} */ (
    jspb.Message.getWrapperField(this, hotel_booking_pb.InvoicingInformation, 2));
};


/**
 * @param {?proto.hotel.InvoicingInformation|undefined} value
 * @return {!proto.hotel.web.AddBookingContactInfoReq} returns this
*/
proto.hotel.web.AddBookingContactInfoReq.prototype.setInvoicingInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web.AddBookingContactInfoReq} returns this
 */
proto.hotel.web.AddBookingContactInfoReq.prototype.clearInvoicingInfo = function() {
  return this.setInvoicingInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web.AddBookingContactInfoReq.prototype.hasInvoicingInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string bed_option_id = 3;
 * @return {string}
 */
proto.hotel.web.AddBookingContactInfoReq.prototype.getBedOptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.AddBookingContactInfoReq} returns this
 */
proto.hotel.web.AddBookingContactInfoReq.prototype.setBedOptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated hotel.RoomOccupancyRequest room_occupancies = 4;
 * @return {!Array<!proto.hotel.RoomOccupancyRequest>}
 */
proto.hotel.web.AddBookingContactInfoReq.prototype.getRoomOccupanciesList = function() {
  return /** @type{!Array<!proto.hotel.RoomOccupancyRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_booking_pb.RoomOccupancyRequest, 4));
};


/**
 * @param {!Array<!proto.hotel.RoomOccupancyRequest>} value
 * @return {!proto.hotel.web.AddBookingContactInfoReq} returns this
*/
proto.hotel.web.AddBookingContactInfoReq.prototype.setRoomOccupanciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.hotel.RoomOccupancyRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.RoomOccupancyRequest}
 */
proto.hotel.web.AddBookingContactInfoReq.prototype.addRoomOccupancies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.hotel.RoomOccupancyRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web.AddBookingContactInfoReq} returns this
 */
proto.hotel.web.AddBookingContactInfoReq.prototype.clearRoomOccupanciesList = function() {
  return this.setRoomOccupanciesList([]);
};


/**
 * optional string special_request = 5;
 * @return {string}
 */
proto.hotel.web.AddBookingContactInfoReq.prototype.getSpecialRequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.AddBookingContactInfoReq} returns this
 */
proto.hotel.web.AddBookingContactInfoReq.prototype.setSpecialRequest = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web.AddBookingContactInfoRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web.AddBookingContactInfoRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web.AddBookingContactInfoRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.AddBookingContactInfoRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rate: (f = msg.getRate()) && hotel_hotel_pb.HubRateData.toObject(includeInstance, f),
    expiredAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web.AddBookingContactInfoRes}
 */
proto.hotel.web.AddBookingContactInfoRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web.AddBookingContactInfoRes;
  return proto.hotel.web.AddBookingContactInfoRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web.AddBookingContactInfoRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web.AddBookingContactInfoRes}
 */
proto.hotel.web.AddBookingContactInfoRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 2:
      var value = new hotel_hotel_pb.HubRateData;
      reader.readMessage(value,hotel_hotel_pb.HubRateData.deserializeBinaryFromReader);
      msg.setRate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiredAt(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web.AddBookingContactInfoRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web.AddBookingContactInfoRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web.AddBookingContactInfoRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.AddBookingContactInfoRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hotel_hotel_pb.HubRateData.serializeBinaryToWriter
    );
  }
  f = message.getExpiredAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.hotel.web.AddBookingContactInfoRes.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.AddBookingContactInfoRes} returns this
 */
proto.hotel.web.AddBookingContactInfoRes.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional hotel.HubRateData rate = 2;
 * @return {?proto.hotel.HubRateData}
 */
proto.hotel.web.AddBookingContactInfoRes.prototype.getRate = function() {
  return /** @type{?proto.hotel.HubRateData} */ (
    jspb.Message.getWrapperField(this, hotel_hotel_pb.HubRateData, 2));
};


/**
 * @param {?proto.hotel.HubRateData|undefined} value
 * @return {!proto.hotel.web.AddBookingContactInfoRes} returns this
*/
proto.hotel.web.AddBookingContactInfoRes.prototype.setRate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web.AddBookingContactInfoRes} returns this
 */
proto.hotel.web.AddBookingContactInfoRes.prototype.clearRate = function() {
  return this.setRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web.AddBookingContactInfoRes.prototype.hasRate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 expired_at = 3;
 * @return {number}
 */
proto.hotel.web.AddBookingContactInfoRes.prototype.getExpiredAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.web.AddBookingContactInfoRes} returns this
 */
proto.hotel.web.AddBookingContactInfoRes.prototype.setExpiredAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool is_success = 4;
 * @return {boolean}
 */
proto.hotel.web.AddBookingContactInfoRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web.AddBookingContactInfoRes} returns this
 */
proto.hotel.web.AddBookingContactInfoRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string error_code = 5;
 * @return {string}
 */
proto.hotel.web.AddBookingContactInfoRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.AddBookingContactInfoRes} returns this
 */
proto.hotel.web.AddBookingContactInfoRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web.GetHotelInfoReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web.GetHotelInfoReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web.GetHotelInfoReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.GetHotelInfoReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    hotelId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web.GetHotelInfoReq}
 */
proto.hotel.web.GetHotelInfoReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web.GetHotelInfoReq;
  return proto.hotel.web.GetHotelInfoReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web.GetHotelInfoReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web.GetHotelInfoReq}
 */
proto.hotel.web.GetHotelInfoReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web.GetHotelInfoReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web.GetHotelInfoReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web.GetHotelInfoReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.GetHotelInfoReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHotelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string hotel_id = 1;
 * @return {string}
 */
proto.hotel.web.GetHotelInfoReq.prototype.getHotelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.GetHotelInfoReq} returns this
 */
proto.hotel.web.GetHotelInfoReq.prototype.setHotelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web.GetHotelInfoRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web.GetHotelInfoRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web.GetHotelInfoRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.GetHotelInfoRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    hotel: (f = msg.getHotel()) && hotel_hotel_pb.HotelDetail.toObject(includeInstance, f),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web.GetHotelInfoRes}
 */
proto.hotel.web.GetHotelInfoRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web.GetHotelInfoRes;
  return proto.hotel.web.GetHotelInfoRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web.GetHotelInfoRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web.GetHotelInfoRes}
 */
proto.hotel.web.GetHotelInfoRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new hotel_hotel_pb.HotelDetail;
      reader.readMessage(value,hotel_hotel_pb.HotelDetail.deserializeBinaryFromReader);
      msg.setHotel(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web.GetHotelInfoRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web.GetHotelInfoRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web.GetHotelInfoRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.GetHotelInfoRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHotel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      hotel_hotel_pb.HotelDetail.serializeBinaryToWriter
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional hotel.HotelDetail hotel = 1;
 * @return {?proto.hotel.HotelDetail}
 */
proto.hotel.web.GetHotelInfoRes.prototype.getHotel = function() {
  return /** @type{?proto.hotel.HotelDetail} */ (
    jspb.Message.getWrapperField(this, hotel_hotel_pb.HotelDetail, 1));
};


/**
 * @param {?proto.hotel.HotelDetail|undefined} value
 * @return {!proto.hotel.web.GetHotelInfoRes} returns this
*/
proto.hotel.web.GetHotelInfoRes.prototype.setHotel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web.GetHotelInfoRes} returns this
 */
proto.hotel.web.GetHotelInfoRes.prototype.clearHotel = function() {
  return this.setHotel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web.GetHotelInfoRes.prototype.hasHotel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool is_success = 2;
 * @return {boolean}
 */
proto.hotel.web.GetHotelInfoRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web.GetHotelInfoRes} returns this
 */
proto.hotel.web.GetHotelInfoRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string error_code = 3;
 * @return {string}
 */
proto.hotel.web.GetHotelInfoRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.GetHotelInfoRes} returns this
 */
proto.hotel.web.GetHotelInfoRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web.GetHotelRoomDetailsReq.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web.GetHotelRoomDetailsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web.GetHotelRoomDetailsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web.GetHotelRoomDetailsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.GetHotelRoomDetailsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    hotelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    checkIn: jspb.Message.getFieldWithDefault(msg, 2, ""),
    checkOut: jspb.Message.getFieldWithDefault(msg, 3, ""),
    occupanciesList: jspb.Message.toObjectList(msg.getOccupanciesList(),
    hotel_base_pb.OccupancyRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web.GetHotelRoomDetailsReq}
 */
proto.hotel.web.GetHotelRoomDetailsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web.GetHotelRoomDetailsReq;
  return proto.hotel.web.GetHotelRoomDetailsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web.GetHotelRoomDetailsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web.GetHotelRoomDetailsReq}
 */
proto.hotel.web.GetHotelRoomDetailsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckIn(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckOut(value);
      break;
    case 4:
      var value = new hotel_base_pb.OccupancyRequest;
      reader.readMessage(value,hotel_base_pb.OccupancyRequest.deserializeBinaryFromReader);
      msg.addOccupancies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web.GetHotelRoomDetailsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web.GetHotelRoomDetailsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web.GetHotelRoomDetailsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.GetHotelRoomDetailsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHotelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCheckIn();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCheckOut();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOccupanciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      hotel_base_pb.OccupancyRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string hotel_id = 1;
 * @return {string}
 */
proto.hotel.web.GetHotelRoomDetailsReq.prototype.getHotelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.GetHotelRoomDetailsReq} returns this
 */
proto.hotel.web.GetHotelRoomDetailsReq.prototype.setHotelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string check_in = 2;
 * @return {string}
 */
proto.hotel.web.GetHotelRoomDetailsReq.prototype.getCheckIn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.GetHotelRoomDetailsReq} returns this
 */
proto.hotel.web.GetHotelRoomDetailsReq.prototype.setCheckIn = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string check_out = 3;
 * @return {string}
 */
proto.hotel.web.GetHotelRoomDetailsReq.prototype.getCheckOut = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.GetHotelRoomDetailsReq} returns this
 */
proto.hotel.web.GetHotelRoomDetailsReq.prototype.setCheckOut = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated hotel.OccupancyRequest occupancies = 4;
 * @return {!Array<!proto.hotel.OccupancyRequest>}
 */
proto.hotel.web.GetHotelRoomDetailsReq.prototype.getOccupanciesList = function() {
  return /** @type{!Array<!proto.hotel.OccupancyRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.OccupancyRequest, 4));
};


/**
 * @param {!Array<!proto.hotel.OccupancyRequest>} value
 * @return {!proto.hotel.web.GetHotelRoomDetailsReq} returns this
*/
proto.hotel.web.GetHotelRoomDetailsReq.prototype.setOccupanciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.hotel.OccupancyRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.OccupancyRequest}
 */
proto.hotel.web.GetHotelRoomDetailsReq.prototype.addOccupancies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.hotel.OccupancyRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web.GetHotelRoomDetailsReq} returns this
 */
proto.hotel.web.GetHotelRoomDetailsReq.prototype.clearOccupanciesList = function() {
  return this.setOccupanciesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web.GetHotelRoomDetailsRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web.GetHotelRoomDetailsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web.GetHotelRoomDetailsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web.GetHotelRoomDetailsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.GetHotelRoomDetailsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomDetailsList: jspb.Message.toObjectList(msg.getRoomDetailsList(),
    hotel_hotel_pb.RoomRate.toObject, includeInstance),
    searchKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web.GetHotelRoomDetailsRes}
 */
proto.hotel.web.GetHotelRoomDetailsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web.GetHotelRoomDetailsRes;
  return proto.hotel.web.GetHotelRoomDetailsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web.GetHotelRoomDetailsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web.GetHotelRoomDetailsRes}
 */
proto.hotel.web.GetHotelRoomDetailsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new hotel_hotel_pb.RoomRate;
      reader.readMessage(value,hotel_hotel_pb.RoomRate.deserializeBinaryFromReader);
      msg.addRoomDetails(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchKey(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web.GetHotelRoomDetailsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web.GetHotelRoomDetailsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web.GetHotelRoomDetailsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.GetHotelRoomDetailsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      hotel_hotel_pb.RoomRate.serializeBinaryToWriter
    );
  }
  f = message.getSearchKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * repeated hotel.RoomRate room_details = 1;
 * @return {!Array<!proto.hotel.RoomRate>}
 */
proto.hotel.web.GetHotelRoomDetailsRes.prototype.getRoomDetailsList = function() {
  return /** @type{!Array<!proto.hotel.RoomRate>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_hotel_pb.RoomRate, 1));
};


/**
 * @param {!Array<!proto.hotel.RoomRate>} value
 * @return {!proto.hotel.web.GetHotelRoomDetailsRes} returns this
*/
proto.hotel.web.GetHotelRoomDetailsRes.prototype.setRoomDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.hotel.RoomRate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.RoomRate}
 */
proto.hotel.web.GetHotelRoomDetailsRes.prototype.addRoomDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.hotel.RoomRate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web.GetHotelRoomDetailsRes} returns this
 */
proto.hotel.web.GetHotelRoomDetailsRes.prototype.clearRoomDetailsList = function() {
  return this.setRoomDetailsList([]);
};


/**
 * optional string search_key = 2;
 * @return {string}
 */
proto.hotel.web.GetHotelRoomDetailsRes.prototype.getSearchKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.GetHotelRoomDetailsRes} returns this
 */
proto.hotel.web.GetHotelRoomDetailsRes.prototype.setSearchKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_success = 3;
 * @return {boolean}
 */
proto.hotel.web.GetHotelRoomDetailsRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web.GetHotelRoomDetailsRes} returns this
 */
proto.hotel.web.GetHotelRoomDetailsRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string error_code = 4;
 * @return {string}
 */
proto.hotel.web.GetHotelRoomDetailsRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.GetHotelRoomDetailsRes} returns this
 */
proto.hotel.web.GetHotelRoomDetailsRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web.GetRecommendedHotelsReq.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web.GetRecommendedHotelsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web.GetRecommendedHotelsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web.GetRecommendedHotelsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.GetRecommendedHotelsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    hotelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    checkIn: jspb.Message.getFieldWithDefault(msg, 2, ""),
    checkOut: jspb.Message.getFieldWithDefault(msg, 3, ""),
    occupanciesList: jspb.Message.toObjectList(msg.getOccupanciesList(),
    hotel_base_pb.OccupancyRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web.GetRecommendedHotelsReq}
 */
proto.hotel.web.GetRecommendedHotelsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web.GetRecommendedHotelsReq;
  return proto.hotel.web.GetRecommendedHotelsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web.GetRecommendedHotelsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web.GetRecommendedHotelsReq}
 */
proto.hotel.web.GetRecommendedHotelsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckIn(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckOut(value);
      break;
    case 4:
      var value = new hotel_base_pb.OccupancyRequest;
      reader.readMessage(value,hotel_base_pb.OccupancyRequest.deserializeBinaryFromReader);
      msg.addOccupancies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web.GetRecommendedHotelsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web.GetRecommendedHotelsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web.GetRecommendedHotelsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.GetRecommendedHotelsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHotelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCheckIn();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCheckOut();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOccupanciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      hotel_base_pb.OccupancyRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string hotel_id = 1;
 * @return {string}
 */
proto.hotel.web.GetRecommendedHotelsReq.prototype.getHotelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.GetRecommendedHotelsReq} returns this
 */
proto.hotel.web.GetRecommendedHotelsReq.prototype.setHotelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string check_in = 2;
 * @return {string}
 */
proto.hotel.web.GetRecommendedHotelsReq.prototype.getCheckIn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.GetRecommendedHotelsReq} returns this
 */
proto.hotel.web.GetRecommendedHotelsReq.prototype.setCheckIn = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string check_out = 3;
 * @return {string}
 */
proto.hotel.web.GetRecommendedHotelsReq.prototype.getCheckOut = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.GetRecommendedHotelsReq} returns this
 */
proto.hotel.web.GetRecommendedHotelsReq.prototype.setCheckOut = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated hotel.OccupancyRequest occupancies = 4;
 * @return {!Array<!proto.hotel.OccupancyRequest>}
 */
proto.hotel.web.GetRecommendedHotelsReq.prototype.getOccupanciesList = function() {
  return /** @type{!Array<!proto.hotel.OccupancyRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.OccupancyRequest, 4));
};


/**
 * @param {!Array<!proto.hotel.OccupancyRequest>} value
 * @return {!proto.hotel.web.GetRecommendedHotelsReq} returns this
*/
proto.hotel.web.GetRecommendedHotelsReq.prototype.setOccupanciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.hotel.OccupancyRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.OccupancyRequest}
 */
proto.hotel.web.GetRecommendedHotelsReq.prototype.addOccupancies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.hotel.OccupancyRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web.GetRecommendedHotelsReq} returns this
 */
proto.hotel.web.GetRecommendedHotelsReq.prototype.clearOccupanciesList = function() {
  return this.setOccupanciesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web.GetRecommendedHotelsRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web.GetRecommendedHotelsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web.GetRecommendedHotelsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web.GetRecommendedHotelsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.GetRecommendedHotelsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    hotelsList: jspb.Message.toObjectList(msg.getHotelsList(),
    hotel_base_pb.HotelSummary.toObject, includeInstance),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web.GetRecommendedHotelsRes}
 */
proto.hotel.web.GetRecommendedHotelsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web.GetRecommendedHotelsRes;
  return proto.hotel.web.GetRecommendedHotelsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web.GetRecommendedHotelsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web.GetRecommendedHotelsRes}
 */
proto.hotel.web.GetRecommendedHotelsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new hotel_base_pb.HotelSummary;
      reader.readMessage(value,hotel_base_pb.HotelSummary.deserializeBinaryFromReader);
      msg.addHotels(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web.GetRecommendedHotelsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web.GetRecommendedHotelsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web.GetRecommendedHotelsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.GetRecommendedHotelsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHotelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      hotel_base_pb.HotelSummary.serializeBinaryToWriter
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated hotel.HotelSummary hotels = 1;
 * @return {!Array<!proto.hotel.HotelSummary>}
 */
proto.hotel.web.GetRecommendedHotelsRes.prototype.getHotelsList = function() {
  return /** @type{!Array<!proto.hotel.HotelSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.HotelSummary, 1));
};


/**
 * @param {!Array<!proto.hotel.HotelSummary>} value
 * @return {!proto.hotel.web.GetRecommendedHotelsRes} returns this
*/
proto.hotel.web.GetRecommendedHotelsRes.prototype.setHotelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.hotel.HotelSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.HotelSummary}
 */
proto.hotel.web.GetRecommendedHotelsRes.prototype.addHotels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.hotel.HotelSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web.GetRecommendedHotelsRes} returns this
 */
proto.hotel.web.GetRecommendedHotelsRes.prototype.clearHotelsList = function() {
  return this.setHotelsList([]);
};


/**
 * optional bool is_success = 2;
 * @return {boolean}
 */
proto.hotel.web.GetRecommendedHotelsRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web.GetRecommendedHotelsRes} returns this
 */
proto.hotel.web.GetRecommendedHotelsRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string error_code = 3;
 * @return {string}
 */
proto.hotel.web.GetRecommendedHotelsRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.GetRecommendedHotelsRes} returns this
 */
proto.hotel.web.GetRecommendedHotelsRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web.GetHotelReviewsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web.GetHotelReviewsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web.GetHotelReviewsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.GetHotelReviewsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    hotelId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web.GetHotelReviewsReq}
 */
proto.hotel.web.GetHotelReviewsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web.GetHotelReviewsReq;
  return proto.hotel.web.GetHotelReviewsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web.GetHotelReviewsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web.GetHotelReviewsReq}
 */
proto.hotel.web.GetHotelReviewsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web.GetHotelReviewsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web.GetHotelReviewsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web.GetHotelReviewsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.GetHotelReviewsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHotelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string hotel_id = 1;
 * @return {string}
 */
proto.hotel.web.GetHotelReviewsReq.prototype.getHotelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.GetHotelReviewsReq} returns this
 */
proto.hotel.web.GetHotelReviewsReq.prototype.setHotelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web.GetHotelReviewsRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web.GetHotelReviewsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web.GetHotelReviewsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web.GetHotelReviewsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.GetHotelReviewsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewsList: jspb.Message.toObjectList(msg.getReviewsList(),
    hotel_base_pb.HotelReview.toObject, includeInstance),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web.GetHotelReviewsRes}
 */
proto.hotel.web.GetHotelReviewsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web.GetHotelReviewsRes;
  return proto.hotel.web.GetHotelReviewsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web.GetHotelReviewsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web.GetHotelReviewsRes}
 */
proto.hotel.web.GetHotelReviewsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new hotel_base_pb.HotelReview;
      reader.readMessage(value,hotel_base_pb.HotelReview.deserializeBinaryFromReader);
      msg.addReviews(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web.GetHotelReviewsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web.GetHotelReviewsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web.GetHotelReviewsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.GetHotelReviewsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReviewsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      hotel_base_pb.HotelReview.serializeBinaryToWriter
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated hotel.HotelReview reviews = 1;
 * @return {!Array<!proto.hotel.HotelReview>}
 */
proto.hotel.web.GetHotelReviewsRes.prototype.getReviewsList = function() {
  return /** @type{!Array<!proto.hotel.HotelReview>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.HotelReview, 1));
};


/**
 * @param {!Array<!proto.hotel.HotelReview>} value
 * @return {!proto.hotel.web.GetHotelReviewsRes} returns this
*/
proto.hotel.web.GetHotelReviewsRes.prototype.setReviewsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.hotel.HotelReview=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.HotelReview}
 */
proto.hotel.web.GetHotelReviewsRes.prototype.addReviews = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.hotel.HotelReview, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web.GetHotelReviewsRes} returns this
 */
proto.hotel.web.GetHotelReviewsRes.prototype.clearReviewsList = function() {
  return this.setReviewsList([]);
};


/**
 * optional bool is_success = 2;
 * @return {boolean}
 */
proto.hotel.web.GetHotelReviewsRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web.GetHotelReviewsRes} returns this
 */
proto.hotel.web.GetHotelReviewsRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string error_code = 3;
 * @return {string}
 */
proto.hotel.web.GetHotelReviewsRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.GetHotelReviewsRes} returns this
 */
proto.hotel.web.GetHotelReviewsRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web.RetrieveBookingReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web.RetrieveBookingReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web.RetrieveBookingReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.RetrieveBookingReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    holder: (f = msg.getHolder()) && hotel_booking_pb.RoomHolder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web.RetrieveBookingReq}
 */
proto.hotel.web.RetrieveBookingReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web.RetrieveBookingReq;
  return proto.hotel.web.RetrieveBookingReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web.RetrieveBookingReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web.RetrieveBookingReq}
 */
proto.hotel.web.RetrieveBookingReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 2:
      var value = new hotel_booking_pb.RoomHolder;
      reader.readMessage(value,hotel_booking_pb.RoomHolder.deserializeBinaryFromReader);
      msg.setHolder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web.RetrieveBookingReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web.RetrieveBookingReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web.RetrieveBookingReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.RetrieveBookingReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHolder();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hotel_booking_pb.RoomHolder.serializeBinaryToWriter
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.hotel.web.RetrieveBookingReq.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.RetrieveBookingReq} returns this
 */
proto.hotel.web.RetrieveBookingReq.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional hotel.RoomHolder holder = 2;
 * @return {?proto.hotel.RoomHolder}
 */
proto.hotel.web.RetrieveBookingReq.prototype.getHolder = function() {
  return /** @type{?proto.hotel.RoomHolder} */ (
    jspb.Message.getWrapperField(this, hotel_booking_pb.RoomHolder, 2));
};


/**
 * @param {?proto.hotel.RoomHolder|undefined} value
 * @return {!proto.hotel.web.RetrieveBookingReq} returns this
*/
proto.hotel.web.RetrieveBookingReq.prototype.setHolder = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web.RetrieveBookingReq} returns this
 */
proto.hotel.web.RetrieveBookingReq.prototype.clearHolder = function() {
  return this.setHolder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web.RetrieveBookingReq.prototype.hasHolder = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web.RetrieveBookingRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web.RetrieveBookingRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web.RetrieveBookingRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.RetrieveBookingRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    booking: (f = msg.getBooking()) && hotel_booking_pb.BookingDetail.toObject(includeInstance, f),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web.RetrieveBookingRes}
 */
proto.hotel.web.RetrieveBookingRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web.RetrieveBookingRes;
  return proto.hotel.web.RetrieveBookingRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web.RetrieveBookingRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web.RetrieveBookingRes}
 */
proto.hotel.web.RetrieveBookingRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new hotel_booking_pb.BookingDetail;
      reader.readMessage(value,hotel_booking_pb.BookingDetail.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web.RetrieveBookingRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web.RetrieveBookingRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web.RetrieveBookingRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.RetrieveBookingRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      hotel_booking_pb.BookingDetail.serializeBinaryToWriter
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional hotel.BookingDetail booking = 1;
 * @return {?proto.hotel.BookingDetail}
 */
proto.hotel.web.RetrieveBookingRes.prototype.getBooking = function() {
  return /** @type{?proto.hotel.BookingDetail} */ (
    jspb.Message.getWrapperField(this, hotel_booking_pb.BookingDetail, 1));
};


/**
 * @param {?proto.hotel.BookingDetail|undefined} value
 * @return {!proto.hotel.web.RetrieveBookingRes} returns this
*/
proto.hotel.web.RetrieveBookingRes.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web.RetrieveBookingRes} returns this
 */
proto.hotel.web.RetrieveBookingRes.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web.RetrieveBookingRes.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool is_success = 2;
 * @return {boolean}
 */
proto.hotel.web.RetrieveBookingRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web.RetrieveBookingRes} returns this
 */
proto.hotel.web.RetrieveBookingRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string error_code = 3;
 * @return {string}
 */
proto.hotel.web.RetrieveBookingRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.RetrieveBookingRes} returns this
 */
proto.hotel.web.RetrieveBookingRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web.GetStaticBookingBySessionReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web.GetStaticBookingBySessionReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web.GetStaticBookingBySessionReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.GetStaticBookingBySessionReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web.GetStaticBookingBySessionReq}
 */
proto.hotel.web.GetStaticBookingBySessionReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web.GetStaticBookingBySessionReq;
  return proto.hotel.web.GetStaticBookingBySessionReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web.GetStaticBookingBySessionReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web.GetStaticBookingBySessionReq}
 */
proto.hotel.web.GetStaticBookingBySessionReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web.GetStaticBookingBySessionReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web.GetStaticBookingBySessionReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web.GetStaticBookingBySessionReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.GetStaticBookingBySessionReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.hotel.web.GetStaticBookingBySessionReq.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.GetStaticBookingBySessionReq} returns this
 */
proto.hotel.web.GetStaticBookingBySessionReq.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web.GetStaticBookingBySessionRes.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web.GetStaticBookingBySessionRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web.GetStaticBookingBySessionRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web.GetStaticBookingBySessionRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.GetStaticBookingBySessionRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    booking: (f = msg.getBooking()) && hotel_booking_pb.BookingDetail.toObject(includeInstance, f),
    bedOptionsList: jspb.Message.toObjectList(msg.getBedOptionsList(),
    hotel_base_pb.BedOption.toObject, includeInstance),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web.GetStaticBookingBySessionRes}
 */
proto.hotel.web.GetStaticBookingBySessionRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web.GetStaticBookingBySessionRes;
  return proto.hotel.web.GetStaticBookingBySessionRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web.GetStaticBookingBySessionRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web.GetStaticBookingBySessionRes}
 */
proto.hotel.web.GetStaticBookingBySessionRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new hotel_booking_pb.BookingDetail;
      reader.readMessage(value,hotel_booking_pb.BookingDetail.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    case 2:
      var value = new hotel_base_pb.BedOption;
      reader.readMessage(value,hotel_base_pb.BedOption.deserializeBinaryFromReader);
      msg.addBedOptions(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web.GetStaticBookingBySessionRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web.GetStaticBookingBySessionRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web.GetStaticBookingBySessionRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.GetStaticBookingBySessionRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      hotel_booking_pb.BookingDetail.serializeBinaryToWriter
    );
  }
  f = message.getBedOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      hotel_base_pb.BedOption.serializeBinaryToWriter
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional hotel.BookingDetail booking = 1;
 * @return {?proto.hotel.BookingDetail}
 */
proto.hotel.web.GetStaticBookingBySessionRes.prototype.getBooking = function() {
  return /** @type{?proto.hotel.BookingDetail} */ (
    jspb.Message.getWrapperField(this, hotel_booking_pb.BookingDetail, 1));
};


/**
 * @param {?proto.hotel.BookingDetail|undefined} value
 * @return {!proto.hotel.web.GetStaticBookingBySessionRes} returns this
*/
proto.hotel.web.GetStaticBookingBySessionRes.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web.GetStaticBookingBySessionRes} returns this
 */
proto.hotel.web.GetStaticBookingBySessionRes.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web.GetStaticBookingBySessionRes.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated hotel.BedOption bed_options = 2;
 * @return {!Array<!proto.hotel.BedOption>}
 */
proto.hotel.web.GetStaticBookingBySessionRes.prototype.getBedOptionsList = function() {
  return /** @type{!Array<!proto.hotel.BedOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.BedOption, 2));
};


/**
 * @param {!Array<!proto.hotel.BedOption>} value
 * @return {!proto.hotel.web.GetStaticBookingBySessionRes} returns this
*/
proto.hotel.web.GetStaticBookingBySessionRes.prototype.setBedOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.hotel.BedOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.BedOption}
 */
proto.hotel.web.GetStaticBookingBySessionRes.prototype.addBedOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.hotel.BedOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web.GetStaticBookingBySessionRes} returns this
 */
proto.hotel.web.GetStaticBookingBySessionRes.prototype.clearBedOptionsList = function() {
  return this.setBedOptionsList([]);
};


/**
 * optional bool is_success = 3;
 * @return {boolean}
 */
proto.hotel.web.GetStaticBookingBySessionRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web.GetStaticBookingBySessionRes} returns this
 */
proto.hotel.web.GetStaticBookingBySessionRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string error_code = 4;
 * @return {string}
 */
proto.hotel.web.GetStaticBookingBySessionRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.GetStaticBookingBySessionRes} returns this
 */
proto.hotel.web.GetStaticBookingBySessionRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web.ReserveBookingReq.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web.ReserveBookingReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web.ReserveBookingReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web.ReserveBookingReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.ReserveBookingReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hotelId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    roomId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    rateId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    checkIn: jspb.Message.getFieldWithDefault(msg, 5, ""),
    checkOut: jspb.Message.getFieldWithDefault(msg, 6, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 7, ""),
    roomOccupanciesList: jspb.Message.toObjectList(msg.getRoomOccupanciesList(),
    hotel_booking_pb.RoomOccupancyRequest.toObject, includeInstance),
    destinationId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web.ReserveBookingReq}
 */
proto.hotel.web.ReserveBookingReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web.ReserveBookingReq;
  return proto.hotel.web.ReserveBookingReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web.ReserveBookingReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web.ReserveBookingReq}
 */
proto.hotel.web.ReserveBookingReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRateId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckIn(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckOut(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 8:
      var value = new hotel_booking_pb.RoomOccupancyRequest;
      reader.readMessage(value,hotel_booking_pb.RoomOccupancyRequest.deserializeBinaryFromReader);
      msg.addRoomOccupancies(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web.ReserveBookingReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web.ReserveBookingReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web.ReserveBookingReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.ReserveBookingReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHotelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRateId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCheckIn();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCheckOut();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRoomOccupanciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      hotel_booking_pb.RoomOccupancyRequest.serializeBinaryToWriter
    );
  }
  f = message.getDestinationId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string search_key = 1;
 * @return {string}
 */
proto.hotel.web.ReserveBookingReq.prototype.getSearchKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.ReserveBookingReq} returns this
 */
proto.hotel.web.ReserveBookingReq.prototype.setSearchKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string hotel_id = 2;
 * @return {string}
 */
proto.hotel.web.ReserveBookingReq.prototype.getHotelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.ReserveBookingReq} returns this
 */
proto.hotel.web.ReserveBookingReq.prototype.setHotelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string room_id = 3;
 * @return {string}
 */
proto.hotel.web.ReserveBookingReq.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.ReserveBookingReq} returns this
 */
proto.hotel.web.ReserveBookingReq.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string rate_id = 4;
 * @return {string}
 */
proto.hotel.web.ReserveBookingReq.prototype.getRateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.ReserveBookingReq} returns this
 */
proto.hotel.web.ReserveBookingReq.prototype.setRateId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string check_in = 5;
 * @return {string}
 */
proto.hotel.web.ReserveBookingReq.prototype.getCheckIn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.ReserveBookingReq} returns this
 */
proto.hotel.web.ReserveBookingReq.prototype.setCheckIn = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string check_out = 6;
 * @return {string}
 */
proto.hotel.web.ReserveBookingReq.prototype.getCheckOut = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.ReserveBookingReq} returns this
 */
proto.hotel.web.ReserveBookingReq.prototype.setCheckOut = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string currency = 7;
 * @return {string}
 */
proto.hotel.web.ReserveBookingReq.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.ReserveBookingReq} returns this
 */
proto.hotel.web.ReserveBookingReq.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated hotel.RoomOccupancyRequest room_occupancies = 8;
 * @return {!Array<!proto.hotel.RoomOccupancyRequest>}
 */
proto.hotel.web.ReserveBookingReq.prototype.getRoomOccupanciesList = function() {
  return /** @type{!Array<!proto.hotel.RoomOccupancyRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_booking_pb.RoomOccupancyRequest, 8));
};


/**
 * @param {!Array<!proto.hotel.RoomOccupancyRequest>} value
 * @return {!proto.hotel.web.ReserveBookingReq} returns this
*/
proto.hotel.web.ReserveBookingReq.prototype.setRoomOccupanciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.hotel.RoomOccupancyRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.RoomOccupancyRequest}
 */
proto.hotel.web.ReserveBookingReq.prototype.addRoomOccupancies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.hotel.RoomOccupancyRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web.ReserveBookingReq} returns this
 */
proto.hotel.web.ReserveBookingReq.prototype.clearRoomOccupanciesList = function() {
  return this.setRoomOccupanciesList([]);
};


/**
 * optional string destination_id = 9;
 * @return {string}
 */
proto.hotel.web.ReserveBookingReq.prototype.getDestinationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.ReserveBookingReq} returns this
 */
proto.hotel.web.ReserveBookingReq.prototype.setDestinationId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web.ReserveBookingRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web.ReserveBookingRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web.ReserveBookingRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.ReserveBookingRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web.ReserveBookingRes}
 */
proto.hotel.web.ReserveBookingRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web.ReserveBookingRes;
  return proto.hotel.web.ReserveBookingRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web.ReserveBookingRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web.ReserveBookingRes}
 */
proto.hotel.web.ReserveBookingRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web.ReserveBookingRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web.ReserveBookingRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web.ReserveBookingRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.ReserveBookingRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.hotel.web.ReserveBookingRes.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.ReserveBookingRes} returns this
 */
proto.hotel.web.ReserveBookingRes.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_success = 3;
 * @return {boolean}
 */
proto.hotel.web.ReserveBookingRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web.ReserveBookingRes} returns this
 */
proto.hotel.web.ReserveBookingRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string error_code = 4;
 * @return {string}
 */
proto.hotel.web.ReserveBookingRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.ReserveBookingRes} returns this
 */
proto.hotel.web.ReserveBookingRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web.GetBookingSelectionReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web.GetBookingSelectionReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web.GetBookingSelectionReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.GetBookingSelectionReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    selectionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web.GetBookingSelectionReq}
 */
proto.hotel.web.GetBookingSelectionReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web.GetBookingSelectionReq;
  return proto.hotel.web.GetBookingSelectionReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web.GetBookingSelectionReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web.GetBookingSelectionReq}
 */
proto.hotel.web.GetBookingSelectionReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelectionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web.GetBookingSelectionReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web.GetBookingSelectionReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web.GetBookingSelectionReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.GetBookingSelectionReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelectionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string selection_id = 1;
 * @return {string}
 */
proto.hotel.web.GetBookingSelectionReq.prototype.getSelectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.GetBookingSelectionReq} returns this
 */
proto.hotel.web.GetBookingSelectionReq.prototype.setSelectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web.GetBookingSelectionRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web.GetBookingSelectionRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web.GetBookingSelectionRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.GetBookingSelectionRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    selection: (f = msg.getSelection()) && hotel_booking_selection_pb.BookingSelection.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web.GetBookingSelectionRes}
 */
proto.hotel.web.GetBookingSelectionRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web.GetBookingSelectionRes;
  return proto.hotel.web.GetBookingSelectionRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web.GetBookingSelectionRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web.GetBookingSelectionRes}
 */
proto.hotel.web.GetBookingSelectionRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new hotel_booking_selection_pb.BookingSelection;
      reader.readMessage(value,hotel_booking_selection_pb.BookingSelection.deserializeBinaryFromReader);
      msg.setSelection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web.GetBookingSelectionRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web.GetBookingSelectionRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web.GetBookingSelectionRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web.GetBookingSelectionRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSelection();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      hotel_booking_selection_pb.BookingSelection.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.hotel.web.GetBookingSelectionRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web.GetBookingSelectionRes} returns this
 */
proto.hotel.web.GetBookingSelectionRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.hotel.web.GetBookingSelectionRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web.GetBookingSelectionRes} returns this
 */
proto.hotel.web.GetBookingSelectionRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional hotel.BookingSelection selection = 3;
 * @return {?proto.hotel.BookingSelection}
 */
proto.hotel.web.GetBookingSelectionRes.prototype.getSelection = function() {
  return /** @type{?proto.hotel.BookingSelection} */ (
    jspb.Message.getWrapperField(this, hotel_booking_selection_pb.BookingSelection, 3));
};


/**
 * @param {?proto.hotel.BookingSelection|undefined} value
 * @return {!proto.hotel.web.GetBookingSelectionRes} returns this
*/
proto.hotel.web.GetBookingSelectionRes.prototype.setSelection = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web.GetBookingSelectionRes} returns this
 */
proto.hotel.web.GetBookingSelectionRes.prototype.clearSelection = function() {
  return this.setSelection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web.GetBookingSelectionRes.prototype.hasSelection = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.hotel.web);
