import {
  Button,
  ButtonLoading,
  CloseIcon,
  Form,
  FormInputPassword,
} from '@common-ui';
import { IModal } from '@sky-booking-config/types';
import { Modal } from 'antd';
import { useForm } from 'react-hook-form';
import { Dispatch, SetStateAction } from 'react';
import useCreateAndUpdateIssuer from '../useCreateAndUpdateIssuer';
import { UserStatus } from '@api/partner/base_pb';

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  modalInfo: TModalInfo;
};

export type TModalInfo = IModal & {
  id: string;
  status: UserStatus;
};

type TFormModal = {
  id: string;
  password: string;
  status: UserStatus;
};

const ModalChangePasswordIssuer = ({
  isModalOpen,
  setIsModalOpen,
  modalInfo,
}: Props) => {
  const changePasswordMethod = useForm<TFormModal>({
    defaultValues: {
      id: modalInfo.id,
      password: '',
      status: modalInfo.status,
    },
  });

  const {
    isLoadingUpdateIssuer,
    updateIssuerMethod,
    handleSubmitUpdateIssuer,
  } = useCreateAndUpdateIssuer();

  const onOk = async (modalInfo: TModalInfo) => {
    updateIssuerMethod.setValue('id', modalInfo.id);
    updateIssuerMethod.setValue('status', modalInfo.status);
    updateIssuerMethod.setValue('setIsModalOpen', setIsModalOpen);
    await updateIssuerMethod.handleSubmit(handleSubmitUpdateIssuer)();
  };

  const onCancel = () => {
    setIsModalOpen(false);
  };

  const genModalTitle = (modalInfo: TModalInfo) => {
    return 'Đổi mật khẩu';
  };

  const genModalContent = (modalInfo: TModalInfo) => {
    return (
      <Form
        methods={updateIssuerMethod}
        onSubmit={handleSubmitUpdateIssuer}
        className='flex flex-col gap-y-5'>
        <FormInputPassword
          name='password'
          label='Mật khẩu'
          placeholder='Nhập mật khẩu'
          rules={{
            required: 'Nhập mật khẩu',
            validate: {
              minLength: (value: string) =>
                value.length >= 8 || 'Tối thiểu 8 kí tự',
            },
            onChange(event) {
              changePasswordMethod.clearErrors('password');
            },
          }}
        />
      </Form>
    );
  };

  const genModalFooter = (modalInfo: TModalInfo) => {
    return (
      <div className='flex w-full space-x-2.5 whitespace-nowrap'>
        <Button
          disabled={isLoadingUpdateIssuer}
          theme='red'
          className='flex-1'
          onClick={() => onCancel()}>
          Hủy bỏ
        </Button>
        <ButtonLoading
          loading={isLoadingUpdateIssuer}
          isShowChildWhenLoading
          className='flex-1'
          onClick={() => onOk(modalInfo)}>
          Cập nhật
        </ButtonLoading>
      </div>
    );
  };

  return (
    <Modal
      centered
      open={isModalOpen}
      title={genModalTitle(modalInfo)}
      footer={genModalFooter(modalInfo)}
      closeIcon={
        modalInfo.closable ? (
          <CloseIcon
            className='w-5 h-5 text-[#999999]'
            onClick={() => onCancel()}
          />
        ) : null
      }>
      {genModalContent(modalInfo)}
    </Modal>
  );
};

export default ModalChangePasswordIssuer;
