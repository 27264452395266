// source: skyhub/flight.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var base_base_pb = require('../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
var base_enum_pb = require('../base/enum_pb.js');
goog.object.extend(proto, base_enum_pb);
goog.exportSymbol('proto.skyhub.BookingDetails', null, global);
goog.exportSymbol('proto.skyhub.BookingInfo', null, global);
goog.exportSymbol('proto.skyhub.Contact', null, global);
goog.exportSymbol('proto.skyhub.FlightItinerary', null, global);
goog.exportSymbol('proto.skyhub.ItinerarySegment', null, global);
goog.exportSymbol('proto.skyhub.PaxInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.BookingInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skyhub.BookingInfo.repeatedFields_, null);
};
goog.inherits(proto.skyhub.BookingInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.BookingInfo.displayName = 'proto.skyhub.BookingInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.BookingDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skyhub.BookingDetails.repeatedFields_, null);
};
goog.inherits(proto.skyhub.BookingDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.BookingDetails.displayName = 'proto.skyhub.BookingDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.FlightItinerary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skyhub.FlightItinerary.repeatedFields_, null);
};
goog.inherits(proto.skyhub.FlightItinerary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.FlightItinerary.displayName = 'proto.skyhub.FlightItinerary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.ItinerarySegment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skyhub.ItinerarySegment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.ItinerarySegment.displayName = 'proto.skyhub.ItinerarySegment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.PaxInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skyhub.PaxInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.PaxInfo.displayName = 'proto.skyhub.PaxInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.Contact = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skyhub.Contact, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.Contact.displayName = 'proto.skyhub.Contact';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skyhub.BookingInfo.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.BookingInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.BookingInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.BookingInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.BookingInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    officeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bookingCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itinerariesList: jspb.Message.toObjectList(msg.getItinerariesList(),
    proto.skyhub.FlightItinerary.toObject, includeInstance),
    notified: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.BookingInfo}
 */
proto.skyhub.BookingInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.BookingInfo;
  return proto.skyhub.BookingInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.BookingInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.BookingInfo}
 */
proto.skyhub.BookingInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfficeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 3:
      var value = new proto.skyhub.FlightItinerary;
      reader.readMessage(value,proto.skyhub.FlightItinerary.deserializeBinaryFromReader);
      msg.addItineraries(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNotified(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.BookingInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.BookingInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.BookingInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.BookingInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfficeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItinerariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.skyhub.FlightItinerary.serializeBinaryToWriter
    );
  }
  f = message.getNotified();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string office_id = 1;
 * @return {string}
 */
proto.skyhub.BookingInfo.prototype.getOfficeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.BookingInfo} returns this
 */
proto.skyhub.BookingInfo.prototype.setOfficeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string booking_code = 2;
 * @return {string}
 */
proto.skyhub.BookingInfo.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.BookingInfo} returns this
 */
proto.skyhub.BookingInfo.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated FlightItinerary itineraries = 3;
 * @return {!Array<!proto.skyhub.FlightItinerary>}
 */
proto.skyhub.BookingInfo.prototype.getItinerariesList = function() {
  return /** @type{!Array<!proto.skyhub.FlightItinerary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skyhub.FlightItinerary, 3));
};


/**
 * @param {!Array<!proto.skyhub.FlightItinerary>} value
 * @return {!proto.skyhub.BookingInfo} returns this
*/
proto.skyhub.BookingInfo.prototype.setItinerariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.skyhub.FlightItinerary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skyhub.FlightItinerary}
 */
proto.skyhub.BookingInfo.prototype.addItineraries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.skyhub.FlightItinerary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skyhub.BookingInfo} returns this
 */
proto.skyhub.BookingInfo.prototype.clearItinerariesList = function() {
  return this.setItinerariesList([]);
};


/**
 * optional bool notified = 4;
 * @return {boolean}
 */
proto.skyhub.BookingInfo.prototype.getNotified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skyhub.BookingInfo} returns this
 */
proto.skyhub.BookingInfo.prototype.setNotified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skyhub.BookingDetails.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.BookingDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.BookingDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.BookingDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.BookingDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    officeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bookingCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    itinerariesList: jspb.Message.toObjectList(msg.getItinerariesList(),
    proto.skyhub.FlightItinerary.toObject, includeInstance),
    listPaxList: jspb.Message.toObjectList(msg.getListPaxList(),
    proto.skyhub.PaxInfo.toObject, includeInstance),
    contactInfo: (f = msg.getContactInfo()) && proto.skyhub.Contact.toObject(includeInstance, f),
    lastTicketingDate: jspb.Message.getFieldWithDefault(msg, 7, 0),
    vat: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    partnerName: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.BookingDetails}
 */
proto.skyhub.BookingDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.BookingDetails;
  return proto.skyhub.BookingDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.BookingDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.BookingDetails}
 */
proto.skyhub.BookingDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfficeId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 4:
      var value = new proto.skyhub.FlightItinerary;
      reader.readMessage(value,proto.skyhub.FlightItinerary.deserializeBinaryFromReader);
      msg.addItineraries(value);
      break;
    case 5:
      var value = new proto.skyhub.PaxInfo;
      reader.readMessage(value,proto.skyhub.PaxInfo.deserializeBinaryFromReader);
      msg.addListPax(value);
      break;
    case 6:
      var value = new proto.skyhub.Contact;
      reader.readMessage(value,proto.skyhub.Contact.deserializeBinaryFromReader);
      msg.setContactInfo(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastTicketingDate(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVat(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.BookingDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.BookingDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.BookingDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.BookingDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOfficeId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getItinerariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.skyhub.FlightItinerary.serializeBinaryToWriter
    );
  }
  f = message.getListPaxList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.skyhub.PaxInfo.serializeBinaryToWriter
    );
  }
  f = message.getContactInfo();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.skyhub.Contact.serializeBinaryToWriter
    );
  }
  f = message.getLastTicketingDate();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getVat();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getPartnerName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.skyhub.BookingDetails.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.BookingDetails} returns this
 */
proto.skyhub.BookingDetails.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string office_id = 2;
 * @return {string}
 */
proto.skyhub.BookingDetails.prototype.getOfficeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.BookingDetails} returns this
 */
proto.skyhub.BookingDetails.prototype.setOfficeId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string booking_code = 3;
 * @return {string}
 */
proto.skyhub.BookingDetails.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.BookingDetails} returns this
 */
proto.skyhub.BookingDetails.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated FlightItinerary itineraries = 4;
 * @return {!Array<!proto.skyhub.FlightItinerary>}
 */
proto.skyhub.BookingDetails.prototype.getItinerariesList = function() {
  return /** @type{!Array<!proto.skyhub.FlightItinerary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skyhub.FlightItinerary, 4));
};


/**
 * @param {!Array<!proto.skyhub.FlightItinerary>} value
 * @return {!proto.skyhub.BookingDetails} returns this
*/
proto.skyhub.BookingDetails.prototype.setItinerariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.skyhub.FlightItinerary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skyhub.FlightItinerary}
 */
proto.skyhub.BookingDetails.prototype.addItineraries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.skyhub.FlightItinerary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skyhub.BookingDetails} returns this
 */
proto.skyhub.BookingDetails.prototype.clearItinerariesList = function() {
  return this.setItinerariesList([]);
};


/**
 * repeated PaxInfo list_pax = 5;
 * @return {!Array<!proto.skyhub.PaxInfo>}
 */
proto.skyhub.BookingDetails.prototype.getListPaxList = function() {
  return /** @type{!Array<!proto.skyhub.PaxInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skyhub.PaxInfo, 5));
};


/**
 * @param {!Array<!proto.skyhub.PaxInfo>} value
 * @return {!proto.skyhub.BookingDetails} returns this
*/
proto.skyhub.BookingDetails.prototype.setListPaxList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.skyhub.PaxInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skyhub.PaxInfo}
 */
proto.skyhub.BookingDetails.prototype.addListPax = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.skyhub.PaxInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skyhub.BookingDetails} returns this
 */
proto.skyhub.BookingDetails.prototype.clearListPaxList = function() {
  return this.setListPaxList([]);
};


/**
 * optional Contact contact_info = 6;
 * @return {?proto.skyhub.Contact}
 */
proto.skyhub.BookingDetails.prototype.getContactInfo = function() {
  return /** @type{?proto.skyhub.Contact} */ (
    jspb.Message.getWrapperField(this, proto.skyhub.Contact, 6));
};


/**
 * @param {?proto.skyhub.Contact|undefined} value
 * @return {!proto.skyhub.BookingDetails} returns this
*/
proto.skyhub.BookingDetails.prototype.setContactInfo = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skyhub.BookingDetails} returns this
 */
proto.skyhub.BookingDetails.prototype.clearContactInfo = function() {
  return this.setContactInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skyhub.BookingDetails.prototype.hasContactInfo = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 last_ticketing_date = 7;
 * @return {number}
 */
proto.skyhub.BookingDetails.prototype.getLastTicketingDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.BookingDetails} returns this
 */
proto.skyhub.BookingDetails.prototype.setLastTicketingDate = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool vat = 8;
 * @return {boolean}
 */
proto.skyhub.BookingDetails.prototype.getVat = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skyhub.BookingDetails} returns this
 */
proto.skyhub.BookingDetails.prototype.setVat = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string partner_name = 9;
 * @return {string}
 */
proto.skyhub.BookingDetails.prototype.getPartnerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.BookingDetails} returns this
 */
proto.skyhub.BookingDetails.prototype.setPartnerName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skyhub.FlightItinerary.repeatedFields_ = [16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.FlightItinerary.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.FlightItinerary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.FlightItinerary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.FlightItinerary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    index: jspb.Message.getFieldWithDefault(msg, 2, 0),
    reservationCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fareBasis: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cabinClass: jspb.Message.getFieldWithDefault(msg, 5, ""),
    bookingClass: jspb.Message.getFieldWithDefault(msg, 6, ""),
    availability: jspb.Message.getFieldWithDefault(msg, 7, 0),
    departPlace: jspb.Message.getFieldWithDefault(msg, 8, ""),
    departDate: jspb.Message.getFieldWithDefault(msg, 9, 0),
    arrivalPlace: jspb.Message.getFieldWithDefault(msg, 10, ""),
    arrivalDate: jspb.Message.getFieldWithDefault(msg, 11, 0),
    carrierMarketing: jspb.Message.getFieldWithDefault(msg, 12, ""),
    flightNumber: jspb.Message.getFieldWithDefault(msg, 13, ""),
    flightDuration: jspb.Message.getFieldWithDefault(msg, 14, 0),
    stopNumber: jspb.Message.getFieldWithDefault(msg, 15, 0),
    segmentsList: jspb.Message.toObjectList(msg.getSegmentsList(),
    proto.skyhub.ItinerarySegment.toObject, includeInstance),
    departDateUtc: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.FlightItinerary}
 */
proto.skyhub.FlightItinerary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.FlightItinerary;
  return proto.skyhub.FlightItinerary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.FlightItinerary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.FlightItinerary}
 */
proto.skyhub.FlightItinerary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFareBasis(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCabinClass(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingClass(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvailability(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartPlace(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDepartDate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalPlace(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setArrivalDate(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarrierMarketing(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightNumber(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlightDuration(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStopNumber(value);
      break;
    case 16:
      var value = new proto.skyhub.ItinerarySegment;
      reader.readMessage(value,proto.skyhub.ItinerarySegment.deserializeBinaryFromReader);
      msg.addSegments(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDepartDateUtc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.FlightItinerary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.FlightItinerary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.FlightItinerary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.FlightItinerary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getReservationCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFareBasis();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCabinClass();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBookingClass();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAvailability();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getDepartPlace();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDepartDate();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getArrivalPlace();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getArrivalDate();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getCarrierMarketing();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getFlightNumber();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getFlightDuration();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getStopNumber();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getSegmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.skyhub.ItinerarySegment.serializeBinaryToWriter
    );
  }
  f = message.getDepartDateUtc();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.skyhub.FlightItinerary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.FlightItinerary} returns this
 */
proto.skyhub.FlightItinerary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 index = 2;
 * @return {number}
 */
proto.skyhub.FlightItinerary.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.FlightItinerary} returns this
 */
proto.skyhub.FlightItinerary.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string reservation_code = 3;
 * @return {string}
 */
proto.skyhub.FlightItinerary.prototype.getReservationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.FlightItinerary} returns this
 */
proto.skyhub.FlightItinerary.prototype.setReservationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string fare_basis = 4;
 * @return {string}
 */
proto.skyhub.FlightItinerary.prototype.getFareBasis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.FlightItinerary} returns this
 */
proto.skyhub.FlightItinerary.prototype.setFareBasis = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string cabin_class = 5;
 * @return {string}
 */
proto.skyhub.FlightItinerary.prototype.getCabinClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.FlightItinerary} returns this
 */
proto.skyhub.FlightItinerary.prototype.setCabinClass = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string booking_class = 6;
 * @return {string}
 */
proto.skyhub.FlightItinerary.prototype.getBookingClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.FlightItinerary} returns this
 */
proto.skyhub.FlightItinerary.prototype.setBookingClass = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 availability = 7;
 * @return {number}
 */
proto.skyhub.FlightItinerary.prototype.getAvailability = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.FlightItinerary} returns this
 */
proto.skyhub.FlightItinerary.prototype.setAvailability = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string depart_place = 8;
 * @return {string}
 */
proto.skyhub.FlightItinerary.prototype.getDepartPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.FlightItinerary} returns this
 */
proto.skyhub.FlightItinerary.prototype.setDepartPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 depart_date = 9;
 * @return {number}
 */
proto.skyhub.FlightItinerary.prototype.getDepartDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.FlightItinerary} returns this
 */
proto.skyhub.FlightItinerary.prototype.setDepartDate = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string arrival_place = 10;
 * @return {string}
 */
proto.skyhub.FlightItinerary.prototype.getArrivalPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.FlightItinerary} returns this
 */
proto.skyhub.FlightItinerary.prototype.setArrivalPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 arrival_date = 11;
 * @return {number}
 */
proto.skyhub.FlightItinerary.prototype.getArrivalDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.FlightItinerary} returns this
 */
proto.skyhub.FlightItinerary.prototype.setArrivalDate = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string carrier_marketing = 12;
 * @return {string}
 */
proto.skyhub.FlightItinerary.prototype.getCarrierMarketing = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.FlightItinerary} returns this
 */
proto.skyhub.FlightItinerary.prototype.setCarrierMarketing = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string flight_number = 13;
 * @return {string}
 */
proto.skyhub.FlightItinerary.prototype.getFlightNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.FlightItinerary} returns this
 */
proto.skyhub.FlightItinerary.prototype.setFlightNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int32 flight_duration = 14;
 * @return {number}
 */
proto.skyhub.FlightItinerary.prototype.getFlightDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.FlightItinerary} returns this
 */
proto.skyhub.FlightItinerary.prototype.setFlightDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 stop_number = 15;
 * @return {number}
 */
proto.skyhub.FlightItinerary.prototype.getStopNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.FlightItinerary} returns this
 */
proto.skyhub.FlightItinerary.prototype.setStopNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * repeated ItinerarySegment segments = 16;
 * @return {!Array<!proto.skyhub.ItinerarySegment>}
 */
proto.skyhub.FlightItinerary.prototype.getSegmentsList = function() {
  return /** @type{!Array<!proto.skyhub.ItinerarySegment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skyhub.ItinerarySegment, 16));
};


/**
 * @param {!Array<!proto.skyhub.ItinerarySegment>} value
 * @return {!proto.skyhub.FlightItinerary} returns this
*/
proto.skyhub.FlightItinerary.prototype.setSegmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.skyhub.ItinerarySegment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skyhub.ItinerarySegment}
 */
proto.skyhub.FlightItinerary.prototype.addSegments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.skyhub.ItinerarySegment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skyhub.FlightItinerary} returns this
 */
proto.skyhub.FlightItinerary.prototype.clearSegmentsList = function() {
  return this.setSegmentsList([]);
};


/**
 * optional int64 depart_date_utc = 17;
 * @return {number}
 */
proto.skyhub.FlightItinerary.prototype.getDepartDateUtc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.FlightItinerary} returns this
 */
proto.skyhub.FlightItinerary.prototype.setDepartDateUtc = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.ItinerarySegment.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.ItinerarySegment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.ItinerarySegment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.ItinerarySegment.toObject = function(includeInstance, msg) {
  var f, obj = {
    index: jspb.Message.getFieldWithDefault(msg, 1, 0),
    departPlace: jspb.Message.getFieldWithDefault(msg, 2, ""),
    departDate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    departDt: jspb.Message.getFieldWithDefault(msg, 4, ""),
    departTerminal: jspb.Message.getFieldWithDefault(msg, 5, ""),
    arrivalPlace: jspb.Message.getFieldWithDefault(msg, 6, ""),
    arrivalDate: jspb.Message.getFieldWithDefault(msg, 7, 0),
    arrivalDt: jspb.Message.getFieldWithDefault(msg, 8, ""),
    arrivalTerminal: jspb.Message.getFieldWithDefault(msg, 9, ""),
    carrierMarketing: jspb.Message.getFieldWithDefault(msg, 10, ""),
    carrierOperator: jspb.Message.getFieldWithDefault(msg, 11, ""),
    flightNumber: jspb.Message.getFieldWithDefault(msg, 12, ""),
    aircraft: jspb.Message.getFieldWithDefault(msg, 13, ""),
    bookingClass: jspb.Message.getFieldWithDefault(msg, 14, ""),
    cabinClassCode: jspb.Message.getFieldWithDefault(msg, 15, ""),
    fareBasis: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.ItinerarySegment}
 */
proto.skyhub.ItinerarySegment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.ItinerarySegment;
  return proto.skyhub.ItinerarySegment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.ItinerarySegment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.ItinerarySegment}
 */
proto.skyhub.ItinerarySegment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartPlace(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDepartDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartDt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartTerminal(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalPlace(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setArrivalDate(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalDt(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalTerminal(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarrierMarketing(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarrierOperator(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightNumber(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAircraft(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingClass(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCabinClassCode(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setFareBasis(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.ItinerarySegment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.ItinerarySegment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.ItinerarySegment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.ItinerarySegment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDepartPlace();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDepartDate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDepartDt();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDepartTerminal();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getArrivalPlace();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getArrivalDate();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getArrivalDt();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getArrivalTerminal();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCarrierMarketing();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCarrierOperator();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getFlightNumber();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAircraft();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getBookingClass();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getCabinClassCode();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getFareBasis();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional int32 index = 1;
 * @return {number}
 */
proto.skyhub.ItinerarySegment.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.ItinerarySegment} returns this
 */
proto.skyhub.ItinerarySegment.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string depart_place = 2;
 * @return {string}
 */
proto.skyhub.ItinerarySegment.prototype.getDepartPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.ItinerarySegment} returns this
 */
proto.skyhub.ItinerarySegment.prototype.setDepartPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 depart_date = 3;
 * @return {number}
 */
proto.skyhub.ItinerarySegment.prototype.getDepartDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.ItinerarySegment} returns this
 */
proto.skyhub.ItinerarySegment.prototype.setDepartDate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string depart_dt = 4;
 * @return {string}
 */
proto.skyhub.ItinerarySegment.prototype.getDepartDt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.ItinerarySegment} returns this
 */
proto.skyhub.ItinerarySegment.prototype.setDepartDt = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string depart_terminal = 5;
 * @return {string}
 */
proto.skyhub.ItinerarySegment.prototype.getDepartTerminal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.ItinerarySegment} returns this
 */
proto.skyhub.ItinerarySegment.prototype.setDepartTerminal = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string arrival_place = 6;
 * @return {string}
 */
proto.skyhub.ItinerarySegment.prototype.getArrivalPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.ItinerarySegment} returns this
 */
proto.skyhub.ItinerarySegment.prototype.setArrivalPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 arrival_date = 7;
 * @return {number}
 */
proto.skyhub.ItinerarySegment.prototype.getArrivalDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.ItinerarySegment} returns this
 */
proto.skyhub.ItinerarySegment.prototype.setArrivalDate = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string arrival_dt = 8;
 * @return {string}
 */
proto.skyhub.ItinerarySegment.prototype.getArrivalDt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.ItinerarySegment} returns this
 */
proto.skyhub.ItinerarySegment.prototype.setArrivalDt = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string arrival_terminal = 9;
 * @return {string}
 */
proto.skyhub.ItinerarySegment.prototype.getArrivalTerminal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.ItinerarySegment} returns this
 */
proto.skyhub.ItinerarySegment.prototype.setArrivalTerminal = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string carrier_marketing = 10;
 * @return {string}
 */
proto.skyhub.ItinerarySegment.prototype.getCarrierMarketing = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.ItinerarySegment} returns this
 */
proto.skyhub.ItinerarySegment.prototype.setCarrierMarketing = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string carrier_operator = 11;
 * @return {string}
 */
proto.skyhub.ItinerarySegment.prototype.getCarrierOperator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.ItinerarySegment} returns this
 */
proto.skyhub.ItinerarySegment.prototype.setCarrierOperator = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string flight_number = 12;
 * @return {string}
 */
proto.skyhub.ItinerarySegment.prototype.getFlightNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.ItinerarySegment} returns this
 */
proto.skyhub.ItinerarySegment.prototype.setFlightNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string aircraft = 13;
 * @return {string}
 */
proto.skyhub.ItinerarySegment.prototype.getAircraft = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.ItinerarySegment} returns this
 */
proto.skyhub.ItinerarySegment.prototype.setAircraft = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string booking_class = 14;
 * @return {string}
 */
proto.skyhub.ItinerarySegment.prototype.getBookingClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.ItinerarySegment} returns this
 */
proto.skyhub.ItinerarySegment.prototype.setBookingClass = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string cabin_class_code = 15;
 * @return {string}
 */
proto.skyhub.ItinerarySegment.prototype.getCabinClassCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.ItinerarySegment} returns this
 */
proto.skyhub.ItinerarySegment.prototype.setCabinClassCode = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string fare_basis = 16;
 * @return {string}
 */
proto.skyhub.ItinerarySegment.prototype.getFareBasis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.ItinerarySegment} returns this
 */
proto.skyhub.ItinerarySegment.prototype.setFareBasis = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.PaxInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.PaxInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.PaxInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.PaxInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    surname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    givenName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dob: jspb.Message.getFieldWithDefault(msg, 4, 0),
    gender: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.PaxInfo}
 */
proto.skyhub.PaxInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.PaxInfo;
  return proto.skyhub.PaxInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.PaxInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.PaxInfo}
 */
proto.skyhub.PaxInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.base.PassengerType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSurname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGivenName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDob(value);
      break;
    case 5:
      var value = /** @type {!proto.base.GENDER} */ (reader.readEnum());
      msg.setGender(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.PaxInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.PaxInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.PaxInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.PaxInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSurname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGivenName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getGender();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional base.PassengerType type = 1;
 * @return {!proto.base.PassengerType}
 */
proto.skyhub.PaxInfo.prototype.getType = function() {
  return /** @type {!proto.base.PassengerType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.base.PassengerType} value
 * @return {!proto.skyhub.PaxInfo} returns this
 */
proto.skyhub.PaxInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string surname = 2;
 * @return {string}
 */
proto.skyhub.PaxInfo.prototype.getSurname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.PaxInfo} returns this
 */
proto.skyhub.PaxInfo.prototype.setSurname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string given_name = 3;
 * @return {string}
 */
proto.skyhub.PaxInfo.prototype.getGivenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.PaxInfo} returns this
 */
proto.skyhub.PaxInfo.prototype.setGivenName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 dob = 4;
 * @return {number}
 */
proto.skyhub.PaxInfo.prototype.getDob = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.PaxInfo} returns this
 */
proto.skyhub.PaxInfo.prototype.setDob = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.skyhub.PaxInfo} returns this
 */
proto.skyhub.PaxInfo.prototype.clearDob = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skyhub.PaxInfo.prototype.hasDob = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional base.GENDER gender = 5;
 * @return {!proto.base.GENDER}
 */
proto.skyhub.PaxInfo.prototype.getGender = function() {
  return /** @type {!proto.base.GENDER} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.base.GENDER} value
 * @return {!proto.skyhub.PaxInfo} returns this
 */
proto.skyhub.PaxInfo.prototype.setGender = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.Contact.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.Contact.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.Contact} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.Contact.toObject = function(includeInstance, msg) {
  var f, obj = {
    surname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    givenName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 5, ""),
    gender: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.Contact}
 */
proto.skyhub.Contact.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.Contact;
  return proto.skyhub.Contact.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.Contact} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.Contact}
 */
proto.skyhub.Contact.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSurname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGivenName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = /** @type {!proto.base.GENDER} */ (reader.readEnum());
      msg.setGender(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.Contact.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.Contact.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.Contact} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.Contact.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSurname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGivenName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getGender();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string surname = 1;
 * @return {string}
 */
proto.skyhub.Contact.prototype.getSurname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.Contact} returns this
 */
proto.skyhub.Contact.prototype.setSurname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string given_name = 2;
 * @return {string}
 */
proto.skyhub.Contact.prototype.getGivenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.Contact} returns this
 */
proto.skyhub.Contact.prototype.setGivenName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string phone_code = 3;
 * @return {string}
 */
proto.skyhub.Contact.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.Contact} returns this
 */
proto.skyhub.Contact.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone = 4;
 * @return {string}
 */
proto.skyhub.Contact.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.Contact} returns this
 */
proto.skyhub.Contact.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.skyhub.Contact.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.Contact} returns this
 */
proto.skyhub.Contact.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional base.GENDER gender = 6;
 * @return {!proto.base.GENDER}
 */
proto.skyhub.Contact.prototype.getGender = function() {
  return /** @type {!proto.base.GENDER} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.base.GENDER} value
 * @return {!proto.skyhub.Contact} returns this
 */
proto.skyhub.Contact.prototype.setGender = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


goog.object.extend(exports, proto.skyhub);
