import { Button, Form, FormInput } from '@common-ui';
import { getApiErrorMessages } from '@core/utils';
import { useToast } from '@sky-booking-config/hooks/internals';
import { useAppDispatch } from '@sky-booking-config/stores';
import { userAuthenticate } from '@sky-booking-config/stores/reducers/user';
import { ILoginFormV2 } from '@tixlabs/grpc-client/web-partnership';
import { useForm } from 'react-hook-form';

export const LoginCtn = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();

  const methods = useForm<ILoginFormV2>({
    defaultValues: { username: '', password: '' },
  });

  const handleLogin = async (data: ILoginFormV2) => {
    try {
      await dispatch(userAuthenticate(data)).unwrap();
      methods.reset();
    } catch (error) {
      const [message] = getApiErrorMessages(error);
      if (message.includes('PARTNERSHIP_NOT_FOUND_USER')) {
        toast.error('Tài khoản không tồn tại');
      } else if (
        message.includes('INVALID_INPUT') ||
        message.includes('PARTNERSHIP_PASSWORD_WRONG')
      ) {
        toast.error('Tên đăng nhập hoặc mật khẩu không chính xác');
      } else {
        toast.error('Lỗi đăng nhập');
      }
    }
  };

  return (
    <div className='p-10  pr-[150px] flex space-x-10 bg-white shadow-auth-ctn rounded-lg relative'>
      <Form
        onSubmit={handleLogin}
        methods={methods}
        className='w-[350px] shrink-0 z-10'>
        <div className='space-y-[30px]'>
          <div className='space-y-2.5'>
            <div className='text-2xl font-semibold'>Đăng nhập</div>
            <div className='text-neutral-8'>Chào mừng bạn quay lại</div>
          </div>
          <div className='space-y-5 '>
            <FormInput
              name='username'
              label='Tên đăng nhập'
              placeholder='Nhập tên đăng nhập'
              labelProps={{ className: 'text-neutral-7' }}
              rules={{
                required: 'Nhập tên đăng nhập',
                validate: {
                  specialCharacter: (v) =>
                    !v.match(/[^a-zA-Z0-9]/) || 'Không chứa ký tự đặc biệt',
                },
              }}
            />
            <FormInput
              name='password'
              label='Mật khẩu'
              placeholder='Nhập mật khẩu'
              inputProps={{ type: 'password' }}
              labelProps={{ className: 'text-neutral-7' }}
              rules={{
                required: 'Nhập mật khẩu',
              }}
            />
            <Button
              className='w-full'
              type='submit'
              disabled={methods.formState.isSubmitting}>
              Đăng nhập
            </Button>
            {/* <div className='py-2.5 flex justify-center'>
              <Link
                to='../forgot-password'
                className='text-md font-semibold text-primary'>
                Quên mât khẩu?
              </Link>
            </div> */}
          </div>
        </div>
      </Form>
      {/* <div className='space-y-10 z-10'>
        <div className='flex flex-col'>
          <LogoSkyBookingIcon className='w-[137px] h-[44px] text-primary' />
          <span>Nền tảng đặt vé máy bay toàn diện</span>
        </div>
        <div className='space-y-4'>
          <div>Lý do bạn nên trở thành đối tác của SKYBooking:</div>
          <div className='space-y-2.5'>
            <div className='flex space-x-1'>
              <CheckFillIcon className='text-green-6' />
              <span>Vốn đầu tư thấp, thu nhập hấp dẫn</span>
            </div>
            <div className='flex space-x-1'>
              <CheckFillIcon className='text-green-6' />
              <span>Kinh doanh nhàn rỗi không cần nghiệp vụ</span>
            </div>
            <div className='flex space-x-1'>
              <CheckFillIcon className='text-green-6' />
              <span>Nền tảng website thông minh</span>
            </div>
            <div className='flex space-x-1'>
              <CheckFillIcon className='text-green-6' />
              <span>Đối tác uy tín, dịch vụ chất lượng</span>
            </div>
          </div>
          <button className='px-4 py-2.5 rounded bg-green-1 text-green-6 text-md font-semibold hover:bg-green-2 active:bg-green-3'>
            Đăng ký ngay
          </button>
        </div>
      </div> */}
      <img
        src='./assets/images/img-auth.png'
        alt='background'
        className='absolute right-0 top-0 bottom-0 z-0 h-full'
      />
    </div>
  );
};

export default LoginCtn;
