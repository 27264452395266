import { Button, Edit2FillIcon } from '@common-ui';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { TPagination } from './useManageOfficeId';
import { LabelTransferBookingConfigStatus } from '@sky-booking-config/utils';
import cn from 'classnames';
import { ETransferBookingConfigStatus } from '@tixlabs/grpc-client';
import { useTime } from '@sky-booking-config/hooks/internals';
import { IListTransferBookingConfig } from '@tixlabs/grpc-client/web-partnership';

type Props = {
  data: IListTransferBookingConfig[];
  isLoading?: boolean;
  pagination: TPagination;
  onChangePage: (pageNumber: number) => Promise<void>;
};

const TitleColumns = ({ title }: { title: string }) => {
  return (
    <span className='font-semibold leading-[25px] text-neutral-7'>{title}</span>
  );
};

const StatusTransferBookingConfig = ({
  status,
}: {
  status: ETransferBookingConfigStatus;
}) => {
  return (
    <span
      className={cn('font-bold', {
        'text-green-7':
          status ===
          ETransferBookingConfigStatus.TRANSFERBOOKINGCONFIGSTATUSACTIVE,
        'text-red-500':
          status ===
          ETransferBookingConfigStatus.TRANSFERBOOKINGCONFIGSTATUSDISABLE,
      })}>
      {LabelTransferBookingConfigStatus[status]}
    </span>
  );
};

export const FeeTableContent = ({
  data,
  isLoading,
  onChangePage,
  pagination,
}: Props) => {
  const { formatDateTime } = useTime();
  const navigate = useNavigate();

  // const { userData } = useUserStore();
  const columns: ColumnsType<IListTransferBookingConfig> = [
    {
      key: 'requestId',
      dataIndex: 'requestId',
      title: <TitleColumns title='Giá trị phí' />,
      render: (_, record) => {
        return <span>{record.fee}</span>;
      },
    },
    {
      key: 'requesterName',
      dataIndex: 'requesterName',
      title: <TitleColumns title='Đại lý' />,
      render: (_, record) => {
        return <span>{record.agentCode}</span>;
      },
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: <TitleColumns title='Trạng thái' />,
      render: (_, record) => {
        return <StatusTransferBookingConfig status={record.status} />;
      },
    },
    {
      key: 'action',
      dataIndex: 'action',
      title: <TitleColumns title='Hành động' />,
      render: (_, record) => {
        return (
          <div className='flex items-center gap-x-1.5'>
            <Button
              variant='ghost'
              onClick={() => {
                navigate(
                  `./update?feeId=${record.id}&agentCode=${record.agentCode}&fee=${record.fee}&status=${record.status}`
                );
              }}
              prefixIcon={<Edit2FillIcon />}>
              Chỉnh sửa
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <Table
      dataSource={data}
      columns={columns}
      size='small'
      loading={isLoading}
      pagination={
        pagination.totalPage > 1 && {
          pageSize: pagination.pageLimit,
          current: pagination.pageCurrent,
          total: pagination.totalRecord,
          className: 'space-x-2.5',
          onChange: onChangePage,
        }
      }
    />
  );
};

export default FeeTableContent;
