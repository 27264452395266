import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import cn from 'classnames';
import {
  ForwardedRef,
  Fragment,
  ReactNode,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { InputSize, PassPropsType, TBaseInputProps } from '@common-ui';
import { ByComparator } from '@headlessui/react/dist/types';
import { SelectOptionItem } from '@tixlabs/types';

import VirtualList from 'rc-virtual-list';
import { useThrottleValue } from '@core/hooks';
import { toLowerCaseNonAccentVietnamese } from '@core/utils';

type VirtualListProps = Parameters<typeof VirtualList>[number];

export type SelectOptionItemCombine =
  | SelectOptionItem<unknown>
  | string
  | number
  | boolean;

export type SelectSearchProps = {
  isOnlyRead?: boolean;
  className?: string;
  // selectButtonClassName?: string;
  inputSearchClassName?: string;
  optionSelectSearchClassName?: string;
  optionGroupSelectSearchClassName?: string;
  selectOptions: SelectOptionItem[];
  compareBy?: ByComparator<SelectOptionItemCombine>;
  disabled?: boolean;
  isOnlyValue?: boolean;
  isRoot?: boolean;
  isVirtualMode?: boolean;
  isAllowUncheck?: boolean;
  compareFunc?: (query: string, option: SelectOptionItem) => boolean;
  handleSearch?: (keyword: string) => void;
  renderLabel?: (option: SelectOptionItem) => ReactNode;
  displayValue?: (option: SelectOptionItem | undefined) => string;
  virtualListProps?: Omit<VirtualListProps, 'data'>;
} & PassPropsType<SelectOptionItemCombine> &
  TBaseInputProps;

const defaultCompare: ByComparator<SelectOptionItemCombine> = (a, b) => {
  if (typeof a === 'object' && typeof b === 'object') {
    return a.value === b.value;
  }

  // (isOnlyValue ? value : (value as SelectOptionItem)?.value)
  return a === b;
  // return a.name.toLowerCase() === b.name.toLowerCase()
};

const defaultFilter = (option: SelectOptionItem, query: string) => {
  const textCompare = toLowerCaseNonAccentVietnamese(
    JSON.stringify(option.label + option.label)?.toLowerCase()
  );
  return textCompare.includes(query.toLowerCase());
};

function SelectSearchInner(
  {
    name,
    selectOptions,
    compareBy = defaultCompare,
    disabled,
    className,
    inputSearchClassName,
    optionSelectSearchClassName,
    optionGroupSelectSearchClassName,
    isError,
    placeholder,
    value,
    onChange,
    inputSize = InputSize.SM,
    isOnlyValue,
    isRoot = true,
    handleSearch,
    renderLabel,
    displayValue,
    compareFunc,
    isVirtualMode,
    virtualListProps,
    isAllowUncheck = false,
    isOnlyRead = false,

  }: SelectSearchProps,
  // }: SelectSearchProps<SelectOptionItem<unknown>[][number]>,
  ref: ForwardedRef<HTMLInputElement>
) {
  const [query, setQuery] = useState('trigger');
  const [isFocusSearch, setIsFocusSearch] = useState(false);
  const trimQuery = useMemo(() => {
    return query.trim();
  }, [query]);

  const throttleQuery = useThrottleValue<string>(trimQuery, 500);

  const filteredSelectOptions =
    throttleQuery === ''
      ? selectOptions
      : selectOptions.filter((option) => {
          return typeof compareFunc === 'function'
            ? compareFunc(throttleQuery, option)
            : defaultFilter(option, throttleQuery);
        });

  const selectedOptionValue = selectOptions.find(
    (option) =>
      option.value ===
      (isOnlyValue ? value : (value as SelectOptionItem)?.value)
  );

  const selectInputLabel = selectedOptionValue
    ? selectedOptionValue?.label
    : '';

  const handleOnChange = (newValueOption: SelectOptionItem) => {
    if (!onChange || newValueOption === undefined) {
      return;
    }

    if (
      JSON.stringify(selectedOptionValue?.value) ===
      JSON.stringify(newValueOption)
    ) {
      if (isAllowUncheck) {
        onChange('');
        return;
      }
    } else {
      onChange(newValueOption);
      return;
    }
  };

  useEffect(() => {
    if (isFocusSearch && throttleQuery) {
      handleSearch &&
        handleSearch(toLowerCaseNonAccentVietnamese(throttleQuery));
    }
  }, [throttleQuery]);

  useEffect(() => {
    setQuery('');
  }, []);

  useEffect(() => {
    if (isFocusSearch) {
      if (selectedOptionValue) {
        setQuery(
          toLowerCaseNonAccentVietnamese(
            selectedOptionValue.label
              .trim()
              .slice(
                0,
                Math.min(Math.round(selectedOptionValue.label.length / 4), 2)
              )
          )
        );
      } else {
        setQuery('');
      }
    }
  }, [isFocusSearch]);

  return (
    <div className={cn('text-black', className)}>
      <Combobox
        by={compareBy}
        value={value}
        disabled={!!disabled}
        onChange={handleOnChange}>
        <div
          className={cn({
            relative: isRoot,
          })}>
          <div
            className={cn(
              'base-select relative flex space-x-2.5 items-center',
              `base-select-${inputSize}`,
              inputSearchClassName,
              // isError && '!ring-common-error'
              {
                'bg-theme-black/5 ': disabled,
                error: isError,
              }
            )}>
            {selectedOptionValue?.icon as React.ReactNode}
            <Combobox.Input
              ref={ref}
              className={cn('w-full min-w-[45px] bg-inherit', {
                'bg-transparent ': disabled,
              })}
              onFocus={() => {
                setIsFocusSearch(true);
              }}
              onBlur={() => {
                setIsFocusSearch(false);
              }}
              displayValue={() =>
                typeof displayValue === 'function'
                  ? displayValue(selectedOptionValue)
                  : selectInputLabel || ''
              }
              onChange={(event) =>
                setQuery(toLowerCaseNonAccentVietnamese(event.target.value))
              }
              placeholder={placeholder}
            />

            <Combobox.Button className={cn('absolute inset-y-0 right-0 pr-2')}>
              <ChevronDownIcon
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
                onClick={() => {
                  if (handleSearch) {
                    selectedOptionValue
                      ? handleSearch(
                          toLowerCaseNonAccentVietnamese(
                            selectedOptionValue.label
                              .trim()
                              .slice(
                                0,
                                Math.min(
                                  Math.round(
                                    selectedOptionValue.label.length / 4
                                  ),
                                  2
                                )
                              )
                          )
                        )
                      : handleSearch('');
                  } else {
                    if (selectedOptionValue) {
                      setQuery(
                        toLowerCaseNonAccentVietnamese(
                          selectedOptionValue.label
                            .trim()
                            .slice(
                              0,
                              Math.min(
                                Math.round(
                                  selectedOptionValue.label.length / 4
                                ),
                                2
                              )
                            )
                        )
                      );
                    } else {
                      query !== '' && setQuery('');
                      // setQuery('');
                    }
                  }
                }}
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <Combobox.Options
              className={cn(
                'absolute left-0  mt-1 max-h-60 w-full overflow-auto rounded-xl bg-theme-white text-sm shadow-lg z-50',
                'bg-theme-white shadow-popover-search-flight',
                {
                  'py-2': trimQuery !== '',
                },
                optionGroupSelectSearchClassName
              )}>
              {filteredSelectOptions.length === 0 && trimQuery !== '' ? (
                <div className='relative cursor-default select-none px-4 py-2 text-gray-700'>
                  Nothing found.
                </div>
              ) : isVirtualMode && filteredSelectOptions.length > 20 ? (
                <VirtualList
                  height={240}
                  itemHeight={45}
                  {...virtualListProps}
                  data={filteredSelectOptions}
                  itemKey={virtualListProps?.itemKey || ''}>
                  {(option, idx) => (
                    <div key={idx}>
                      <Combobox.Option
                        value={isOnlyValue ? option.value : option}
                        disabled={option.disabled}
                        className={({ active, selected }) =>
                          cn(
                            'relative cursor-pointer select-none space-x-2 px-3 text-theme-black',
                            active || selected ? 'bg-primary/5 ' : '',
                            // 'relative flex cursor-pointer select-none items-center space-x-2 px-4 py-2',
                            optionSelectSearchClassName,
                            // active || selected
                            //   ? 'bg-primary/20 text-black'
                            //   : 'text-gray-900',
                            option.disabled &&
                              '!cursor-not-allowed bg-common-disabled/20 text-opacity-50'
                          )
                        }>
                        <div className='flex items-center space-x-2 border-b border-theme-black/20 py-3 '>
                          {typeof renderLabel === 'function' ? (
                            renderLabel(option)
                          ) : (
                            <div
                              className='flex space-x-2.5 truncate font-normal items-center'
                              title={`${option.label}`}>
                              {option.icon as React.ReactNode}
                              <span className={`block `}>{option.label}</span>
                            </div>
                          )}
                          <CheckIcon
                            className={cn(
                              'absolute right-2 top-1/2 h-5 w-5 flex-shrink-0 -translate-y-1/2 text-primary',
                              selectedOptionValue?.value === option.value
                                ? 'visible'
                                : 'invisible'
                            )}
                            aria-hidden='true'
                          />
                        </div>
                      </Combobox.Option>
                    </div>
                  )}
                </VirtualList>
              ) : (
                filteredSelectOptions.map((option, idx) => (
                  <Combobox.Option
                    key={idx}
                    value={isOnlyValue ? option.value : option}
                    disabled={option.disabled}
                    className={({ active, selected }) =>
                      cn(
                        'relative cursor-pointer select-none space-x-2 px-3 text-theme-black',
                        active || selected ? 'bg-primary/5 ' : '',
                        optionSelectSearchClassName,

                        option.disabled &&
                          '!cursor-not-allowed bg-common-disabled/20 text-opacity-50'
                      )
                    }>
                    <div className='flex items-center space-x-2 border-b border-theme-black/20 py-3 '>
                      {typeof renderLabel === 'function' ? (
                        renderLabel(option)
                      ) : (
                        <div
                          className='flex space-x-2.5 truncate font-normal items-center'
                          title={`${option.label}`}>
                          {option.icon as React.ReactNode}
                          <span className={`block `}>{option.label}</span>
                        </div>
                      )}
                      <CheckIcon
                        className={cn(
                          'absolute right-2 top-1/2 h-5 w-5 flex-shrink-0 -translate-y-1/2 text-primary',
                          selectedOptionValue?.value === option.value
                            ? 'visible'
                            : 'invisible'
                        )}
                        aria-hidden='true'
                      />
                    </div>
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}
export const SelectSearch = forwardRef(SelectSearchInner);
SelectSearch.displayName = 'SelectSearch';

export default SelectSearch;
