// source: hotel/transaction.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var base_enum_pb = require('../base/enum_pb.js');
goog.object.extend(proto, base_enum_pb);
goog.exportSymbol('proto.hotel.Sender', null, global);
goog.exportSymbol('proto.hotel.Transaction', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.Transaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.Transaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.Transaction.displayName = 'proto.hotel.Transaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.Sender = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.Sender, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.Sender.displayName = 'proto.hotel.Sender';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.Transaction.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.Transaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.Transaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.Transaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    partnershipId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    partnershopId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    agentCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    orderCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    type: jspb.Message.getFieldWithDefault(msg, 8, 0),
    paymentMethod: jspb.Message.getFieldWithDefault(msg, 9, 0),
    sender: (f = msg.getSender()) && proto.hotel.Sender.toObject(includeInstance, f),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 12, ""),
    fee: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    description: jspb.Message.getFieldWithDefault(msg, 14, ""),
    transactionSource: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.Transaction}
 */
proto.hotel.Transaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.Transaction;
  return proto.hotel.Transaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.Transaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.Transaction}
 */
proto.hotel.Transaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnershipId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnershopId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentCode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderCode(value);
      break;
    case 7:
      var value = /** @type {!proto.base.TransactionStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {!proto.base.TransactionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 9:
      var value = /** @type {!proto.base.PaymentMethod} */ (reader.readEnum());
      msg.setPaymentMethod(value);
      break;
    case 10:
      var value = new proto.hotel.Sender;
      reader.readMessage(value,proto.hotel.Sender.deserializeBinaryFromReader);
      msg.setSender(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFee(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.Transaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.Transaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.Transaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.Transaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPartnershipId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPartnershopId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAgentCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOrderCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getPaymentMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getSender();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.hotel.Sender.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getFee();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getTransactionSource();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.hotel.Transaction.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Transaction} returns this
 */
proto.hotel.Transaction.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 created_at = 2;
 * @return {number}
 */
proto.hotel.Transaction.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.Transaction} returns this
 */
proto.hotel.Transaction.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string partnership_id = 3;
 * @return {string}
 */
proto.hotel.Transaction.prototype.getPartnershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Transaction} returns this
 */
proto.hotel.Transaction.prototype.setPartnershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string partnershop_id = 4;
 * @return {string}
 */
proto.hotel.Transaction.prototype.getPartnershopId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Transaction} returns this
 */
proto.hotel.Transaction.prototype.setPartnershopId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string agent_code = 5;
 * @return {string}
 */
proto.hotel.Transaction.prototype.getAgentCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Transaction} returns this
 */
proto.hotel.Transaction.prototype.setAgentCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string order_code = 6;
 * @return {string}
 */
proto.hotel.Transaction.prototype.getOrderCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Transaction} returns this
 */
proto.hotel.Transaction.prototype.setOrderCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional base.TransactionStatus status = 7;
 * @return {!proto.base.TransactionStatus}
 */
proto.hotel.Transaction.prototype.getStatus = function() {
  return /** @type {!proto.base.TransactionStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.base.TransactionStatus} value
 * @return {!proto.hotel.Transaction} returns this
 */
proto.hotel.Transaction.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional base.TransactionType type = 8;
 * @return {!proto.base.TransactionType}
 */
proto.hotel.Transaction.prototype.getType = function() {
  return /** @type {!proto.base.TransactionType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.base.TransactionType} value
 * @return {!proto.hotel.Transaction} returns this
 */
proto.hotel.Transaction.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional base.PaymentMethod payment_method = 9;
 * @return {!proto.base.PaymentMethod}
 */
proto.hotel.Transaction.prototype.getPaymentMethod = function() {
  return /** @type {!proto.base.PaymentMethod} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.base.PaymentMethod} value
 * @return {!proto.hotel.Transaction} returns this
 */
proto.hotel.Transaction.prototype.setPaymentMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional Sender sender = 10;
 * @return {?proto.hotel.Sender}
 */
proto.hotel.Transaction.prototype.getSender = function() {
  return /** @type{?proto.hotel.Sender} */ (
    jspb.Message.getWrapperField(this, proto.hotel.Sender, 10));
};


/**
 * @param {?proto.hotel.Sender|undefined} value
 * @return {!proto.hotel.Transaction} returns this
*/
proto.hotel.Transaction.prototype.setSender = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.Transaction} returns this
 */
proto.hotel.Transaction.prototype.clearSender = function() {
  return this.setSender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.Transaction.prototype.hasSender = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional double amount = 11;
 * @return {number}
 */
proto.hotel.Transaction.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.Transaction} returns this
 */
proto.hotel.Transaction.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional string currency = 12;
 * @return {string}
 */
proto.hotel.Transaction.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Transaction} returns this
 */
proto.hotel.Transaction.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional double fee = 13;
 * @return {number}
 */
proto.hotel.Transaction.prototype.getFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.Transaction} returns this
 */
proto.hotel.Transaction.prototype.setFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional string description = 14;
 * @return {string}
 */
proto.hotel.Transaction.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Transaction} returns this
 */
proto.hotel.Transaction.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string transaction_source = 15;
 * @return {string}
 */
proto.hotel.Transaction.prototype.getTransactionSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Transaction} returns this
 */
proto.hotel.Transaction.prototype.setTransactionSource = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.Sender.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.Sender.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.Sender} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.Sender.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    username: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.Sender}
 */
proto.hotel.Sender.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.Sender;
  return proto.hotel.Sender.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.Sender} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.Sender}
 */
proto.hotel.Sender.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.Sender.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.Sender.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.Sender} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.Sender.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.hotel.Sender.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Sender} returns this
 */
proto.hotel.Sender.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.hotel.Sender.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Sender} returns this
 */
proto.hotel.Sender.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string username = 3;
 * @return {string}
 */
proto.hotel.Sender.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Sender} returns this
 */
proto.hotel.Sender.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone = 4;
 * @return {string}
 */
proto.hotel.Sender.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Sender} returns this
 */
proto.hotel.Sender.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.hotel.Sender.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Sender} returns this
 */
proto.hotel.Sender.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


goog.object.extend(exports, proto.hotel);
