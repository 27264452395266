// source: hotel/web_partner/hotel.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var hotel_booking_pb = require('../../hotel/booking_pb.js');
goog.object.extend(proto, hotel_booking_pb);
var base_base_pb = require('../../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
var hotel_base_pb = require('../../hotel/base_pb.js');
goog.object.extend(proto, hotel_base_pb);
var hotel_hotel_pb = require('../../hotel/hotel_pb.js');
goog.object.extend(proto, hotel_hotel_pb);
var hotel_booking_selection_pb = require('../../hotel/booking_selection_pb.js');
goog.object.extend(proto, hotel_booking_selection_pb);
var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.hotel.web_partner.AddBookingContactInfoReq', null, global);
goog.exportSymbol('proto.hotel.web_partner.AddBookingContactInfoRes', null, global);
goog.exportSymbol('proto.hotel.web_partner.CancelBookingReq', null, global);
goog.exportSymbol('proto.hotel.web_partner.CancelBookingRes', null, global);
goog.exportSymbol('proto.hotel.web_partner.DeleteBookingSelectionReq', null, global);
goog.exportSymbol('proto.hotel.web_partner.DeleteBookingSelectionRes', null, global);
goog.exportSymbol('proto.hotel.web_partner.DeleteRateSelectionReq', null, global);
goog.exportSymbol('proto.hotel.web_partner.DeleteRateSelectionRes', null, global);
goog.exportSymbol('proto.hotel.web_partner.ExportBookingsReq', null, global);
goog.exportSymbol('proto.hotel.web_partner.ExportBookingsRes', null, global);
goog.exportSymbol('proto.hotel.web_partner.GetBookingDetailRequest', null, global);
goog.exportSymbol('proto.hotel.web_partner.GetBookingDetailResponse', null, global);
goog.exportSymbol('proto.hotel.web_partner.GetBookingSelectionReq', null, global);
goog.exportSymbol('proto.hotel.web_partner.GetBookingSelectionRes', null, global);
goog.exportSymbol('proto.hotel.web_partner.GetHotelInfoReq', null, global);
goog.exportSymbol('proto.hotel.web_partner.GetHotelInfoRes', null, global);
goog.exportSymbol('proto.hotel.web_partner.GetHotelReviewsReq', null, global);
goog.exportSymbol('proto.hotel.web_partner.GetHotelReviewsRes', null, global);
goog.exportSymbol('proto.hotel.web_partner.GetHotelRoomDetailsReq', null, global);
goog.exportSymbol('proto.hotel.web_partner.GetHotelRoomDetailsRes', null, global);
goog.exportSymbol('proto.hotel.web_partner.GetRecommendedHotelsReq', null, global);
goog.exportSymbol('proto.hotel.web_partner.GetRecommendedHotelsRes', null, global);
goog.exportSymbol('proto.hotel.web_partner.GetStaticBookingBySessionReq', null, global);
goog.exportSymbol('proto.hotel.web_partner.GetStaticBookingBySessionRes', null, global);
goog.exportSymbol('proto.hotel.web_partner.HotelBookingSelectionReq', null, global);
goog.exportSymbol('proto.hotel.web_partner.ListBookingSelectionReq', null, global);
goog.exportSymbol('proto.hotel.web_partner.ListBookingSelectionRes', null, global);
goog.exportSymbol('proto.hotel.web_partner.ListBookingsFilter', null, global);
goog.exportSymbol('proto.hotel.web_partner.ListBookingsRequest', null, global);
goog.exportSymbol('proto.hotel.web_partner.ListBookingsResponse', null, global);
goog.exportSymbol('proto.hotel.web_partner.OrderBookingReq', null, global);
goog.exportSymbol('proto.hotel.web_partner.OrderBookingRes', null, global);
goog.exportSymbol('proto.hotel.web_partner.PriceCheckReq', null, global);
goog.exportSymbol('proto.hotel.web_partner.PriceCheckRes', null, global);
goog.exportSymbol('proto.hotel.web_partner.ReserveBookingReq', null, global);
goog.exportSymbol('proto.hotel.web_partner.ReserveBookingRes', null, global);
goog.exportSymbol('proto.hotel.web_partner.ReserveBookingSelectionReq', null, global);
goog.exportSymbol('proto.hotel.web_partner.ReserveBookingSelectionRes', null, global);
goog.exportSymbol('proto.hotel.web_partner.RetrieveBookingReq', null, global);
goog.exportSymbol('proto.hotel.web_partner.RetrieveBookingRes', null, global);
goog.exportSymbol('proto.hotel.web_partner.RoomBookingSelectionReq', null, global);
goog.exportSymbol('proto.hotel.web_partner.SearchDestinationsReq', null, global);
goog.exportSymbol('proto.hotel.web_partner.SearchDestinationsRes', null, global);
goog.exportSymbol('proto.hotel.web_partner.SearchHotelReq', null, global);
goog.exportSymbol('proto.hotel.web_partner.SearchHotelRes', null, global);
goog.exportSymbol('proto.hotel.web_partner.UpdateBookingSelectionReq', null, global);
goog.exportSymbol('proto.hotel.web_partner.UpdateBookingSelectionRes', null, global);
goog.exportSymbol('proto.hotel.web_partner.UpdatePriceAdjustment', null, global);
goog.exportSymbol('proto.hotel.web_partner.UpsertBookingSelectionReq', null, global);
goog.exportSymbol('proto.hotel.web_partner.UpsertBookingSelectionRes', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.SearchDestinationsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web_partner.SearchDestinationsReq.repeatedFields_, null);
};
goog.inherits(proto.hotel.web_partner.SearchDestinationsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.SearchDestinationsReq.displayName = 'proto.hotel.web_partner.SearchDestinationsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.SearchDestinationsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web_partner.SearchDestinationsRes.repeatedFields_, null);
};
goog.inherits(proto.hotel.web_partner.SearchDestinationsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.SearchDestinationsRes.displayName = 'proto.hotel.web_partner.SearchDestinationsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.SearchHotelReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web_partner.SearchHotelReq.repeatedFields_, null);
};
goog.inherits(proto.hotel.web_partner.SearchHotelReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.SearchHotelReq.displayName = 'proto.hotel.web_partner.SearchHotelReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.SearchHotelRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web_partner.SearchHotelRes.repeatedFields_, null);
};
goog.inherits(proto.hotel.web_partner.SearchHotelRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.SearchHotelRes.displayName = 'proto.hotel.web_partner.SearchHotelRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.GetHotelInfoReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.GetHotelInfoReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.GetHotelInfoReq.displayName = 'proto.hotel.web_partner.GetHotelInfoReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.GetHotelInfoRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.GetHotelInfoRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.GetHotelInfoRes.displayName = 'proto.hotel.web_partner.GetHotelInfoRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.GetHotelRoomDetailsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web_partner.GetHotelRoomDetailsReq.repeatedFields_, null);
};
goog.inherits(proto.hotel.web_partner.GetHotelRoomDetailsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.GetHotelRoomDetailsReq.displayName = 'proto.hotel.web_partner.GetHotelRoomDetailsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.GetHotelRoomDetailsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web_partner.GetHotelRoomDetailsRes.repeatedFields_, null);
};
goog.inherits(proto.hotel.web_partner.GetHotelRoomDetailsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.GetHotelRoomDetailsRes.displayName = 'proto.hotel.web_partner.GetHotelRoomDetailsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.GetRecommendedHotelsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web_partner.GetRecommendedHotelsReq.repeatedFields_, null);
};
goog.inherits(proto.hotel.web_partner.GetRecommendedHotelsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.GetRecommendedHotelsReq.displayName = 'proto.hotel.web_partner.GetRecommendedHotelsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.GetRecommendedHotelsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web_partner.GetRecommendedHotelsRes.repeatedFields_, null);
};
goog.inherits(proto.hotel.web_partner.GetRecommendedHotelsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.GetRecommendedHotelsRes.displayName = 'proto.hotel.web_partner.GetRecommendedHotelsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.GetHotelReviewsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.GetHotelReviewsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.GetHotelReviewsReq.displayName = 'proto.hotel.web_partner.GetHotelReviewsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.GetHotelReviewsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web_partner.GetHotelReviewsRes.repeatedFields_, null);
};
goog.inherits(proto.hotel.web_partner.GetHotelReviewsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.GetHotelReviewsRes.displayName = 'proto.hotel.web_partner.GetHotelReviewsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.GetBookingDetailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.GetBookingDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.GetBookingDetailRequest.displayName = 'proto.hotel.web_partner.GetBookingDetailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.GetBookingDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.GetBookingDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.GetBookingDetailResponse.displayName = 'proto.hotel.web_partner.GetBookingDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.ListBookingsFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web_partner.ListBookingsFilter.repeatedFields_, null);
};
goog.inherits(proto.hotel.web_partner.ListBookingsFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.ListBookingsFilter.displayName = 'proto.hotel.web_partner.ListBookingsFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.ListBookingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.ListBookingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.ListBookingsRequest.displayName = 'proto.hotel.web_partner.ListBookingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.ListBookingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web_partner.ListBookingsResponse.repeatedFields_, null);
};
goog.inherits(proto.hotel.web_partner.ListBookingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.ListBookingsResponse.displayName = 'proto.hotel.web_partner.ListBookingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.ExportBookingsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.ExportBookingsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.ExportBookingsReq.displayName = 'proto.hotel.web_partner.ExportBookingsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.ExportBookingsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web_partner.ExportBookingsRes.repeatedFields_, null);
};
goog.inherits(proto.hotel.web_partner.ExportBookingsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.ExportBookingsRes.displayName = 'proto.hotel.web_partner.ExportBookingsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.ListBookingSelectionReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.ListBookingSelectionReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.ListBookingSelectionReq.displayName = 'proto.hotel.web_partner.ListBookingSelectionReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.ListBookingSelectionRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web_partner.ListBookingSelectionRes.repeatedFields_, null);
};
goog.inherits(proto.hotel.web_partner.ListBookingSelectionRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.ListBookingSelectionRes.displayName = 'proto.hotel.web_partner.ListBookingSelectionRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.AddBookingContactInfoReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web_partner.AddBookingContactInfoReq.repeatedFields_, null);
};
goog.inherits(proto.hotel.web_partner.AddBookingContactInfoReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.AddBookingContactInfoReq.displayName = 'proto.hotel.web_partner.AddBookingContactInfoReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.AddBookingContactInfoRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.AddBookingContactInfoRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.AddBookingContactInfoRes.displayName = 'proto.hotel.web_partner.AddBookingContactInfoRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.RetrieveBookingReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.RetrieveBookingReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.RetrieveBookingReq.displayName = 'proto.hotel.web_partner.RetrieveBookingReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.RetrieveBookingRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.RetrieveBookingRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.RetrieveBookingRes.displayName = 'proto.hotel.web_partner.RetrieveBookingRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.GetStaticBookingBySessionReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.GetStaticBookingBySessionReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.GetStaticBookingBySessionReq.displayName = 'proto.hotel.web_partner.GetStaticBookingBySessionReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.GetStaticBookingBySessionRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web_partner.GetStaticBookingBySessionRes.repeatedFields_, null);
};
goog.inherits(proto.hotel.web_partner.GetStaticBookingBySessionRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.GetStaticBookingBySessionRes.displayName = 'proto.hotel.web_partner.GetStaticBookingBySessionRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.ReserveBookingReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web_partner.ReserveBookingReq.repeatedFields_, null);
};
goog.inherits(proto.hotel.web_partner.ReserveBookingReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.ReserveBookingReq.displayName = 'proto.hotel.web_partner.ReserveBookingReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.ReserveBookingRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.ReserveBookingRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.ReserveBookingRes.displayName = 'proto.hotel.web_partner.ReserveBookingRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.ReserveBookingSelectionReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.ReserveBookingSelectionReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.ReserveBookingSelectionReq.displayName = 'proto.hotel.web_partner.ReserveBookingSelectionReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.ReserveBookingSelectionRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.ReserveBookingSelectionRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.ReserveBookingSelectionRes.displayName = 'proto.hotel.web_partner.ReserveBookingSelectionRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.HotelBookingSelectionReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web_partner.HotelBookingSelectionReq.repeatedFields_, null);
};
goog.inherits(proto.hotel.web_partner.HotelBookingSelectionReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.HotelBookingSelectionReq.displayName = 'proto.hotel.web_partner.HotelBookingSelectionReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.RoomBookingSelectionReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.RoomBookingSelectionReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.RoomBookingSelectionReq.displayName = 'proto.hotel.web_partner.RoomBookingSelectionReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.UpsertBookingSelectionReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web_partner.UpsertBookingSelectionReq.repeatedFields_, null);
};
goog.inherits(proto.hotel.web_partner.UpsertBookingSelectionReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.UpsertBookingSelectionReq.displayName = 'proto.hotel.web_partner.UpsertBookingSelectionReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.UpsertBookingSelectionRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.UpsertBookingSelectionRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.UpsertBookingSelectionRes.displayName = 'proto.hotel.web_partner.UpsertBookingSelectionRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.GetBookingSelectionReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.GetBookingSelectionReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.GetBookingSelectionReq.displayName = 'proto.hotel.web_partner.GetBookingSelectionReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.GetBookingSelectionRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.GetBookingSelectionRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.GetBookingSelectionRes.displayName = 'proto.hotel.web_partner.GetBookingSelectionRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.DeleteBookingSelectionReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.DeleteBookingSelectionReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.DeleteBookingSelectionReq.displayName = 'proto.hotel.web_partner.DeleteBookingSelectionReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.DeleteBookingSelectionRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.DeleteBookingSelectionRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.DeleteBookingSelectionRes.displayName = 'proto.hotel.web_partner.DeleteBookingSelectionRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.DeleteRateSelectionReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.DeleteRateSelectionReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.DeleteRateSelectionReq.displayName = 'proto.hotel.web_partner.DeleteRateSelectionReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.DeleteRateSelectionRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.DeleteRateSelectionRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.DeleteRateSelectionRes.displayName = 'proto.hotel.web_partner.DeleteRateSelectionRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.UpdatePriceAdjustment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.UpdatePriceAdjustment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.UpdatePriceAdjustment.displayName = 'proto.hotel.web_partner.UpdatePriceAdjustment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.UpdateBookingSelectionReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web_partner.UpdateBookingSelectionReq.repeatedFields_, null);
};
goog.inherits(proto.hotel.web_partner.UpdateBookingSelectionReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.UpdateBookingSelectionReq.displayName = 'proto.hotel.web_partner.UpdateBookingSelectionReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.UpdateBookingSelectionRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.UpdateBookingSelectionRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.UpdateBookingSelectionRes.displayName = 'proto.hotel.web_partner.UpdateBookingSelectionRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.CancelBookingReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.CancelBookingReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.CancelBookingReq.displayName = 'proto.hotel.web_partner.CancelBookingReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.CancelBookingRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.CancelBookingRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.CancelBookingRes.displayName = 'proto.hotel.web_partner.CancelBookingRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.OrderBookingReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.OrderBookingReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.OrderBookingReq.displayName = 'proto.hotel.web_partner.OrderBookingReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.OrderBookingRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.OrderBookingRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.OrderBookingRes.displayName = 'proto.hotel.web_partner.OrderBookingRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.PriceCheckReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.PriceCheckReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.PriceCheckReq.displayName = 'proto.hotel.web_partner.PriceCheckReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partner.PriceCheckRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partner.PriceCheckRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partner.PriceCheckRes.displayName = 'proto.hotel.web_partner.PriceCheckRes';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web_partner.SearchDestinationsReq.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.SearchDestinationsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.SearchDestinationsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.SearchDestinationsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.SearchDestinationsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    language: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f),
    orderByList: jspb.Message.toObjectList(msg.getOrderByList(),
    base_base_pb.OrderByItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.SearchDestinationsReq}
 */
proto.hotel.web_partner.SearchDestinationsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.SearchDestinationsReq;
  return proto.hotel.web_partner.SearchDestinationsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.SearchDestinationsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.SearchDestinationsReq}
 */
proto.hotel.web_partner.SearchDestinationsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 3:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = new base_base_pb.OrderByItem;
      reader.readMessage(value,base_base_pb.OrderByItem.deserializeBinaryFromReader);
      msg.addOrderBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.SearchDestinationsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.SearchDestinationsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.SearchDestinationsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.SearchDestinationsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
  f = message.getOrderByList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      base_base_pb.OrderByItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.hotel.web_partner.SearchDestinationsReq.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.SearchDestinationsReq} returns this
 */
proto.hotel.web_partner.SearchDestinationsReq.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string language = 2;
 * @return {string}
 */
proto.hotel.web_partner.SearchDestinationsReq.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.SearchDestinationsReq} returns this
 */
proto.hotel.web_partner.SearchDestinationsReq.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional base.PaginationReq pagination = 3;
 * @return {?proto.base.PaginationReq}
 */
proto.hotel.web_partner.SearchDestinationsReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 3));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.hotel.web_partner.SearchDestinationsReq} returns this
*/
proto.hotel.web_partner.SearchDestinationsReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partner.SearchDestinationsReq} returns this
 */
proto.hotel.web_partner.SearchDestinationsReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partner.SearchDestinationsReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated base.OrderByItem order_by = 4;
 * @return {!Array<!proto.base.OrderByItem>}
 */
proto.hotel.web_partner.SearchDestinationsReq.prototype.getOrderByList = function() {
  return /** @type{!Array<!proto.base.OrderByItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, base_base_pb.OrderByItem, 4));
};


/**
 * @param {!Array<!proto.base.OrderByItem>} value
 * @return {!proto.hotel.web_partner.SearchDestinationsReq} returns this
*/
proto.hotel.web_partner.SearchDestinationsReq.prototype.setOrderByList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.base.OrderByItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.base.OrderByItem}
 */
proto.hotel.web_partner.SearchDestinationsReq.prototype.addOrderBy = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.base.OrderByItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web_partner.SearchDestinationsReq} returns this
 */
proto.hotel.web_partner.SearchDestinationsReq.prototype.clearOrderByList = function() {
  return this.setOrderByList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web_partner.SearchDestinationsRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.SearchDestinationsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.SearchDestinationsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.SearchDestinationsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.SearchDestinationsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    destinationsList: jspb.Message.toObjectList(msg.getDestinationsList(),
    hotel_base_pb.Destination.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.SearchDestinationsRes}
 */
proto.hotel.web_partner.SearchDestinationsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.SearchDestinationsRes;
  return proto.hotel.web_partner.SearchDestinationsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.SearchDestinationsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.SearchDestinationsRes}
 */
proto.hotel.web_partner.SearchDestinationsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new hotel_base_pb.Destination;
      reader.readMessage(value,hotel_base_pb.Destination.deserializeBinaryFromReader);
      msg.addDestinations(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.SearchDestinationsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.SearchDestinationsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.SearchDestinationsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.SearchDestinationsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDestinationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      hotel_base_pb.Destination.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * repeated hotel.Destination destinations = 1;
 * @return {!Array<!proto.hotel.Destination>}
 */
proto.hotel.web_partner.SearchDestinationsRes.prototype.getDestinationsList = function() {
  return /** @type{!Array<!proto.hotel.Destination>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.Destination, 1));
};


/**
 * @param {!Array<!proto.hotel.Destination>} value
 * @return {!proto.hotel.web_partner.SearchDestinationsRes} returns this
*/
proto.hotel.web_partner.SearchDestinationsRes.prototype.setDestinationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.hotel.Destination=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.Destination}
 */
proto.hotel.web_partner.SearchDestinationsRes.prototype.addDestinations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.hotel.Destination, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web_partner.SearchDestinationsRes} returns this
 */
proto.hotel.web_partner.SearchDestinationsRes.prototype.clearDestinationsList = function() {
  return this.setDestinationsList([]);
};


/**
 * optional base.PaginationRes pagination = 2;
 * @return {?proto.base.PaginationRes}
 */
proto.hotel.web_partner.SearchDestinationsRes.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 2));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.hotel.web_partner.SearchDestinationsRes} returns this
*/
proto.hotel.web_partner.SearchDestinationsRes.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partner.SearchDestinationsRes} returns this
 */
proto.hotel.web_partner.SearchDestinationsRes.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partner.SearchDestinationsRes.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool is_success = 3;
 * @return {boolean}
 */
proto.hotel.web_partner.SearchDestinationsRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web_partner.SearchDestinationsRes} returns this
 */
proto.hotel.web_partner.SearchDestinationsRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string error_code = 4;
 * @return {string}
 */
proto.hotel.web_partner.SearchDestinationsRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.SearchDestinationsRes} returns this
 */
proto.hotel.web_partner.SearchDestinationsRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web_partner.SearchHotelReq.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.SearchHotelReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.SearchHotelReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.SearchHotelReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.SearchHotelReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && hotel_base_pb.HotelFilterRequest.toObject(includeInstance, f),
    destination: (f = msg.getDestination()) && hotel_base_pb.Destination.toObject(includeInstance, f),
    checkIn: jspb.Message.getFieldWithDefault(msg, 3, ""),
    checkOut: jspb.Message.getFieldWithDefault(msg, 4, ""),
    occupanciesList: jspb.Message.toObjectList(msg.getOccupanciesList(),
    hotel_base_pb.OccupancyRequest.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f),
    sorts: (f = msg.getSorts()) && hotel_base_pb.SortItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.SearchHotelReq}
 */
proto.hotel.web_partner.SearchHotelReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.SearchHotelReq;
  return proto.hotel.web_partner.SearchHotelReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.SearchHotelReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.SearchHotelReq}
 */
proto.hotel.web_partner.SearchHotelReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new hotel_base_pb.HotelFilterRequest;
      reader.readMessage(value,hotel_base_pb.HotelFilterRequest.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new hotel_base_pb.Destination;
      reader.readMessage(value,hotel_base_pb.Destination.deserializeBinaryFromReader);
      msg.setDestination(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckIn(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckOut(value);
      break;
    case 5:
      var value = new hotel_base_pb.OccupancyRequest;
      reader.readMessage(value,hotel_base_pb.OccupancyRequest.deserializeBinaryFromReader);
      msg.addOccupancies(value);
      break;
    case 6:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 7:
      var value = new hotel_base_pb.SortItem;
      reader.readMessage(value,hotel_base_pb.SortItem.deserializeBinaryFromReader);
      msg.setSorts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.SearchHotelReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.SearchHotelReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.SearchHotelReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.SearchHotelReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      hotel_base_pb.HotelFilterRequest.serializeBinaryToWriter
    );
  }
  f = message.getDestination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hotel_base_pb.Destination.serializeBinaryToWriter
    );
  }
  f = message.getCheckIn();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCheckOut();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOccupanciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      hotel_base_pb.OccupancyRequest.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
  f = message.getSorts();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      hotel_base_pb.SortItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional hotel.HotelFilterRequest filter = 1;
 * @return {?proto.hotel.HotelFilterRequest}
 */
proto.hotel.web_partner.SearchHotelReq.prototype.getFilter = function() {
  return /** @type{?proto.hotel.HotelFilterRequest} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.HotelFilterRequest, 1));
};


/**
 * @param {?proto.hotel.HotelFilterRequest|undefined} value
 * @return {!proto.hotel.web_partner.SearchHotelReq} returns this
*/
proto.hotel.web_partner.SearchHotelReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partner.SearchHotelReq} returns this
 */
proto.hotel.web_partner.SearchHotelReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partner.SearchHotelReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional hotel.Destination destination = 2;
 * @return {?proto.hotel.Destination}
 */
proto.hotel.web_partner.SearchHotelReq.prototype.getDestination = function() {
  return /** @type{?proto.hotel.Destination} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.Destination, 2));
};


/**
 * @param {?proto.hotel.Destination|undefined} value
 * @return {!proto.hotel.web_partner.SearchHotelReq} returns this
*/
proto.hotel.web_partner.SearchHotelReq.prototype.setDestination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partner.SearchHotelReq} returns this
 */
proto.hotel.web_partner.SearchHotelReq.prototype.clearDestination = function() {
  return this.setDestination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partner.SearchHotelReq.prototype.hasDestination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string check_in = 3;
 * @return {string}
 */
proto.hotel.web_partner.SearchHotelReq.prototype.getCheckIn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.SearchHotelReq} returns this
 */
proto.hotel.web_partner.SearchHotelReq.prototype.setCheckIn = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string check_out = 4;
 * @return {string}
 */
proto.hotel.web_partner.SearchHotelReq.prototype.getCheckOut = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.SearchHotelReq} returns this
 */
proto.hotel.web_partner.SearchHotelReq.prototype.setCheckOut = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated hotel.OccupancyRequest occupancies = 5;
 * @return {!Array<!proto.hotel.OccupancyRequest>}
 */
proto.hotel.web_partner.SearchHotelReq.prototype.getOccupanciesList = function() {
  return /** @type{!Array<!proto.hotel.OccupancyRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.OccupancyRequest, 5));
};


/**
 * @param {!Array<!proto.hotel.OccupancyRequest>} value
 * @return {!proto.hotel.web_partner.SearchHotelReq} returns this
*/
proto.hotel.web_partner.SearchHotelReq.prototype.setOccupanciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.hotel.OccupancyRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.OccupancyRequest}
 */
proto.hotel.web_partner.SearchHotelReq.prototype.addOccupancies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.hotel.OccupancyRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web_partner.SearchHotelReq} returns this
 */
proto.hotel.web_partner.SearchHotelReq.prototype.clearOccupanciesList = function() {
  return this.setOccupanciesList([]);
};


/**
 * optional base.PaginationReq pagination = 6;
 * @return {?proto.base.PaginationReq}
 */
proto.hotel.web_partner.SearchHotelReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 6));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.hotel.web_partner.SearchHotelReq} returns this
*/
proto.hotel.web_partner.SearchHotelReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partner.SearchHotelReq} returns this
 */
proto.hotel.web_partner.SearchHotelReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partner.SearchHotelReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional hotel.SortItem sorts = 7;
 * @return {?proto.hotel.SortItem}
 */
proto.hotel.web_partner.SearchHotelReq.prototype.getSorts = function() {
  return /** @type{?proto.hotel.SortItem} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.SortItem, 7));
};


/**
 * @param {?proto.hotel.SortItem|undefined} value
 * @return {!proto.hotel.web_partner.SearchHotelReq} returns this
*/
proto.hotel.web_partner.SearchHotelReq.prototype.setSorts = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partner.SearchHotelReq} returns this
 */
proto.hotel.web_partner.SearchHotelReq.prototype.clearSorts = function() {
  return this.setSorts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partner.SearchHotelReq.prototype.hasSorts = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web_partner.SearchHotelRes.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.SearchHotelRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.SearchHotelRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.SearchHotelRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.SearchHotelRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hotelList: jspb.Message.toObjectList(msg.getHotelList(),
    hotel_base_pb.HotelSummary.toObject, includeInstance),
    filter: (f = msg.getFilter()) && hotel_base_pb.FilterOptions.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.SearchHotelRes}
 */
proto.hotel.web_partner.SearchHotelRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.SearchHotelRes;
  return proto.hotel.web_partner.SearchHotelRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.SearchHotelRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.SearchHotelRes}
 */
proto.hotel.web_partner.SearchHotelRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = new hotel_base_pb.HotelSummary;
      reader.readMessage(value,hotel_base_pb.HotelSummary.deserializeBinaryFromReader);
      msg.addHotel(value);
      break;
    case 3:
      var value = new hotel_base_pb.FilterOptions;
      reader.readMessage(value,hotel_base_pb.FilterOptions.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 4:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.SearchHotelRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.SearchHotelRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.SearchHotelRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.SearchHotelRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHotelList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      hotel_base_pb.HotelSummary.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      hotel_base_pb.FilterOptions.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.hotel.web_partner.SearchHotelRes.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.SearchHotelRes} returns this
 */
proto.hotel.web_partner.SearchHotelRes.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated hotel.HotelSummary hotel = 2;
 * @return {!Array<!proto.hotel.HotelSummary>}
 */
proto.hotel.web_partner.SearchHotelRes.prototype.getHotelList = function() {
  return /** @type{!Array<!proto.hotel.HotelSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.HotelSummary, 2));
};


/**
 * @param {!Array<!proto.hotel.HotelSummary>} value
 * @return {!proto.hotel.web_partner.SearchHotelRes} returns this
*/
proto.hotel.web_partner.SearchHotelRes.prototype.setHotelList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.hotel.HotelSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.HotelSummary}
 */
proto.hotel.web_partner.SearchHotelRes.prototype.addHotel = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.hotel.HotelSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web_partner.SearchHotelRes} returns this
 */
proto.hotel.web_partner.SearchHotelRes.prototype.clearHotelList = function() {
  return this.setHotelList([]);
};


/**
 * optional hotel.FilterOptions filter = 3;
 * @return {?proto.hotel.FilterOptions}
 */
proto.hotel.web_partner.SearchHotelRes.prototype.getFilter = function() {
  return /** @type{?proto.hotel.FilterOptions} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.FilterOptions, 3));
};


/**
 * @param {?proto.hotel.FilterOptions|undefined} value
 * @return {!proto.hotel.web_partner.SearchHotelRes} returns this
*/
proto.hotel.web_partner.SearchHotelRes.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partner.SearchHotelRes} returns this
 */
proto.hotel.web_partner.SearchHotelRes.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partner.SearchHotelRes.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional base.PaginationRes pagination = 4;
 * @return {?proto.base.PaginationRes}
 */
proto.hotel.web_partner.SearchHotelRes.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 4));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.hotel.web_partner.SearchHotelRes} returns this
*/
proto.hotel.web_partner.SearchHotelRes.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partner.SearchHotelRes} returns this
 */
proto.hotel.web_partner.SearchHotelRes.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partner.SearchHotelRes.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool is_success = 5;
 * @return {boolean}
 */
proto.hotel.web_partner.SearchHotelRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web_partner.SearchHotelRes} returns this
 */
proto.hotel.web_partner.SearchHotelRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string error_code = 6;
 * @return {string}
 */
proto.hotel.web_partner.SearchHotelRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.SearchHotelRes} returns this
 */
proto.hotel.web_partner.SearchHotelRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.GetHotelInfoReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.GetHotelInfoReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.GetHotelInfoReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.GetHotelInfoReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    hotelId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.GetHotelInfoReq}
 */
proto.hotel.web_partner.GetHotelInfoReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.GetHotelInfoReq;
  return proto.hotel.web_partner.GetHotelInfoReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.GetHotelInfoReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.GetHotelInfoReq}
 */
proto.hotel.web_partner.GetHotelInfoReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.GetHotelInfoReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.GetHotelInfoReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.GetHotelInfoReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.GetHotelInfoReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHotelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string hotel_id = 1;
 * @return {string}
 */
proto.hotel.web_partner.GetHotelInfoReq.prototype.getHotelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.GetHotelInfoReq} returns this
 */
proto.hotel.web_partner.GetHotelInfoReq.prototype.setHotelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.GetHotelInfoRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.GetHotelInfoRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.GetHotelInfoRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.GetHotelInfoRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    hotel: (f = msg.getHotel()) && hotel_hotel_pb.HotelDetail.toObject(includeInstance, f),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.GetHotelInfoRes}
 */
proto.hotel.web_partner.GetHotelInfoRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.GetHotelInfoRes;
  return proto.hotel.web_partner.GetHotelInfoRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.GetHotelInfoRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.GetHotelInfoRes}
 */
proto.hotel.web_partner.GetHotelInfoRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new hotel_hotel_pb.HotelDetail;
      reader.readMessage(value,hotel_hotel_pb.HotelDetail.deserializeBinaryFromReader);
      msg.setHotel(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.GetHotelInfoRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.GetHotelInfoRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.GetHotelInfoRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.GetHotelInfoRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHotel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      hotel_hotel_pb.HotelDetail.serializeBinaryToWriter
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional hotel.HotelDetail hotel = 1;
 * @return {?proto.hotel.HotelDetail}
 */
proto.hotel.web_partner.GetHotelInfoRes.prototype.getHotel = function() {
  return /** @type{?proto.hotel.HotelDetail} */ (
    jspb.Message.getWrapperField(this, hotel_hotel_pb.HotelDetail, 1));
};


/**
 * @param {?proto.hotel.HotelDetail|undefined} value
 * @return {!proto.hotel.web_partner.GetHotelInfoRes} returns this
*/
proto.hotel.web_partner.GetHotelInfoRes.prototype.setHotel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partner.GetHotelInfoRes} returns this
 */
proto.hotel.web_partner.GetHotelInfoRes.prototype.clearHotel = function() {
  return this.setHotel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partner.GetHotelInfoRes.prototype.hasHotel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool is_success = 2;
 * @return {boolean}
 */
proto.hotel.web_partner.GetHotelInfoRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web_partner.GetHotelInfoRes} returns this
 */
proto.hotel.web_partner.GetHotelInfoRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string error_code = 3;
 * @return {string}
 */
proto.hotel.web_partner.GetHotelInfoRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.GetHotelInfoRes} returns this
 */
proto.hotel.web_partner.GetHotelInfoRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web_partner.GetHotelRoomDetailsReq.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.GetHotelRoomDetailsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.GetHotelRoomDetailsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.GetHotelRoomDetailsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.GetHotelRoomDetailsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    hotelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    checkIn: jspb.Message.getFieldWithDefault(msg, 2, ""),
    checkOut: jspb.Message.getFieldWithDefault(msg, 3, ""),
    occupanciesList: jspb.Message.toObjectList(msg.getOccupanciesList(),
    hotel_base_pb.OccupancyRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.GetHotelRoomDetailsReq}
 */
proto.hotel.web_partner.GetHotelRoomDetailsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.GetHotelRoomDetailsReq;
  return proto.hotel.web_partner.GetHotelRoomDetailsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.GetHotelRoomDetailsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.GetHotelRoomDetailsReq}
 */
proto.hotel.web_partner.GetHotelRoomDetailsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckIn(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckOut(value);
      break;
    case 4:
      var value = new hotel_base_pb.OccupancyRequest;
      reader.readMessage(value,hotel_base_pb.OccupancyRequest.deserializeBinaryFromReader);
      msg.addOccupancies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.GetHotelRoomDetailsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.GetHotelRoomDetailsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.GetHotelRoomDetailsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.GetHotelRoomDetailsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHotelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCheckIn();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCheckOut();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOccupanciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      hotel_base_pb.OccupancyRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string hotel_id = 1;
 * @return {string}
 */
proto.hotel.web_partner.GetHotelRoomDetailsReq.prototype.getHotelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.GetHotelRoomDetailsReq} returns this
 */
proto.hotel.web_partner.GetHotelRoomDetailsReq.prototype.setHotelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string check_in = 2;
 * @return {string}
 */
proto.hotel.web_partner.GetHotelRoomDetailsReq.prototype.getCheckIn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.GetHotelRoomDetailsReq} returns this
 */
proto.hotel.web_partner.GetHotelRoomDetailsReq.prototype.setCheckIn = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string check_out = 3;
 * @return {string}
 */
proto.hotel.web_partner.GetHotelRoomDetailsReq.prototype.getCheckOut = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.GetHotelRoomDetailsReq} returns this
 */
proto.hotel.web_partner.GetHotelRoomDetailsReq.prototype.setCheckOut = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated hotel.OccupancyRequest occupancies = 4;
 * @return {!Array<!proto.hotel.OccupancyRequest>}
 */
proto.hotel.web_partner.GetHotelRoomDetailsReq.prototype.getOccupanciesList = function() {
  return /** @type{!Array<!proto.hotel.OccupancyRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.OccupancyRequest, 4));
};


/**
 * @param {!Array<!proto.hotel.OccupancyRequest>} value
 * @return {!proto.hotel.web_partner.GetHotelRoomDetailsReq} returns this
*/
proto.hotel.web_partner.GetHotelRoomDetailsReq.prototype.setOccupanciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.hotel.OccupancyRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.OccupancyRequest}
 */
proto.hotel.web_partner.GetHotelRoomDetailsReq.prototype.addOccupancies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.hotel.OccupancyRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web_partner.GetHotelRoomDetailsReq} returns this
 */
proto.hotel.web_partner.GetHotelRoomDetailsReq.prototype.clearOccupanciesList = function() {
  return this.setOccupanciesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web_partner.GetHotelRoomDetailsRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.GetHotelRoomDetailsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.GetHotelRoomDetailsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.GetHotelRoomDetailsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.GetHotelRoomDetailsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomDetailsList: jspb.Message.toObjectList(msg.getRoomDetailsList(),
    hotel_hotel_pb.RoomRate.toObject, includeInstance),
    searchKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.GetHotelRoomDetailsRes}
 */
proto.hotel.web_partner.GetHotelRoomDetailsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.GetHotelRoomDetailsRes;
  return proto.hotel.web_partner.GetHotelRoomDetailsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.GetHotelRoomDetailsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.GetHotelRoomDetailsRes}
 */
proto.hotel.web_partner.GetHotelRoomDetailsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new hotel_hotel_pb.RoomRate;
      reader.readMessage(value,hotel_hotel_pb.RoomRate.deserializeBinaryFromReader);
      msg.addRoomDetails(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchKey(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.GetHotelRoomDetailsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.GetHotelRoomDetailsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.GetHotelRoomDetailsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.GetHotelRoomDetailsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      hotel_hotel_pb.RoomRate.serializeBinaryToWriter
    );
  }
  f = message.getSearchKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * repeated hotel.RoomRate room_details = 1;
 * @return {!Array<!proto.hotel.RoomRate>}
 */
proto.hotel.web_partner.GetHotelRoomDetailsRes.prototype.getRoomDetailsList = function() {
  return /** @type{!Array<!proto.hotel.RoomRate>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_hotel_pb.RoomRate, 1));
};


/**
 * @param {!Array<!proto.hotel.RoomRate>} value
 * @return {!proto.hotel.web_partner.GetHotelRoomDetailsRes} returns this
*/
proto.hotel.web_partner.GetHotelRoomDetailsRes.prototype.setRoomDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.hotel.RoomRate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.RoomRate}
 */
proto.hotel.web_partner.GetHotelRoomDetailsRes.prototype.addRoomDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.hotel.RoomRate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web_partner.GetHotelRoomDetailsRes} returns this
 */
proto.hotel.web_partner.GetHotelRoomDetailsRes.prototype.clearRoomDetailsList = function() {
  return this.setRoomDetailsList([]);
};


/**
 * optional string search_key = 2;
 * @return {string}
 */
proto.hotel.web_partner.GetHotelRoomDetailsRes.prototype.getSearchKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.GetHotelRoomDetailsRes} returns this
 */
proto.hotel.web_partner.GetHotelRoomDetailsRes.prototype.setSearchKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_success = 3;
 * @return {boolean}
 */
proto.hotel.web_partner.GetHotelRoomDetailsRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web_partner.GetHotelRoomDetailsRes} returns this
 */
proto.hotel.web_partner.GetHotelRoomDetailsRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string error_code = 4;
 * @return {string}
 */
proto.hotel.web_partner.GetHotelRoomDetailsRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.GetHotelRoomDetailsRes} returns this
 */
proto.hotel.web_partner.GetHotelRoomDetailsRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web_partner.GetRecommendedHotelsReq.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.GetRecommendedHotelsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.GetRecommendedHotelsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.GetRecommendedHotelsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.GetRecommendedHotelsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    hotelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    checkIn: jspb.Message.getFieldWithDefault(msg, 2, ""),
    checkOut: jspb.Message.getFieldWithDefault(msg, 3, ""),
    occupanciesList: jspb.Message.toObjectList(msg.getOccupanciesList(),
    hotel_base_pb.OccupancyRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.GetRecommendedHotelsReq}
 */
proto.hotel.web_partner.GetRecommendedHotelsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.GetRecommendedHotelsReq;
  return proto.hotel.web_partner.GetRecommendedHotelsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.GetRecommendedHotelsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.GetRecommendedHotelsReq}
 */
proto.hotel.web_partner.GetRecommendedHotelsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckIn(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckOut(value);
      break;
    case 4:
      var value = new hotel_base_pb.OccupancyRequest;
      reader.readMessage(value,hotel_base_pb.OccupancyRequest.deserializeBinaryFromReader);
      msg.addOccupancies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.GetRecommendedHotelsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.GetRecommendedHotelsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.GetRecommendedHotelsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.GetRecommendedHotelsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHotelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCheckIn();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCheckOut();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOccupanciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      hotel_base_pb.OccupancyRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string hotel_id = 1;
 * @return {string}
 */
proto.hotel.web_partner.GetRecommendedHotelsReq.prototype.getHotelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.GetRecommendedHotelsReq} returns this
 */
proto.hotel.web_partner.GetRecommendedHotelsReq.prototype.setHotelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string check_in = 2;
 * @return {string}
 */
proto.hotel.web_partner.GetRecommendedHotelsReq.prototype.getCheckIn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.GetRecommendedHotelsReq} returns this
 */
proto.hotel.web_partner.GetRecommendedHotelsReq.prototype.setCheckIn = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string check_out = 3;
 * @return {string}
 */
proto.hotel.web_partner.GetRecommendedHotelsReq.prototype.getCheckOut = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.GetRecommendedHotelsReq} returns this
 */
proto.hotel.web_partner.GetRecommendedHotelsReq.prototype.setCheckOut = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated hotel.OccupancyRequest occupancies = 4;
 * @return {!Array<!proto.hotel.OccupancyRequest>}
 */
proto.hotel.web_partner.GetRecommendedHotelsReq.prototype.getOccupanciesList = function() {
  return /** @type{!Array<!proto.hotel.OccupancyRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.OccupancyRequest, 4));
};


/**
 * @param {!Array<!proto.hotel.OccupancyRequest>} value
 * @return {!proto.hotel.web_partner.GetRecommendedHotelsReq} returns this
*/
proto.hotel.web_partner.GetRecommendedHotelsReq.prototype.setOccupanciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.hotel.OccupancyRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.OccupancyRequest}
 */
proto.hotel.web_partner.GetRecommendedHotelsReq.prototype.addOccupancies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.hotel.OccupancyRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web_partner.GetRecommendedHotelsReq} returns this
 */
proto.hotel.web_partner.GetRecommendedHotelsReq.prototype.clearOccupanciesList = function() {
  return this.setOccupanciesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web_partner.GetRecommendedHotelsRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.GetRecommendedHotelsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.GetRecommendedHotelsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.GetRecommendedHotelsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.GetRecommendedHotelsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    hotelsList: jspb.Message.toObjectList(msg.getHotelsList(),
    hotel_base_pb.HotelSummary.toObject, includeInstance),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.GetRecommendedHotelsRes}
 */
proto.hotel.web_partner.GetRecommendedHotelsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.GetRecommendedHotelsRes;
  return proto.hotel.web_partner.GetRecommendedHotelsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.GetRecommendedHotelsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.GetRecommendedHotelsRes}
 */
proto.hotel.web_partner.GetRecommendedHotelsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new hotel_base_pb.HotelSummary;
      reader.readMessage(value,hotel_base_pb.HotelSummary.deserializeBinaryFromReader);
      msg.addHotels(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.GetRecommendedHotelsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.GetRecommendedHotelsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.GetRecommendedHotelsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.GetRecommendedHotelsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHotelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      hotel_base_pb.HotelSummary.serializeBinaryToWriter
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated hotel.HotelSummary hotels = 1;
 * @return {!Array<!proto.hotel.HotelSummary>}
 */
proto.hotel.web_partner.GetRecommendedHotelsRes.prototype.getHotelsList = function() {
  return /** @type{!Array<!proto.hotel.HotelSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.HotelSummary, 1));
};


/**
 * @param {!Array<!proto.hotel.HotelSummary>} value
 * @return {!proto.hotel.web_partner.GetRecommendedHotelsRes} returns this
*/
proto.hotel.web_partner.GetRecommendedHotelsRes.prototype.setHotelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.hotel.HotelSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.HotelSummary}
 */
proto.hotel.web_partner.GetRecommendedHotelsRes.prototype.addHotels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.hotel.HotelSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web_partner.GetRecommendedHotelsRes} returns this
 */
proto.hotel.web_partner.GetRecommendedHotelsRes.prototype.clearHotelsList = function() {
  return this.setHotelsList([]);
};


/**
 * optional bool is_success = 2;
 * @return {boolean}
 */
proto.hotel.web_partner.GetRecommendedHotelsRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web_partner.GetRecommendedHotelsRes} returns this
 */
proto.hotel.web_partner.GetRecommendedHotelsRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string error_code = 3;
 * @return {string}
 */
proto.hotel.web_partner.GetRecommendedHotelsRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.GetRecommendedHotelsRes} returns this
 */
proto.hotel.web_partner.GetRecommendedHotelsRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.GetHotelReviewsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.GetHotelReviewsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.GetHotelReviewsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.GetHotelReviewsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    hotelId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.GetHotelReviewsReq}
 */
proto.hotel.web_partner.GetHotelReviewsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.GetHotelReviewsReq;
  return proto.hotel.web_partner.GetHotelReviewsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.GetHotelReviewsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.GetHotelReviewsReq}
 */
proto.hotel.web_partner.GetHotelReviewsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.GetHotelReviewsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.GetHotelReviewsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.GetHotelReviewsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.GetHotelReviewsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHotelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string hotel_id = 1;
 * @return {string}
 */
proto.hotel.web_partner.GetHotelReviewsReq.prototype.getHotelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.GetHotelReviewsReq} returns this
 */
proto.hotel.web_partner.GetHotelReviewsReq.prototype.setHotelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web_partner.GetHotelReviewsRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.GetHotelReviewsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.GetHotelReviewsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.GetHotelReviewsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.GetHotelReviewsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewsList: jspb.Message.toObjectList(msg.getReviewsList(),
    hotel_base_pb.HotelReview.toObject, includeInstance),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.GetHotelReviewsRes}
 */
proto.hotel.web_partner.GetHotelReviewsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.GetHotelReviewsRes;
  return proto.hotel.web_partner.GetHotelReviewsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.GetHotelReviewsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.GetHotelReviewsRes}
 */
proto.hotel.web_partner.GetHotelReviewsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new hotel_base_pb.HotelReview;
      reader.readMessage(value,hotel_base_pb.HotelReview.deserializeBinaryFromReader);
      msg.addReviews(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.GetHotelReviewsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.GetHotelReviewsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.GetHotelReviewsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.GetHotelReviewsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReviewsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      hotel_base_pb.HotelReview.serializeBinaryToWriter
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated hotel.HotelReview reviews = 1;
 * @return {!Array<!proto.hotel.HotelReview>}
 */
proto.hotel.web_partner.GetHotelReviewsRes.prototype.getReviewsList = function() {
  return /** @type{!Array<!proto.hotel.HotelReview>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.HotelReview, 1));
};


/**
 * @param {!Array<!proto.hotel.HotelReview>} value
 * @return {!proto.hotel.web_partner.GetHotelReviewsRes} returns this
*/
proto.hotel.web_partner.GetHotelReviewsRes.prototype.setReviewsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.hotel.HotelReview=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.HotelReview}
 */
proto.hotel.web_partner.GetHotelReviewsRes.prototype.addReviews = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.hotel.HotelReview, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web_partner.GetHotelReviewsRes} returns this
 */
proto.hotel.web_partner.GetHotelReviewsRes.prototype.clearReviewsList = function() {
  return this.setReviewsList([]);
};


/**
 * optional bool is_success = 2;
 * @return {boolean}
 */
proto.hotel.web_partner.GetHotelReviewsRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web_partner.GetHotelReviewsRes} returns this
 */
proto.hotel.web_partner.GetHotelReviewsRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string error_code = 3;
 * @return {string}
 */
proto.hotel.web_partner.GetHotelReviewsRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.GetHotelReviewsRes} returns this
 */
proto.hotel.web_partner.GetHotelReviewsRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.GetBookingDetailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.GetBookingDetailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.GetBookingDetailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.GetBookingDetailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.GetBookingDetailRequest}
 */
proto.hotel.web_partner.GetBookingDetailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.GetBookingDetailRequest;
  return proto.hotel.web_partner.GetBookingDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.GetBookingDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.GetBookingDetailRequest}
 */
proto.hotel.web_partner.GetBookingDetailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.GetBookingDetailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.GetBookingDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.GetBookingDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.GetBookingDetailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string order_code = 1;
 * @return {string}
 */
proto.hotel.web_partner.GetBookingDetailRequest.prototype.getOrderCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.GetBookingDetailRequest} returns this
 */
proto.hotel.web_partner.GetBookingDetailRequest.prototype.setOrderCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.GetBookingDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.GetBookingDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.GetBookingDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.GetBookingDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && hotel_booking_pb.BookingDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.GetBookingDetailResponse}
 */
proto.hotel.web_partner.GetBookingDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.GetBookingDetailResponse;
  return proto.hotel.web_partner.GetBookingDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.GetBookingDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.GetBookingDetailResponse}
 */
proto.hotel.web_partner.GetBookingDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new hotel_booking_pb.BookingDetail;
      reader.readMessage(value,hotel_booking_pb.BookingDetail.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.GetBookingDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.GetBookingDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.GetBookingDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.GetBookingDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      hotel_booking_pb.BookingDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.hotel.web_partner.GetBookingDetailResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web_partner.GetBookingDetailResponse} returns this
 */
proto.hotel.web_partner.GetBookingDetailResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.hotel.web_partner.GetBookingDetailResponse.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.GetBookingDetailResponse} returns this
 */
proto.hotel.web_partner.GetBookingDetailResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional hotel.BookingDetail data = 3;
 * @return {?proto.hotel.BookingDetail}
 */
proto.hotel.web_partner.GetBookingDetailResponse.prototype.getData = function() {
  return /** @type{?proto.hotel.BookingDetail} */ (
    jspb.Message.getWrapperField(this, hotel_booking_pb.BookingDetail, 3));
};


/**
 * @param {?proto.hotel.BookingDetail|undefined} value
 * @return {!proto.hotel.web_partner.GetBookingDetailResponse} returns this
*/
proto.hotel.web_partner.GetBookingDetailResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partner.GetBookingDetailResponse} returns this
 */
proto.hotel.web_partner.GetBookingDetailResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partner.GetBookingDetailResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web_partner.ListBookingsFilter.repeatedFields_ = [1,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.ListBookingsFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.ListBookingsFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.ListBookingsFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.ListBookingsFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    orderCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    from: jspb.Message.getFieldWithDefault(msg, 3, 0),
    to: jspb.Message.getFieldWithDefault(msg, 4, 0),
    notInStatusesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.ListBookingsFilter}
 */
proto.hotel.web_partner.ListBookingsFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.ListBookingsFilter;
  return proto.hotel.web_partner.ListBookingsFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.ListBookingsFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.ListBookingsFilter}
 */
proto.hotel.web_partner.ListBookingsFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.hotel.BookingStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatus(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderCode(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFrom(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTo(value);
      break;
    case 5:
      var values = /** @type {!Array<!proto.hotel.BookingStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNotInStatuses(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.ListBookingsFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.ListBookingsFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.ListBookingsFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.ListBookingsFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
  f = message.getOrderCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFrom();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTo();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getNotInStatusesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
};


/**
 * repeated hotel.BookingStatus status = 1;
 * @return {!Array<!proto.hotel.BookingStatus>}
 */
proto.hotel.web_partner.ListBookingsFilter.prototype.getStatusList = function() {
  return /** @type {!Array<!proto.hotel.BookingStatus>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.hotel.BookingStatus>} value
 * @return {!proto.hotel.web_partner.ListBookingsFilter} returns this
 */
proto.hotel.web_partner.ListBookingsFilter.prototype.setStatusList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.hotel.BookingStatus} value
 * @param {number=} opt_index
 * @return {!proto.hotel.web_partner.ListBookingsFilter} returns this
 */
proto.hotel.web_partner.ListBookingsFilter.prototype.addStatus = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web_partner.ListBookingsFilter} returns this
 */
proto.hotel.web_partner.ListBookingsFilter.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};


/**
 * optional string order_code = 2;
 * @return {string}
 */
proto.hotel.web_partner.ListBookingsFilter.prototype.getOrderCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.ListBookingsFilter} returns this
 */
proto.hotel.web_partner.ListBookingsFilter.prototype.setOrderCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 from = 3;
 * @return {number}
 */
proto.hotel.web_partner.ListBookingsFilter.prototype.getFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.web_partner.ListBookingsFilter} returns this
 */
proto.hotel.web_partner.ListBookingsFilter.prototype.setFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 to = 4;
 * @return {number}
 */
proto.hotel.web_partner.ListBookingsFilter.prototype.getTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.web_partner.ListBookingsFilter} returns this
 */
proto.hotel.web_partner.ListBookingsFilter.prototype.setTo = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated hotel.BookingStatus not_in_statuses = 5;
 * @return {!Array<!proto.hotel.BookingStatus>}
 */
proto.hotel.web_partner.ListBookingsFilter.prototype.getNotInStatusesList = function() {
  return /** @type {!Array<!proto.hotel.BookingStatus>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.hotel.BookingStatus>} value
 * @return {!proto.hotel.web_partner.ListBookingsFilter} returns this
 */
proto.hotel.web_partner.ListBookingsFilter.prototype.setNotInStatusesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.hotel.BookingStatus} value
 * @param {number=} opt_index
 * @return {!proto.hotel.web_partner.ListBookingsFilter} returns this
 */
proto.hotel.web_partner.ListBookingsFilter.prototype.addNotInStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web_partner.ListBookingsFilter} returns this
 */
proto.hotel.web_partner.ListBookingsFilter.prototype.clearNotInStatusesList = function() {
  return this.setNotInStatusesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.ListBookingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.ListBookingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.ListBookingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.ListBookingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f),
    filter: (f = msg.getFilter()) && proto.hotel.web_partner.ListBookingsFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.ListBookingsRequest}
 */
proto.hotel.web_partner.ListBookingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.ListBookingsRequest;
  return proto.hotel.web_partner.ListBookingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.ListBookingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.ListBookingsRequest}
 */
proto.hotel.web_partner.ListBookingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = new proto.hotel.web_partner.ListBookingsFilter;
      reader.readMessage(value,proto.hotel.web_partner.ListBookingsFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.ListBookingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.ListBookingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.ListBookingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.ListBookingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.hotel.web_partner.ListBookingsFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional base.PaginationReq pagination = 1;
 * @return {?proto.base.PaginationReq}
 */
proto.hotel.web_partner.ListBookingsRequest.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 1));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.hotel.web_partner.ListBookingsRequest} returns this
*/
proto.hotel.web_partner.ListBookingsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partner.ListBookingsRequest} returns this
 */
proto.hotel.web_partner.ListBookingsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partner.ListBookingsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ListBookingsFilter filter = 2;
 * @return {?proto.hotel.web_partner.ListBookingsFilter}
 */
proto.hotel.web_partner.ListBookingsRequest.prototype.getFilter = function() {
  return /** @type{?proto.hotel.web_partner.ListBookingsFilter} */ (
    jspb.Message.getWrapperField(this, proto.hotel.web_partner.ListBookingsFilter, 2));
};


/**
 * @param {?proto.hotel.web_partner.ListBookingsFilter|undefined} value
 * @return {!proto.hotel.web_partner.ListBookingsRequest} returns this
*/
proto.hotel.web_partner.ListBookingsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partner.ListBookingsRequest} returns this
 */
proto.hotel.web_partner.ListBookingsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partner.ListBookingsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web_partner.ListBookingsResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.ListBookingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.ListBookingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.ListBookingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.ListBookingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    hotel_booking_pb.BookingDetail.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.ListBookingsResponse}
 */
proto.hotel.web_partner.ListBookingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.ListBookingsResponse;
  return proto.hotel.web_partner.ListBookingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.ListBookingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.ListBookingsResponse}
 */
proto.hotel.web_partner.ListBookingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new hotel_booking_pb.BookingDetail;
      reader.readMessage(value,hotel_booking_pb.BookingDetail.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 4:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.ListBookingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.ListBookingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.ListBookingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.ListBookingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      hotel_booking_pb.BookingDetail.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.hotel.web_partner.ListBookingsResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web_partner.ListBookingsResponse} returns this
 */
proto.hotel.web_partner.ListBookingsResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.hotel.web_partner.ListBookingsResponse.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.ListBookingsResponse} returns this
 */
proto.hotel.web_partner.ListBookingsResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated hotel.BookingDetail items = 3;
 * @return {!Array<!proto.hotel.BookingDetail>}
 */
proto.hotel.web_partner.ListBookingsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.hotel.BookingDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_booking_pb.BookingDetail, 3));
};


/**
 * @param {!Array<!proto.hotel.BookingDetail>} value
 * @return {!proto.hotel.web_partner.ListBookingsResponse} returns this
*/
proto.hotel.web_partner.ListBookingsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.hotel.BookingDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.BookingDetail}
 */
proto.hotel.web_partner.ListBookingsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.hotel.BookingDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web_partner.ListBookingsResponse} returns this
 */
proto.hotel.web_partner.ListBookingsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 4;
 * @return {?proto.base.PaginationRes}
 */
proto.hotel.web_partner.ListBookingsResponse.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 4));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.hotel.web_partner.ListBookingsResponse} returns this
*/
proto.hotel.web_partner.ListBookingsResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partner.ListBookingsResponse} returns this
 */
proto.hotel.web_partner.ListBookingsResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partner.ListBookingsResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.ExportBookingsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.ExportBookingsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.ExportBookingsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.ExportBookingsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.hotel.web_partner.ListBookingsFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.ExportBookingsReq}
 */
proto.hotel.web_partner.ExportBookingsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.ExportBookingsReq;
  return proto.hotel.web_partner.ExportBookingsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.ExportBookingsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.ExportBookingsReq}
 */
proto.hotel.web_partner.ExportBookingsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.hotel.web_partner.ListBookingsFilter;
      reader.readMessage(value,proto.hotel.web_partner.ListBookingsFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.ExportBookingsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.ExportBookingsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.ExportBookingsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.ExportBookingsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.hotel.web_partner.ListBookingsFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional ListBookingsFilter filter = 2;
 * @return {?proto.hotel.web_partner.ListBookingsFilter}
 */
proto.hotel.web_partner.ExportBookingsReq.prototype.getFilter = function() {
  return /** @type{?proto.hotel.web_partner.ListBookingsFilter} */ (
    jspb.Message.getWrapperField(this, proto.hotel.web_partner.ListBookingsFilter, 2));
};


/**
 * @param {?proto.hotel.web_partner.ListBookingsFilter|undefined} value
 * @return {!proto.hotel.web_partner.ExportBookingsReq} returns this
*/
proto.hotel.web_partner.ExportBookingsReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partner.ExportBookingsReq} returns this
 */
proto.hotel.web_partner.ExportBookingsReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partner.ExportBookingsReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web_partner.ExportBookingsRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.ExportBookingsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.ExportBookingsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.ExportBookingsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.ExportBookingsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    hotel_booking_pb.BookingDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.ExportBookingsRes}
 */
proto.hotel.web_partner.ExportBookingsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.ExportBookingsRes;
  return proto.hotel.web_partner.ExportBookingsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.ExportBookingsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.ExportBookingsRes}
 */
proto.hotel.web_partner.ExportBookingsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new hotel_booking_pb.BookingDetail;
      reader.readMessage(value,hotel_booking_pb.BookingDetail.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.ExportBookingsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.ExportBookingsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.ExportBookingsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.ExportBookingsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      hotel_booking_pb.BookingDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.hotel.web_partner.ExportBookingsRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web_partner.ExportBookingsRes} returns this
 */
proto.hotel.web_partner.ExportBookingsRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.hotel.web_partner.ExportBookingsRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.ExportBookingsRes} returns this
 */
proto.hotel.web_partner.ExportBookingsRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated hotel.BookingDetail items = 3;
 * @return {!Array<!proto.hotel.BookingDetail>}
 */
proto.hotel.web_partner.ExportBookingsRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.hotel.BookingDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_booking_pb.BookingDetail, 3));
};


/**
 * @param {!Array<!proto.hotel.BookingDetail>} value
 * @return {!proto.hotel.web_partner.ExportBookingsRes} returns this
*/
proto.hotel.web_partner.ExportBookingsRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.hotel.BookingDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.BookingDetail}
 */
proto.hotel.web_partner.ExportBookingsRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.hotel.BookingDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web_partner.ExportBookingsRes} returns this
 */
proto.hotel.web_partner.ExportBookingsRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.ListBookingSelectionReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.ListBookingSelectionReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.ListBookingSelectionReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.ListBookingSelectionReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    titleQuery: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.ListBookingSelectionReq}
 */
proto.hotel.web_partner.ListBookingSelectionReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.ListBookingSelectionReq;
  return proto.hotel.web_partner.ListBookingSelectionReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.ListBookingSelectionReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.ListBookingSelectionReq}
 */
proto.hotel.web_partner.ListBookingSelectionReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitleQuery(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.ListBookingSelectionReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.ListBookingSelectionReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.ListBookingSelectionReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.ListBookingSelectionReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitleQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
};


/**
 * optional string title_query = 1;
 * @return {string}
 */
proto.hotel.web_partner.ListBookingSelectionReq.prototype.getTitleQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.ListBookingSelectionReq} returns this
 */
proto.hotel.web_partner.ListBookingSelectionReq.prototype.setTitleQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional base.PaginationReq pagination = 2;
 * @return {?proto.base.PaginationReq}
 */
proto.hotel.web_partner.ListBookingSelectionReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 2));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.hotel.web_partner.ListBookingSelectionReq} returns this
*/
proto.hotel.web_partner.ListBookingSelectionReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partner.ListBookingSelectionReq} returns this
 */
proto.hotel.web_partner.ListBookingSelectionReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partner.ListBookingSelectionReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web_partner.ListBookingSelectionRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.ListBookingSelectionRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.ListBookingSelectionRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.ListBookingSelectionRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.ListBookingSelectionRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    hotel_booking_selection_pb.BookingSelectionBasicInfo.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.ListBookingSelectionRes}
 */
proto.hotel.web_partner.ListBookingSelectionRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.ListBookingSelectionRes;
  return proto.hotel.web_partner.ListBookingSelectionRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.ListBookingSelectionRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.ListBookingSelectionRes}
 */
proto.hotel.web_partner.ListBookingSelectionRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new hotel_booking_selection_pb.BookingSelectionBasicInfo;
      reader.readMessage(value,hotel_booking_selection_pb.BookingSelectionBasicInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 4:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.ListBookingSelectionRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.ListBookingSelectionRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.ListBookingSelectionRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.ListBookingSelectionRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      hotel_booking_selection_pb.BookingSelectionBasicInfo.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.hotel.web_partner.ListBookingSelectionRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web_partner.ListBookingSelectionRes} returns this
 */
proto.hotel.web_partner.ListBookingSelectionRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.hotel.web_partner.ListBookingSelectionRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.ListBookingSelectionRes} returns this
 */
proto.hotel.web_partner.ListBookingSelectionRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated hotel.BookingSelectionBasicInfo items = 3;
 * @return {!Array<!proto.hotel.BookingSelectionBasicInfo>}
 */
proto.hotel.web_partner.ListBookingSelectionRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.hotel.BookingSelectionBasicInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_booking_selection_pb.BookingSelectionBasicInfo, 3));
};


/**
 * @param {!Array<!proto.hotel.BookingSelectionBasicInfo>} value
 * @return {!proto.hotel.web_partner.ListBookingSelectionRes} returns this
*/
proto.hotel.web_partner.ListBookingSelectionRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.hotel.BookingSelectionBasicInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.BookingSelectionBasicInfo}
 */
proto.hotel.web_partner.ListBookingSelectionRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.hotel.BookingSelectionBasicInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web_partner.ListBookingSelectionRes} returns this
 */
proto.hotel.web_partner.ListBookingSelectionRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 4;
 * @return {?proto.base.PaginationRes}
 */
proto.hotel.web_partner.ListBookingSelectionRes.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 4));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.hotel.web_partner.ListBookingSelectionRes} returns this
*/
proto.hotel.web_partner.ListBookingSelectionRes.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partner.ListBookingSelectionRes} returns this
 */
proto.hotel.web_partner.ListBookingSelectionRes.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partner.ListBookingSelectionRes.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web_partner.AddBookingContactInfoReq.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.AddBookingContactInfoReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.AddBookingContactInfoReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.AddBookingContactInfoReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.AddBookingContactInfoReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    invoicingInfo: (f = msg.getInvoicingInfo()) && hotel_booking_pb.InvoicingInformation.toObject(includeInstance, f),
    bedOptionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    roomOccupanciesList: jspb.Message.toObjectList(msg.getRoomOccupanciesList(),
    hotel_booking_pb.RoomOccupancyRequest.toObject, includeInstance),
    specialRequest: jspb.Message.getFieldWithDefault(msg, 5, ""),
    commission: (f = msg.getCommission()) && hotel_base_pb.PriceAdjustmentRequest.toObject(includeInstance, f),
    discount: (f = msg.getDiscount()) && hotel_base_pb.PriceAdjustmentRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.AddBookingContactInfoReq}
 */
proto.hotel.web_partner.AddBookingContactInfoReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.AddBookingContactInfoReq;
  return proto.hotel.web_partner.AddBookingContactInfoReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.AddBookingContactInfoReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.AddBookingContactInfoReq}
 */
proto.hotel.web_partner.AddBookingContactInfoReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 2:
      var value = new hotel_booking_pb.InvoicingInformation;
      reader.readMessage(value,hotel_booking_pb.InvoicingInformation.deserializeBinaryFromReader);
      msg.setInvoicingInfo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBedOptionId(value);
      break;
    case 4:
      var value = new hotel_booking_pb.RoomOccupancyRequest;
      reader.readMessage(value,hotel_booking_pb.RoomOccupancyRequest.deserializeBinaryFromReader);
      msg.addRoomOccupancies(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecialRequest(value);
      break;
    case 6:
      var value = new hotel_base_pb.PriceAdjustmentRequest;
      reader.readMessage(value,hotel_base_pb.PriceAdjustmentRequest.deserializeBinaryFromReader);
      msg.setCommission(value);
      break;
    case 7:
      var value = new hotel_base_pb.PriceAdjustmentRequest;
      reader.readMessage(value,hotel_base_pb.PriceAdjustmentRequest.deserializeBinaryFromReader);
      msg.setDiscount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.AddBookingContactInfoReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.AddBookingContactInfoReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.AddBookingContactInfoReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.AddBookingContactInfoReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInvoicingInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hotel_booking_pb.InvoicingInformation.serializeBinaryToWriter
    );
  }
  f = message.getBedOptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRoomOccupanciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      hotel_booking_pb.RoomOccupancyRequest.serializeBinaryToWriter
    );
  }
  f = message.getSpecialRequest();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCommission();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      hotel_base_pb.PriceAdjustmentRequest.serializeBinaryToWriter
    );
  }
  f = message.getDiscount();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      hotel_base_pb.PriceAdjustmentRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.hotel.web_partner.AddBookingContactInfoReq.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.AddBookingContactInfoReq} returns this
 */
proto.hotel.web_partner.AddBookingContactInfoReq.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional hotel.InvoicingInformation invoicing_info = 2;
 * @return {?proto.hotel.InvoicingInformation}
 */
proto.hotel.web_partner.AddBookingContactInfoReq.prototype.getInvoicingInfo = function() {
  return /** @type{?proto.hotel.InvoicingInformation} */ (
    jspb.Message.getWrapperField(this, hotel_booking_pb.InvoicingInformation, 2));
};


/**
 * @param {?proto.hotel.InvoicingInformation|undefined} value
 * @return {!proto.hotel.web_partner.AddBookingContactInfoReq} returns this
*/
proto.hotel.web_partner.AddBookingContactInfoReq.prototype.setInvoicingInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partner.AddBookingContactInfoReq} returns this
 */
proto.hotel.web_partner.AddBookingContactInfoReq.prototype.clearInvoicingInfo = function() {
  return this.setInvoicingInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partner.AddBookingContactInfoReq.prototype.hasInvoicingInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string bed_option_id = 3;
 * @return {string}
 */
proto.hotel.web_partner.AddBookingContactInfoReq.prototype.getBedOptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.AddBookingContactInfoReq} returns this
 */
proto.hotel.web_partner.AddBookingContactInfoReq.prototype.setBedOptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated hotel.RoomOccupancyRequest room_occupancies = 4;
 * @return {!Array<!proto.hotel.RoomOccupancyRequest>}
 */
proto.hotel.web_partner.AddBookingContactInfoReq.prototype.getRoomOccupanciesList = function() {
  return /** @type{!Array<!proto.hotel.RoomOccupancyRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_booking_pb.RoomOccupancyRequest, 4));
};


/**
 * @param {!Array<!proto.hotel.RoomOccupancyRequest>} value
 * @return {!proto.hotel.web_partner.AddBookingContactInfoReq} returns this
*/
proto.hotel.web_partner.AddBookingContactInfoReq.prototype.setRoomOccupanciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.hotel.RoomOccupancyRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.RoomOccupancyRequest}
 */
proto.hotel.web_partner.AddBookingContactInfoReq.prototype.addRoomOccupancies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.hotel.RoomOccupancyRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web_partner.AddBookingContactInfoReq} returns this
 */
proto.hotel.web_partner.AddBookingContactInfoReq.prototype.clearRoomOccupanciesList = function() {
  return this.setRoomOccupanciesList([]);
};


/**
 * optional string special_request = 5;
 * @return {string}
 */
proto.hotel.web_partner.AddBookingContactInfoReq.prototype.getSpecialRequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.AddBookingContactInfoReq} returns this
 */
proto.hotel.web_partner.AddBookingContactInfoReq.prototype.setSpecialRequest = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional hotel.PriceAdjustmentRequest commission = 6;
 * @return {?proto.hotel.PriceAdjustmentRequest}
 */
proto.hotel.web_partner.AddBookingContactInfoReq.prototype.getCommission = function() {
  return /** @type{?proto.hotel.PriceAdjustmentRequest} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.PriceAdjustmentRequest, 6));
};


/**
 * @param {?proto.hotel.PriceAdjustmentRequest|undefined} value
 * @return {!proto.hotel.web_partner.AddBookingContactInfoReq} returns this
*/
proto.hotel.web_partner.AddBookingContactInfoReq.prototype.setCommission = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partner.AddBookingContactInfoReq} returns this
 */
proto.hotel.web_partner.AddBookingContactInfoReq.prototype.clearCommission = function() {
  return this.setCommission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partner.AddBookingContactInfoReq.prototype.hasCommission = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional hotel.PriceAdjustmentRequest discount = 7;
 * @return {?proto.hotel.PriceAdjustmentRequest}
 */
proto.hotel.web_partner.AddBookingContactInfoReq.prototype.getDiscount = function() {
  return /** @type{?proto.hotel.PriceAdjustmentRequest} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.PriceAdjustmentRequest, 7));
};


/**
 * @param {?proto.hotel.PriceAdjustmentRequest|undefined} value
 * @return {!proto.hotel.web_partner.AddBookingContactInfoReq} returns this
*/
proto.hotel.web_partner.AddBookingContactInfoReq.prototype.setDiscount = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partner.AddBookingContactInfoReq} returns this
 */
proto.hotel.web_partner.AddBookingContactInfoReq.prototype.clearDiscount = function() {
  return this.setDiscount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partner.AddBookingContactInfoReq.prototype.hasDiscount = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.AddBookingContactInfoRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.AddBookingContactInfoRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.AddBookingContactInfoRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.AddBookingContactInfoRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orderCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    rate: (f = msg.getRate()) && hotel_hotel_pb.HubRateData.toObject(includeInstance, f),
    expiredAt: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.AddBookingContactInfoRes}
 */
proto.hotel.web_partner.AddBookingContactInfoRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.AddBookingContactInfoRes;
  return proto.hotel.web_partner.AddBookingContactInfoRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.AddBookingContactInfoRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.AddBookingContactInfoRes}
 */
proto.hotel.web_partner.AddBookingContactInfoRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderCode(value);
      break;
    case 4:
      var value = new hotel_hotel_pb.HubRateData;
      reader.readMessage(value,hotel_hotel_pb.HubRateData.deserializeBinaryFromReader);
      msg.setRate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiredAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.AddBookingContactInfoRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.AddBookingContactInfoRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.AddBookingContactInfoRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.AddBookingContactInfoRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrderCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      hotel_hotel_pb.HubRateData.serializeBinaryToWriter
    );
  }
  f = message.getExpiredAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.hotel.web_partner.AddBookingContactInfoRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web_partner.AddBookingContactInfoRes} returns this
 */
proto.hotel.web_partner.AddBookingContactInfoRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.hotel.web_partner.AddBookingContactInfoRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.AddBookingContactInfoRes} returns this
 */
proto.hotel.web_partner.AddBookingContactInfoRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string order_code = 3;
 * @return {string}
 */
proto.hotel.web_partner.AddBookingContactInfoRes.prototype.getOrderCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.AddBookingContactInfoRes} returns this
 */
proto.hotel.web_partner.AddBookingContactInfoRes.prototype.setOrderCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional hotel.HubRateData rate = 4;
 * @return {?proto.hotel.HubRateData}
 */
proto.hotel.web_partner.AddBookingContactInfoRes.prototype.getRate = function() {
  return /** @type{?proto.hotel.HubRateData} */ (
    jspb.Message.getWrapperField(this, hotel_hotel_pb.HubRateData, 4));
};


/**
 * @param {?proto.hotel.HubRateData|undefined} value
 * @return {!proto.hotel.web_partner.AddBookingContactInfoRes} returns this
*/
proto.hotel.web_partner.AddBookingContactInfoRes.prototype.setRate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partner.AddBookingContactInfoRes} returns this
 */
proto.hotel.web_partner.AddBookingContactInfoRes.prototype.clearRate = function() {
  return this.setRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partner.AddBookingContactInfoRes.prototype.hasRate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 expired_at = 5;
 * @return {number}
 */
proto.hotel.web_partner.AddBookingContactInfoRes.prototype.getExpiredAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.web_partner.AddBookingContactInfoRes} returns this
 */
proto.hotel.web_partner.AddBookingContactInfoRes.prototype.setExpiredAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.RetrieveBookingReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.RetrieveBookingReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.RetrieveBookingReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.RetrieveBookingReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    holder: (f = msg.getHolder()) && hotel_booking_pb.RoomHolder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.RetrieveBookingReq}
 */
proto.hotel.web_partner.RetrieveBookingReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.RetrieveBookingReq;
  return proto.hotel.web_partner.RetrieveBookingReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.RetrieveBookingReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.RetrieveBookingReq}
 */
proto.hotel.web_partner.RetrieveBookingReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderCode(value);
      break;
    case 2:
      var value = new hotel_booking_pb.RoomHolder;
      reader.readMessage(value,hotel_booking_pb.RoomHolder.deserializeBinaryFromReader);
      msg.setHolder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.RetrieveBookingReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.RetrieveBookingReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.RetrieveBookingReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.RetrieveBookingReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHolder();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hotel_booking_pb.RoomHolder.serializeBinaryToWriter
    );
  }
};


/**
 * optional string order_code = 1;
 * @return {string}
 */
proto.hotel.web_partner.RetrieveBookingReq.prototype.getOrderCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.RetrieveBookingReq} returns this
 */
proto.hotel.web_partner.RetrieveBookingReq.prototype.setOrderCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional hotel.RoomHolder holder = 2;
 * @return {?proto.hotel.RoomHolder}
 */
proto.hotel.web_partner.RetrieveBookingReq.prototype.getHolder = function() {
  return /** @type{?proto.hotel.RoomHolder} */ (
    jspb.Message.getWrapperField(this, hotel_booking_pb.RoomHolder, 2));
};


/**
 * @param {?proto.hotel.RoomHolder|undefined} value
 * @return {!proto.hotel.web_partner.RetrieveBookingReq} returns this
*/
proto.hotel.web_partner.RetrieveBookingReq.prototype.setHolder = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partner.RetrieveBookingReq} returns this
 */
proto.hotel.web_partner.RetrieveBookingReq.prototype.clearHolder = function() {
  return this.setHolder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partner.RetrieveBookingReq.prototype.hasHolder = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.RetrieveBookingRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.RetrieveBookingRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.RetrieveBookingRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.RetrieveBookingRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    booking: (f = msg.getBooking()) && hotel_booking_pb.BookingDetail.toObject(includeInstance, f),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.RetrieveBookingRes}
 */
proto.hotel.web_partner.RetrieveBookingRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.RetrieveBookingRes;
  return proto.hotel.web_partner.RetrieveBookingRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.RetrieveBookingRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.RetrieveBookingRes}
 */
proto.hotel.web_partner.RetrieveBookingRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new hotel_booking_pb.BookingDetail;
      reader.readMessage(value,hotel_booking_pb.BookingDetail.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.RetrieveBookingRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.RetrieveBookingRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.RetrieveBookingRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.RetrieveBookingRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      hotel_booking_pb.BookingDetail.serializeBinaryToWriter
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional hotel.BookingDetail booking = 1;
 * @return {?proto.hotel.BookingDetail}
 */
proto.hotel.web_partner.RetrieveBookingRes.prototype.getBooking = function() {
  return /** @type{?proto.hotel.BookingDetail} */ (
    jspb.Message.getWrapperField(this, hotel_booking_pb.BookingDetail, 1));
};


/**
 * @param {?proto.hotel.BookingDetail|undefined} value
 * @return {!proto.hotel.web_partner.RetrieveBookingRes} returns this
*/
proto.hotel.web_partner.RetrieveBookingRes.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partner.RetrieveBookingRes} returns this
 */
proto.hotel.web_partner.RetrieveBookingRes.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partner.RetrieveBookingRes.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool is_success = 2;
 * @return {boolean}
 */
proto.hotel.web_partner.RetrieveBookingRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web_partner.RetrieveBookingRes} returns this
 */
proto.hotel.web_partner.RetrieveBookingRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string error_code = 3;
 * @return {string}
 */
proto.hotel.web_partner.RetrieveBookingRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.RetrieveBookingRes} returns this
 */
proto.hotel.web_partner.RetrieveBookingRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.GetStaticBookingBySessionReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.GetStaticBookingBySessionReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.GetStaticBookingBySessionReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.GetStaticBookingBySessionReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.GetStaticBookingBySessionReq}
 */
proto.hotel.web_partner.GetStaticBookingBySessionReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.GetStaticBookingBySessionReq;
  return proto.hotel.web_partner.GetStaticBookingBySessionReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.GetStaticBookingBySessionReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.GetStaticBookingBySessionReq}
 */
proto.hotel.web_partner.GetStaticBookingBySessionReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.GetStaticBookingBySessionReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.GetStaticBookingBySessionReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.GetStaticBookingBySessionReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.GetStaticBookingBySessionReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.hotel.web_partner.GetStaticBookingBySessionReq.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.GetStaticBookingBySessionReq} returns this
 */
proto.hotel.web_partner.GetStaticBookingBySessionReq.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web_partner.GetStaticBookingBySessionRes.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.GetStaticBookingBySessionRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.GetStaticBookingBySessionRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.GetStaticBookingBySessionRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.GetStaticBookingBySessionRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    booking: (f = msg.getBooking()) && hotel_booking_pb.BookingDetail.toObject(includeInstance, f),
    bedOptionsList: jspb.Message.toObjectList(msg.getBedOptionsList(),
    hotel_base_pb.BedOption.toObject, includeInstance),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.GetStaticBookingBySessionRes}
 */
proto.hotel.web_partner.GetStaticBookingBySessionRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.GetStaticBookingBySessionRes;
  return proto.hotel.web_partner.GetStaticBookingBySessionRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.GetStaticBookingBySessionRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.GetStaticBookingBySessionRes}
 */
proto.hotel.web_partner.GetStaticBookingBySessionRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new hotel_booking_pb.BookingDetail;
      reader.readMessage(value,hotel_booking_pb.BookingDetail.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    case 2:
      var value = new hotel_base_pb.BedOption;
      reader.readMessage(value,hotel_base_pb.BedOption.deserializeBinaryFromReader);
      msg.addBedOptions(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.GetStaticBookingBySessionRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.GetStaticBookingBySessionRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.GetStaticBookingBySessionRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.GetStaticBookingBySessionRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      hotel_booking_pb.BookingDetail.serializeBinaryToWriter
    );
  }
  f = message.getBedOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      hotel_base_pb.BedOption.serializeBinaryToWriter
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional hotel.BookingDetail booking = 1;
 * @return {?proto.hotel.BookingDetail}
 */
proto.hotel.web_partner.GetStaticBookingBySessionRes.prototype.getBooking = function() {
  return /** @type{?proto.hotel.BookingDetail} */ (
    jspb.Message.getWrapperField(this, hotel_booking_pb.BookingDetail, 1));
};


/**
 * @param {?proto.hotel.BookingDetail|undefined} value
 * @return {!proto.hotel.web_partner.GetStaticBookingBySessionRes} returns this
*/
proto.hotel.web_partner.GetStaticBookingBySessionRes.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partner.GetStaticBookingBySessionRes} returns this
 */
proto.hotel.web_partner.GetStaticBookingBySessionRes.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partner.GetStaticBookingBySessionRes.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated hotel.BedOption bed_options = 2;
 * @return {!Array<!proto.hotel.BedOption>}
 */
proto.hotel.web_partner.GetStaticBookingBySessionRes.prototype.getBedOptionsList = function() {
  return /** @type{!Array<!proto.hotel.BedOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.BedOption, 2));
};


/**
 * @param {!Array<!proto.hotel.BedOption>} value
 * @return {!proto.hotel.web_partner.GetStaticBookingBySessionRes} returns this
*/
proto.hotel.web_partner.GetStaticBookingBySessionRes.prototype.setBedOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.hotel.BedOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.BedOption}
 */
proto.hotel.web_partner.GetStaticBookingBySessionRes.prototype.addBedOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.hotel.BedOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web_partner.GetStaticBookingBySessionRes} returns this
 */
proto.hotel.web_partner.GetStaticBookingBySessionRes.prototype.clearBedOptionsList = function() {
  return this.setBedOptionsList([]);
};


/**
 * optional bool is_success = 3;
 * @return {boolean}
 */
proto.hotel.web_partner.GetStaticBookingBySessionRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web_partner.GetStaticBookingBySessionRes} returns this
 */
proto.hotel.web_partner.GetStaticBookingBySessionRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string error_code = 4;
 * @return {string}
 */
proto.hotel.web_partner.GetStaticBookingBySessionRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.GetStaticBookingBySessionRes} returns this
 */
proto.hotel.web_partner.GetStaticBookingBySessionRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web_partner.ReserveBookingReq.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.ReserveBookingReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.ReserveBookingReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.ReserveBookingReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.ReserveBookingReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hotelId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    roomId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    rateId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    checkIn: jspb.Message.getFieldWithDefault(msg, 5, ""),
    checkOut: jspb.Message.getFieldWithDefault(msg, 6, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 7, ""),
    roomOccupanciesList: jspb.Message.toObjectList(msg.getRoomOccupanciesList(),
    hotel_booking_pb.RoomOccupancyRequest.toObject, includeInstance),
    destinationId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.ReserveBookingReq}
 */
proto.hotel.web_partner.ReserveBookingReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.ReserveBookingReq;
  return proto.hotel.web_partner.ReserveBookingReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.ReserveBookingReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.ReserveBookingReq}
 */
proto.hotel.web_partner.ReserveBookingReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRateId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckIn(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckOut(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 8:
      var value = new hotel_booking_pb.RoomOccupancyRequest;
      reader.readMessage(value,hotel_booking_pb.RoomOccupancyRequest.deserializeBinaryFromReader);
      msg.addRoomOccupancies(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.ReserveBookingReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.ReserveBookingReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.ReserveBookingReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.ReserveBookingReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHotelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRateId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCheckIn();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCheckOut();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRoomOccupanciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      hotel_booking_pb.RoomOccupancyRequest.serializeBinaryToWriter
    );
  }
  f = message.getDestinationId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string search_key = 1;
 * @return {string}
 */
proto.hotel.web_partner.ReserveBookingReq.prototype.getSearchKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.ReserveBookingReq} returns this
 */
proto.hotel.web_partner.ReserveBookingReq.prototype.setSearchKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string hotel_id = 2;
 * @return {string}
 */
proto.hotel.web_partner.ReserveBookingReq.prototype.getHotelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.ReserveBookingReq} returns this
 */
proto.hotel.web_partner.ReserveBookingReq.prototype.setHotelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string room_id = 3;
 * @return {string}
 */
proto.hotel.web_partner.ReserveBookingReq.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.ReserveBookingReq} returns this
 */
proto.hotel.web_partner.ReserveBookingReq.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string rate_id = 4;
 * @return {string}
 */
proto.hotel.web_partner.ReserveBookingReq.prototype.getRateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.ReserveBookingReq} returns this
 */
proto.hotel.web_partner.ReserveBookingReq.prototype.setRateId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string check_in = 5;
 * @return {string}
 */
proto.hotel.web_partner.ReserveBookingReq.prototype.getCheckIn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.ReserveBookingReq} returns this
 */
proto.hotel.web_partner.ReserveBookingReq.prototype.setCheckIn = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string check_out = 6;
 * @return {string}
 */
proto.hotel.web_partner.ReserveBookingReq.prototype.getCheckOut = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.ReserveBookingReq} returns this
 */
proto.hotel.web_partner.ReserveBookingReq.prototype.setCheckOut = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string currency = 7;
 * @return {string}
 */
proto.hotel.web_partner.ReserveBookingReq.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.ReserveBookingReq} returns this
 */
proto.hotel.web_partner.ReserveBookingReq.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated hotel.RoomOccupancyRequest room_occupancies = 8;
 * @return {!Array<!proto.hotel.RoomOccupancyRequest>}
 */
proto.hotel.web_partner.ReserveBookingReq.prototype.getRoomOccupanciesList = function() {
  return /** @type{!Array<!proto.hotel.RoomOccupancyRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_booking_pb.RoomOccupancyRequest, 8));
};


/**
 * @param {!Array<!proto.hotel.RoomOccupancyRequest>} value
 * @return {!proto.hotel.web_partner.ReserveBookingReq} returns this
*/
proto.hotel.web_partner.ReserveBookingReq.prototype.setRoomOccupanciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.hotel.RoomOccupancyRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.RoomOccupancyRequest}
 */
proto.hotel.web_partner.ReserveBookingReq.prototype.addRoomOccupancies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.hotel.RoomOccupancyRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web_partner.ReserveBookingReq} returns this
 */
proto.hotel.web_partner.ReserveBookingReq.prototype.clearRoomOccupanciesList = function() {
  return this.setRoomOccupanciesList([]);
};


/**
 * optional string destination_id = 9;
 * @return {string}
 */
proto.hotel.web_partner.ReserveBookingReq.prototype.getDestinationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.ReserveBookingReq} returns this
 */
proto.hotel.web_partner.ReserveBookingReq.prototype.setDestinationId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.ReserveBookingRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.ReserveBookingRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.ReserveBookingRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.ReserveBookingRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.ReserveBookingRes}
 */
proto.hotel.web_partner.ReserveBookingRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.ReserveBookingRes;
  return proto.hotel.web_partner.ReserveBookingRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.ReserveBookingRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.ReserveBookingRes}
 */
proto.hotel.web_partner.ReserveBookingRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.ReserveBookingRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.ReserveBookingRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.ReserveBookingRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.ReserveBookingRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.hotel.web_partner.ReserveBookingRes.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.ReserveBookingRes} returns this
 */
proto.hotel.web_partner.ReserveBookingRes.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_success = 3;
 * @return {boolean}
 */
proto.hotel.web_partner.ReserveBookingRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web_partner.ReserveBookingRes} returns this
 */
proto.hotel.web_partner.ReserveBookingRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string error_code = 4;
 * @return {string}
 */
proto.hotel.web_partner.ReserveBookingRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.ReserveBookingRes} returns this
 */
proto.hotel.web_partner.ReserveBookingRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.ReserveBookingSelectionReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.ReserveBookingSelectionReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.ReserveBookingSelectionReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.ReserveBookingSelectionReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    selectionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hotelId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    roomId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    rateId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.ReserveBookingSelectionReq}
 */
proto.hotel.web_partner.ReserveBookingSelectionReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.ReserveBookingSelectionReq;
  return proto.hotel.web_partner.ReserveBookingSelectionReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.ReserveBookingSelectionReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.ReserveBookingSelectionReq}
 */
proto.hotel.web_partner.ReserveBookingSelectionReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelectionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.ReserveBookingSelectionReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.ReserveBookingSelectionReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.ReserveBookingSelectionReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.ReserveBookingSelectionReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelectionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHotelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRateId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string selection_id = 1;
 * @return {string}
 */
proto.hotel.web_partner.ReserveBookingSelectionReq.prototype.getSelectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.ReserveBookingSelectionReq} returns this
 */
proto.hotel.web_partner.ReserveBookingSelectionReq.prototype.setSelectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string hotel_id = 2;
 * @return {string}
 */
proto.hotel.web_partner.ReserveBookingSelectionReq.prototype.getHotelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.ReserveBookingSelectionReq} returns this
 */
proto.hotel.web_partner.ReserveBookingSelectionReq.prototype.setHotelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string room_id = 3;
 * @return {string}
 */
proto.hotel.web_partner.ReserveBookingSelectionReq.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.ReserveBookingSelectionReq} returns this
 */
proto.hotel.web_partner.ReserveBookingSelectionReq.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string rate_id = 4;
 * @return {string}
 */
proto.hotel.web_partner.ReserveBookingSelectionReq.prototype.getRateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.ReserveBookingSelectionReq} returns this
 */
proto.hotel.web_partner.ReserveBookingSelectionReq.prototype.setRateId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.ReserveBookingSelectionRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.ReserveBookingSelectionRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.ReserveBookingSelectionRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.ReserveBookingSelectionRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sessionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.ReserveBookingSelectionRes}
 */
proto.hotel.web_partner.ReserveBookingSelectionRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.ReserveBookingSelectionRes;
  return proto.hotel.web_partner.ReserveBookingSelectionRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.ReserveBookingSelectionRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.ReserveBookingSelectionRes}
 */
proto.hotel.web_partner.ReserveBookingSelectionRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.ReserveBookingSelectionRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.ReserveBookingSelectionRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.ReserveBookingSelectionRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.ReserveBookingSelectionRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.hotel.web_partner.ReserveBookingSelectionRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web_partner.ReserveBookingSelectionRes} returns this
 */
proto.hotel.web_partner.ReserveBookingSelectionRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.hotel.web_partner.ReserveBookingSelectionRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.ReserveBookingSelectionRes} returns this
 */
proto.hotel.web_partner.ReserveBookingSelectionRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string session_id = 3;
 * @return {string}
 */
proto.hotel.web_partner.ReserveBookingSelectionRes.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.ReserveBookingSelectionRes} returns this
 */
proto.hotel.web_partner.ReserveBookingSelectionRes.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web_partner.HotelBookingSelectionReq.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.HotelBookingSelectionReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.HotelBookingSelectionReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.HotelBookingSelectionReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.HotelBookingSelectionReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    hotelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roomsList: jspb.Message.toObjectList(msg.getRoomsList(),
    proto.hotel.web_partner.RoomBookingSelectionReq.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.HotelBookingSelectionReq}
 */
proto.hotel.web_partner.HotelBookingSelectionReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.HotelBookingSelectionReq;
  return proto.hotel.web_partner.HotelBookingSelectionReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.HotelBookingSelectionReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.HotelBookingSelectionReq}
 */
proto.hotel.web_partner.HotelBookingSelectionReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelId(value);
      break;
    case 2:
      var value = new proto.hotel.web_partner.RoomBookingSelectionReq;
      reader.readMessage(value,proto.hotel.web_partner.RoomBookingSelectionReq.deserializeBinaryFromReader);
      msg.addRooms(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.HotelBookingSelectionReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.HotelBookingSelectionReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.HotelBookingSelectionReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.HotelBookingSelectionReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHotelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoomsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.hotel.web_partner.RoomBookingSelectionReq.serializeBinaryToWriter
    );
  }
};


/**
 * optional string hotel_id = 1;
 * @return {string}
 */
proto.hotel.web_partner.HotelBookingSelectionReq.prototype.getHotelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.HotelBookingSelectionReq} returns this
 */
proto.hotel.web_partner.HotelBookingSelectionReq.prototype.setHotelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated RoomBookingSelectionReq rooms = 2;
 * @return {!Array<!proto.hotel.web_partner.RoomBookingSelectionReq>}
 */
proto.hotel.web_partner.HotelBookingSelectionReq.prototype.getRoomsList = function() {
  return /** @type{!Array<!proto.hotel.web_partner.RoomBookingSelectionReq>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hotel.web_partner.RoomBookingSelectionReq, 2));
};


/**
 * @param {!Array<!proto.hotel.web_partner.RoomBookingSelectionReq>} value
 * @return {!proto.hotel.web_partner.HotelBookingSelectionReq} returns this
*/
proto.hotel.web_partner.HotelBookingSelectionReq.prototype.setRoomsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.hotel.web_partner.RoomBookingSelectionReq=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.web_partner.RoomBookingSelectionReq}
 */
proto.hotel.web_partner.HotelBookingSelectionReq.prototype.addRooms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.hotel.web_partner.RoomBookingSelectionReq, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web_partner.HotelBookingSelectionReq} returns this
 */
proto.hotel.web_partner.HotelBookingSelectionReq.prototype.clearRoomsList = function() {
  return this.setRoomsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.RoomBookingSelectionReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.RoomBookingSelectionReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.RoomBookingSelectionReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.RoomBookingSelectionReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rateId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    optionCancelPolicy: jspb.Message.getFieldWithDefault(msg, 3, ""),
    optionService: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.RoomBookingSelectionReq}
 */
proto.hotel.web_partner.RoomBookingSelectionReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.RoomBookingSelectionReq;
  return proto.hotel.web_partner.RoomBookingSelectionReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.RoomBookingSelectionReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.RoomBookingSelectionReq}
 */
proto.hotel.web_partner.RoomBookingSelectionReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRateId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionCancelPolicy(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionService(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.RoomBookingSelectionReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.RoomBookingSelectionReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.RoomBookingSelectionReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.RoomBookingSelectionReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRateId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOptionCancelPolicy();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOptionService();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.hotel.web_partner.RoomBookingSelectionReq.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.RoomBookingSelectionReq} returns this
 */
proto.hotel.web_partner.RoomBookingSelectionReq.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string rate_id = 2;
 * @return {string}
 */
proto.hotel.web_partner.RoomBookingSelectionReq.prototype.getRateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.RoomBookingSelectionReq} returns this
 */
proto.hotel.web_partner.RoomBookingSelectionReq.prototype.setRateId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string option_cancel_policy = 3;
 * @return {string}
 */
proto.hotel.web_partner.RoomBookingSelectionReq.prototype.getOptionCancelPolicy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.RoomBookingSelectionReq} returns this
 */
proto.hotel.web_partner.RoomBookingSelectionReq.prototype.setOptionCancelPolicy = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string option_service = 4;
 * @return {string}
 */
proto.hotel.web_partner.RoomBookingSelectionReq.prototype.getOptionService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.RoomBookingSelectionReq} returns this
 */
proto.hotel.web_partner.RoomBookingSelectionReq.prototype.setOptionService = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web_partner.UpsertBookingSelectionReq.repeatedFields_ = [4,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.UpsertBookingSelectionReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.UpsertBookingSelectionReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.UpsertBookingSelectionReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.UpsertBookingSelectionReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    currentHotelId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    selectionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    hotelSelectionsList: jspb.Message.toObjectList(msg.getHotelSelectionsList(),
    proto.hotel.web_partner.HotelBookingSelectionReq.toObject, includeInstance),
    destinationId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    checkIn: jspb.Message.getFieldWithDefault(msg, 6, ""),
    checkOut: jspb.Message.getFieldWithDefault(msg, 7, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 8, ""),
    roomOccupanciesList: jspb.Message.toObjectList(msg.getRoomOccupanciesList(),
    hotel_booking_pb.RoomOccupancyRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.UpsertBookingSelectionReq}
 */
proto.hotel.web_partner.UpsertBookingSelectionReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.UpsertBookingSelectionReq;
  return proto.hotel.web_partner.UpsertBookingSelectionReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.UpsertBookingSelectionReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.UpsertBookingSelectionReq}
 */
proto.hotel.web_partner.UpsertBookingSelectionReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentHotelId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelectionId(value);
      break;
    case 4:
      var value = new proto.hotel.web_partner.HotelBookingSelectionReq;
      reader.readMessage(value,proto.hotel.web_partner.HotelBookingSelectionReq.deserializeBinaryFromReader);
      msg.addHotelSelections(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckIn(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckOut(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 9:
      var value = new hotel_booking_pb.RoomOccupancyRequest;
      reader.readMessage(value,hotel_booking_pb.RoomOccupancyRequest.deserializeBinaryFromReader);
      msg.addRoomOccupancies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.UpsertBookingSelectionReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.UpsertBookingSelectionReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.UpsertBookingSelectionReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.UpsertBookingSelectionReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCurrentHotelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSelectionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHotelSelectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.hotel.web_partner.HotelBookingSelectionReq.serializeBinaryToWriter
    );
  }
  f = message.getDestinationId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCheckIn();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCheckOut();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRoomOccupanciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      hotel_booking_pb.RoomOccupancyRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string search_key = 1;
 * @return {string}
 */
proto.hotel.web_partner.UpsertBookingSelectionReq.prototype.getSearchKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.UpsertBookingSelectionReq} returns this
 */
proto.hotel.web_partner.UpsertBookingSelectionReq.prototype.setSearchKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string current_hotel_id = 2;
 * @return {string}
 */
proto.hotel.web_partner.UpsertBookingSelectionReq.prototype.getCurrentHotelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.UpsertBookingSelectionReq} returns this
 */
proto.hotel.web_partner.UpsertBookingSelectionReq.prototype.setCurrentHotelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string selection_id = 3;
 * @return {string}
 */
proto.hotel.web_partner.UpsertBookingSelectionReq.prototype.getSelectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.UpsertBookingSelectionReq} returns this
 */
proto.hotel.web_partner.UpsertBookingSelectionReq.prototype.setSelectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated HotelBookingSelectionReq hotel_selections = 4;
 * @return {!Array<!proto.hotel.web_partner.HotelBookingSelectionReq>}
 */
proto.hotel.web_partner.UpsertBookingSelectionReq.prototype.getHotelSelectionsList = function() {
  return /** @type{!Array<!proto.hotel.web_partner.HotelBookingSelectionReq>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hotel.web_partner.HotelBookingSelectionReq, 4));
};


/**
 * @param {!Array<!proto.hotel.web_partner.HotelBookingSelectionReq>} value
 * @return {!proto.hotel.web_partner.UpsertBookingSelectionReq} returns this
*/
proto.hotel.web_partner.UpsertBookingSelectionReq.prototype.setHotelSelectionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.hotel.web_partner.HotelBookingSelectionReq=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.web_partner.HotelBookingSelectionReq}
 */
proto.hotel.web_partner.UpsertBookingSelectionReq.prototype.addHotelSelections = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.hotel.web_partner.HotelBookingSelectionReq, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web_partner.UpsertBookingSelectionReq} returns this
 */
proto.hotel.web_partner.UpsertBookingSelectionReq.prototype.clearHotelSelectionsList = function() {
  return this.setHotelSelectionsList([]);
};


/**
 * optional string destination_id = 5;
 * @return {string}
 */
proto.hotel.web_partner.UpsertBookingSelectionReq.prototype.getDestinationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.UpsertBookingSelectionReq} returns this
 */
proto.hotel.web_partner.UpsertBookingSelectionReq.prototype.setDestinationId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string check_in = 6;
 * @return {string}
 */
proto.hotel.web_partner.UpsertBookingSelectionReq.prototype.getCheckIn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.UpsertBookingSelectionReq} returns this
 */
proto.hotel.web_partner.UpsertBookingSelectionReq.prototype.setCheckIn = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string check_out = 7;
 * @return {string}
 */
proto.hotel.web_partner.UpsertBookingSelectionReq.prototype.getCheckOut = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.UpsertBookingSelectionReq} returns this
 */
proto.hotel.web_partner.UpsertBookingSelectionReq.prototype.setCheckOut = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string currency = 8;
 * @return {string}
 */
proto.hotel.web_partner.UpsertBookingSelectionReq.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.UpsertBookingSelectionReq} returns this
 */
proto.hotel.web_partner.UpsertBookingSelectionReq.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated hotel.RoomOccupancyRequest room_occupancies = 9;
 * @return {!Array<!proto.hotel.RoomOccupancyRequest>}
 */
proto.hotel.web_partner.UpsertBookingSelectionReq.prototype.getRoomOccupanciesList = function() {
  return /** @type{!Array<!proto.hotel.RoomOccupancyRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_booking_pb.RoomOccupancyRequest, 9));
};


/**
 * @param {!Array<!proto.hotel.RoomOccupancyRequest>} value
 * @return {!proto.hotel.web_partner.UpsertBookingSelectionReq} returns this
*/
proto.hotel.web_partner.UpsertBookingSelectionReq.prototype.setRoomOccupanciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.hotel.RoomOccupancyRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.RoomOccupancyRequest}
 */
proto.hotel.web_partner.UpsertBookingSelectionReq.prototype.addRoomOccupancies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.hotel.RoomOccupancyRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web_partner.UpsertBookingSelectionReq} returns this
 */
proto.hotel.web_partner.UpsertBookingSelectionReq.prototype.clearRoomOccupanciesList = function() {
  return this.setRoomOccupanciesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.UpsertBookingSelectionRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.UpsertBookingSelectionRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.UpsertBookingSelectionRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.UpsertBookingSelectionRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    selectionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.UpsertBookingSelectionRes}
 */
proto.hotel.web_partner.UpsertBookingSelectionRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.UpsertBookingSelectionRes;
  return proto.hotel.web_partner.UpsertBookingSelectionRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.UpsertBookingSelectionRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.UpsertBookingSelectionRes}
 */
proto.hotel.web_partner.UpsertBookingSelectionRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelectionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.UpsertBookingSelectionRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.UpsertBookingSelectionRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.UpsertBookingSelectionRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.UpsertBookingSelectionRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSelectionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.hotel.web_partner.UpsertBookingSelectionRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web_partner.UpsertBookingSelectionRes} returns this
 */
proto.hotel.web_partner.UpsertBookingSelectionRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.hotel.web_partner.UpsertBookingSelectionRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.UpsertBookingSelectionRes} returns this
 */
proto.hotel.web_partner.UpsertBookingSelectionRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string selection_id = 3;
 * @return {string}
 */
proto.hotel.web_partner.UpsertBookingSelectionRes.prototype.getSelectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.UpsertBookingSelectionRes} returns this
 */
proto.hotel.web_partner.UpsertBookingSelectionRes.prototype.setSelectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.GetBookingSelectionReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.GetBookingSelectionReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.GetBookingSelectionReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.GetBookingSelectionReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    selectionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.GetBookingSelectionReq}
 */
proto.hotel.web_partner.GetBookingSelectionReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.GetBookingSelectionReq;
  return proto.hotel.web_partner.GetBookingSelectionReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.GetBookingSelectionReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.GetBookingSelectionReq}
 */
proto.hotel.web_partner.GetBookingSelectionReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelectionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.GetBookingSelectionReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.GetBookingSelectionReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.GetBookingSelectionReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.GetBookingSelectionReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelectionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string selection_id = 1;
 * @return {string}
 */
proto.hotel.web_partner.GetBookingSelectionReq.prototype.getSelectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.GetBookingSelectionReq} returns this
 */
proto.hotel.web_partner.GetBookingSelectionReq.prototype.setSelectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.GetBookingSelectionRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.GetBookingSelectionRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.GetBookingSelectionRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.GetBookingSelectionRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    selection: (f = msg.getSelection()) && hotel_booking_selection_pb.BookingSelection.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.GetBookingSelectionRes}
 */
proto.hotel.web_partner.GetBookingSelectionRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.GetBookingSelectionRes;
  return proto.hotel.web_partner.GetBookingSelectionRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.GetBookingSelectionRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.GetBookingSelectionRes}
 */
proto.hotel.web_partner.GetBookingSelectionRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new hotel_booking_selection_pb.BookingSelection;
      reader.readMessage(value,hotel_booking_selection_pb.BookingSelection.deserializeBinaryFromReader);
      msg.setSelection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.GetBookingSelectionRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.GetBookingSelectionRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.GetBookingSelectionRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.GetBookingSelectionRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSelection();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      hotel_booking_selection_pb.BookingSelection.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.hotel.web_partner.GetBookingSelectionRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web_partner.GetBookingSelectionRes} returns this
 */
proto.hotel.web_partner.GetBookingSelectionRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.hotel.web_partner.GetBookingSelectionRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.GetBookingSelectionRes} returns this
 */
proto.hotel.web_partner.GetBookingSelectionRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional hotel.BookingSelection selection = 3;
 * @return {?proto.hotel.BookingSelection}
 */
proto.hotel.web_partner.GetBookingSelectionRes.prototype.getSelection = function() {
  return /** @type{?proto.hotel.BookingSelection} */ (
    jspb.Message.getWrapperField(this, hotel_booking_selection_pb.BookingSelection, 3));
};


/**
 * @param {?proto.hotel.BookingSelection|undefined} value
 * @return {!proto.hotel.web_partner.GetBookingSelectionRes} returns this
*/
proto.hotel.web_partner.GetBookingSelectionRes.prototype.setSelection = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partner.GetBookingSelectionRes} returns this
 */
proto.hotel.web_partner.GetBookingSelectionRes.prototype.clearSelection = function() {
  return this.setSelection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partner.GetBookingSelectionRes.prototype.hasSelection = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.DeleteBookingSelectionReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.DeleteBookingSelectionReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.DeleteBookingSelectionReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.DeleteBookingSelectionReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    selectionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.DeleteBookingSelectionReq}
 */
proto.hotel.web_partner.DeleteBookingSelectionReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.DeleteBookingSelectionReq;
  return proto.hotel.web_partner.DeleteBookingSelectionReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.DeleteBookingSelectionReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.DeleteBookingSelectionReq}
 */
proto.hotel.web_partner.DeleteBookingSelectionReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelectionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.DeleteBookingSelectionReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.DeleteBookingSelectionReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.DeleteBookingSelectionReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.DeleteBookingSelectionReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelectionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string selection_id = 1;
 * @return {string}
 */
proto.hotel.web_partner.DeleteBookingSelectionReq.prototype.getSelectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.DeleteBookingSelectionReq} returns this
 */
proto.hotel.web_partner.DeleteBookingSelectionReq.prototype.setSelectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.DeleteBookingSelectionRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.DeleteBookingSelectionRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.DeleteBookingSelectionRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.DeleteBookingSelectionRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.DeleteBookingSelectionRes}
 */
proto.hotel.web_partner.DeleteBookingSelectionRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.DeleteBookingSelectionRes;
  return proto.hotel.web_partner.DeleteBookingSelectionRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.DeleteBookingSelectionRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.DeleteBookingSelectionRes}
 */
proto.hotel.web_partner.DeleteBookingSelectionRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.DeleteBookingSelectionRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.DeleteBookingSelectionRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.DeleteBookingSelectionRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.DeleteBookingSelectionRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.hotel.web_partner.DeleteBookingSelectionRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web_partner.DeleteBookingSelectionRes} returns this
 */
proto.hotel.web_partner.DeleteBookingSelectionRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.hotel.web_partner.DeleteBookingSelectionRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.DeleteBookingSelectionRes} returns this
 */
proto.hotel.web_partner.DeleteBookingSelectionRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.DeleteRateSelectionReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.DeleteRateSelectionReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.DeleteRateSelectionReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.DeleteRateSelectionReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    selectionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hotelId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    roomId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    rateId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.DeleteRateSelectionReq}
 */
proto.hotel.web_partner.DeleteRateSelectionReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.DeleteRateSelectionReq;
  return proto.hotel.web_partner.DeleteRateSelectionReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.DeleteRateSelectionReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.DeleteRateSelectionReq}
 */
proto.hotel.web_partner.DeleteRateSelectionReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelectionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.DeleteRateSelectionReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.DeleteRateSelectionReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.DeleteRateSelectionReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.DeleteRateSelectionReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelectionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHotelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRateId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string selection_id = 1;
 * @return {string}
 */
proto.hotel.web_partner.DeleteRateSelectionReq.prototype.getSelectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.DeleteRateSelectionReq} returns this
 */
proto.hotel.web_partner.DeleteRateSelectionReq.prototype.setSelectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string hotel_id = 2;
 * @return {string}
 */
proto.hotel.web_partner.DeleteRateSelectionReq.prototype.getHotelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.DeleteRateSelectionReq} returns this
 */
proto.hotel.web_partner.DeleteRateSelectionReq.prototype.setHotelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string room_id = 3;
 * @return {string}
 */
proto.hotel.web_partner.DeleteRateSelectionReq.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.DeleteRateSelectionReq} returns this
 */
proto.hotel.web_partner.DeleteRateSelectionReq.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string rate_id = 4;
 * @return {string}
 */
proto.hotel.web_partner.DeleteRateSelectionReq.prototype.getRateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.DeleteRateSelectionReq} returns this
 */
proto.hotel.web_partner.DeleteRateSelectionReq.prototype.setRateId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.DeleteRateSelectionRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.DeleteRateSelectionRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.DeleteRateSelectionRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.DeleteRateSelectionRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.DeleteRateSelectionRes}
 */
proto.hotel.web_partner.DeleteRateSelectionRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.DeleteRateSelectionRes;
  return proto.hotel.web_partner.DeleteRateSelectionRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.DeleteRateSelectionRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.DeleteRateSelectionRes}
 */
proto.hotel.web_partner.DeleteRateSelectionRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.DeleteRateSelectionRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.DeleteRateSelectionRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.DeleteRateSelectionRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.DeleteRateSelectionRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.hotel.web_partner.DeleteRateSelectionRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web_partner.DeleteRateSelectionRes} returns this
 */
proto.hotel.web_partner.DeleteRateSelectionRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.hotel.web_partner.DeleteRateSelectionRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.DeleteRateSelectionRes} returns this
 */
proto.hotel.web_partner.DeleteRateSelectionRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.UpdatePriceAdjustment.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.UpdatePriceAdjustment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.UpdatePriceAdjustment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.UpdatePriceAdjustment.toObject = function(includeInstance, msg) {
  var f, obj = {
    hotelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roomId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rateId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    commission: (f = msg.getCommission()) && hotel_base_pb.PriceAdjustmentRequest.toObject(includeInstance, f),
    discount: (f = msg.getDiscount()) && hotel_base_pb.PriceAdjustmentRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.UpdatePriceAdjustment}
 */
proto.hotel.web_partner.UpdatePriceAdjustment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.UpdatePriceAdjustment;
  return proto.hotel.web_partner.UpdatePriceAdjustment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.UpdatePriceAdjustment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.UpdatePriceAdjustment}
 */
proto.hotel.web_partner.UpdatePriceAdjustment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRateId(value);
      break;
    case 4:
      var value = new hotel_base_pb.PriceAdjustmentRequest;
      reader.readMessage(value,hotel_base_pb.PriceAdjustmentRequest.deserializeBinaryFromReader);
      msg.setCommission(value);
      break;
    case 5:
      var value = new hotel_base_pb.PriceAdjustmentRequest;
      reader.readMessage(value,hotel_base_pb.PriceAdjustmentRequest.deserializeBinaryFromReader);
      msg.setDiscount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.UpdatePriceAdjustment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.UpdatePriceAdjustment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.UpdatePriceAdjustment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.UpdatePriceAdjustment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHotelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRateId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCommission();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      hotel_base_pb.PriceAdjustmentRequest.serializeBinaryToWriter
    );
  }
  f = message.getDiscount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      hotel_base_pb.PriceAdjustmentRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string hotel_id = 1;
 * @return {string}
 */
proto.hotel.web_partner.UpdatePriceAdjustment.prototype.getHotelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.UpdatePriceAdjustment} returns this
 */
proto.hotel.web_partner.UpdatePriceAdjustment.prototype.setHotelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string room_id = 2;
 * @return {string}
 */
proto.hotel.web_partner.UpdatePriceAdjustment.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.UpdatePriceAdjustment} returns this
 */
proto.hotel.web_partner.UpdatePriceAdjustment.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string rate_id = 3;
 * @return {string}
 */
proto.hotel.web_partner.UpdatePriceAdjustment.prototype.getRateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.UpdatePriceAdjustment} returns this
 */
proto.hotel.web_partner.UpdatePriceAdjustment.prototype.setRateId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional hotel.PriceAdjustmentRequest commission = 4;
 * @return {?proto.hotel.PriceAdjustmentRequest}
 */
proto.hotel.web_partner.UpdatePriceAdjustment.prototype.getCommission = function() {
  return /** @type{?proto.hotel.PriceAdjustmentRequest} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.PriceAdjustmentRequest, 4));
};


/**
 * @param {?proto.hotel.PriceAdjustmentRequest|undefined} value
 * @return {!proto.hotel.web_partner.UpdatePriceAdjustment} returns this
*/
proto.hotel.web_partner.UpdatePriceAdjustment.prototype.setCommission = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partner.UpdatePriceAdjustment} returns this
 */
proto.hotel.web_partner.UpdatePriceAdjustment.prototype.clearCommission = function() {
  return this.setCommission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partner.UpdatePriceAdjustment.prototype.hasCommission = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional hotel.PriceAdjustmentRequest discount = 5;
 * @return {?proto.hotel.PriceAdjustmentRequest}
 */
proto.hotel.web_partner.UpdatePriceAdjustment.prototype.getDiscount = function() {
  return /** @type{?proto.hotel.PriceAdjustmentRequest} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.PriceAdjustmentRequest, 5));
};


/**
 * @param {?proto.hotel.PriceAdjustmentRequest|undefined} value
 * @return {!proto.hotel.web_partner.UpdatePriceAdjustment} returns this
*/
proto.hotel.web_partner.UpdatePriceAdjustment.prototype.setDiscount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partner.UpdatePriceAdjustment} returns this
 */
proto.hotel.web_partner.UpdatePriceAdjustment.prototype.clearDiscount = function() {
  return this.setDiscount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partner.UpdatePriceAdjustment.prototype.hasDiscount = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web_partner.UpdateBookingSelectionReq.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.UpdateBookingSelectionReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.UpdateBookingSelectionReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.UpdateBookingSelectionReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.UpdateBookingSelectionReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    selectionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    priceAdjustmentsList: jspb.Message.toObjectList(msg.getPriceAdjustmentsList(),
    proto.hotel.web_partner.UpdatePriceAdjustment.toObject, includeInstance),
    title: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.UpdateBookingSelectionReq}
 */
proto.hotel.web_partner.UpdateBookingSelectionReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.UpdateBookingSelectionReq;
  return proto.hotel.web_partner.UpdateBookingSelectionReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.UpdateBookingSelectionReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.UpdateBookingSelectionReq}
 */
proto.hotel.web_partner.UpdateBookingSelectionReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelectionId(value);
      break;
    case 2:
      var value = new proto.hotel.web_partner.UpdatePriceAdjustment;
      reader.readMessage(value,proto.hotel.web_partner.UpdatePriceAdjustment.deserializeBinaryFromReader);
      msg.addPriceAdjustments(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.UpdateBookingSelectionReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.UpdateBookingSelectionReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.UpdateBookingSelectionReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.UpdateBookingSelectionReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelectionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPriceAdjustmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.hotel.web_partner.UpdatePriceAdjustment.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string selection_id = 1;
 * @return {string}
 */
proto.hotel.web_partner.UpdateBookingSelectionReq.prototype.getSelectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.UpdateBookingSelectionReq} returns this
 */
proto.hotel.web_partner.UpdateBookingSelectionReq.prototype.setSelectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated UpdatePriceAdjustment price_adjustments = 2;
 * @return {!Array<!proto.hotel.web_partner.UpdatePriceAdjustment>}
 */
proto.hotel.web_partner.UpdateBookingSelectionReq.prototype.getPriceAdjustmentsList = function() {
  return /** @type{!Array<!proto.hotel.web_partner.UpdatePriceAdjustment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hotel.web_partner.UpdatePriceAdjustment, 2));
};


/**
 * @param {!Array<!proto.hotel.web_partner.UpdatePriceAdjustment>} value
 * @return {!proto.hotel.web_partner.UpdateBookingSelectionReq} returns this
*/
proto.hotel.web_partner.UpdateBookingSelectionReq.prototype.setPriceAdjustmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.hotel.web_partner.UpdatePriceAdjustment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.web_partner.UpdatePriceAdjustment}
 */
proto.hotel.web_partner.UpdateBookingSelectionReq.prototype.addPriceAdjustments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.hotel.web_partner.UpdatePriceAdjustment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web_partner.UpdateBookingSelectionReq} returns this
 */
proto.hotel.web_partner.UpdateBookingSelectionReq.prototype.clearPriceAdjustmentsList = function() {
  return this.setPriceAdjustmentsList([]);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.hotel.web_partner.UpdateBookingSelectionReq.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.UpdateBookingSelectionReq} returns this
 */
proto.hotel.web_partner.UpdateBookingSelectionReq.prototype.setTitle = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hotel.web_partner.UpdateBookingSelectionReq} returns this
 */
proto.hotel.web_partner.UpdateBookingSelectionReq.prototype.clearTitle = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partner.UpdateBookingSelectionReq.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.UpdateBookingSelectionRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.UpdateBookingSelectionRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.UpdateBookingSelectionRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.UpdateBookingSelectionRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.UpdateBookingSelectionRes}
 */
proto.hotel.web_partner.UpdateBookingSelectionRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.UpdateBookingSelectionRes;
  return proto.hotel.web_partner.UpdateBookingSelectionRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.UpdateBookingSelectionRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.UpdateBookingSelectionRes}
 */
proto.hotel.web_partner.UpdateBookingSelectionRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.UpdateBookingSelectionRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.UpdateBookingSelectionRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.UpdateBookingSelectionRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.UpdateBookingSelectionRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.hotel.web_partner.UpdateBookingSelectionRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web_partner.UpdateBookingSelectionRes} returns this
 */
proto.hotel.web_partner.UpdateBookingSelectionRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.hotel.web_partner.UpdateBookingSelectionRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.UpdateBookingSelectionRes} returns this
 */
proto.hotel.web_partner.UpdateBookingSelectionRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.CancelBookingReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.CancelBookingReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.CancelBookingReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.CancelBookingReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.CancelBookingReq}
 */
proto.hotel.web_partner.CancelBookingReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.CancelBookingReq;
  return proto.hotel.web_partner.CancelBookingReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.CancelBookingReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.CancelBookingReq}
 */
proto.hotel.web_partner.CancelBookingReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.CancelBookingReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.CancelBookingReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.CancelBookingReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.CancelBookingReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string order_code = 1;
 * @return {string}
 */
proto.hotel.web_partner.CancelBookingReq.prototype.getOrderCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.CancelBookingReq} returns this
 */
proto.hotel.web_partner.CancelBookingReq.prototype.setOrderCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.CancelBookingRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.CancelBookingRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.CancelBookingRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.CancelBookingRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.CancelBookingRes}
 */
proto.hotel.web_partner.CancelBookingRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.CancelBookingRes;
  return proto.hotel.web_partner.CancelBookingRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.CancelBookingRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.CancelBookingRes}
 */
proto.hotel.web_partner.CancelBookingRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.CancelBookingRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.CancelBookingRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.CancelBookingRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.CancelBookingRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.hotel.web_partner.CancelBookingRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web_partner.CancelBookingRes} returns this
 */
proto.hotel.web_partner.CancelBookingRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.hotel.web_partner.CancelBookingRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.CancelBookingRes} returns this
 */
proto.hotel.web_partner.CancelBookingRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.OrderBookingReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.OrderBookingReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.OrderBookingReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.OrderBookingReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.OrderBookingReq}
 */
proto.hotel.web_partner.OrderBookingReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.OrderBookingReq;
  return proto.hotel.web_partner.OrderBookingReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.OrderBookingReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.OrderBookingReq}
 */
proto.hotel.web_partner.OrderBookingReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.OrderBookingReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.OrderBookingReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.OrderBookingReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.OrderBookingReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string order_code = 1;
 * @return {string}
 */
proto.hotel.web_partner.OrderBookingReq.prototype.getOrderCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.OrderBookingReq} returns this
 */
proto.hotel.web_partner.OrderBookingReq.prototype.setOrderCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.OrderBookingRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.OrderBookingRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.OrderBookingRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.OrderBookingRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orderCode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.OrderBookingRes}
 */
proto.hotel.web_partner.OrderBookingRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.OrderBookingRes;
  return proto.hotel.web_partner.OrderBookingRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.OrderBookingRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.OrderBookingRes}
 */
proto.hotel.web_partner.OrderBookingRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.OrderBookingRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.OrderBookingRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.OrderBookingRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.OrderBookingRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrderCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.hotel.web_partner.OrderBookingRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web_partner.OrderBookingRes} returns this
 */
proto.hotel.web_partner.OrderBookingRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.hotel.web_partner.OrderBookingRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.OrderBookingRes} returns this
 */
proto.hotel.web_partner.OrderBookingRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string order_code = 3;
 * @return {string}
 */
proto.hotel.web_partner.OrderBookingRes.prototype.getOrderCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.OrderBookingRes} returns this
 */
proto.hotel.web_partner.OrderBookingRes.prototype.setOrderCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.PriceCheckReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.PriceCheckReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.PriceCheckReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.PriceCheckReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stateful: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.PriceCheckReq}
 */
proto.hotel.web_partner.PriceCheckReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.PriceCheckReq;
  return proto.hotel.web_partner.PriceCheckReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.PriceCheckReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.PriceCheckReq}
 */
proto.hotel.web_partner.PriceCheckReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStateful(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.PriceCheckReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.PriceCheckReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.PriceCheckReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.PriceCheckReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStateful();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.hotel.web_partner.PriceCheckReq.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.PriceCheckReq} returns this
 */
proto.hotel.web_partner.PriceCheckReq.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool stateful = 2;
 * @return {boolean}
 */
proto.hotel.web_partner.PriceCheckReq.prototype.getStateful = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web_partner.PriceCheckReq} returns this
 */
proto.hotel.web_partner.PriceCheckReq.prototype.setStateful = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partner.PriceCheckRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partner.PriceCheckRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partner.PriceCheckRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.PriceCheckRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rate: (f = msg.getRate()) && hotel_hotel_pb.HubRateData.toObject(includeInstance, f),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partner.PriceCheckRes}
 */
proto.hotel.web_partner.PriceCheckRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partner.PriceCheckRes;
  return proto.hotel.web_partner.PriceCheckRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partner.PriceCheckRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partner.PriceCheckRes}
 */
proto.hotel.web_partner.PriceCheckRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = new hotel_hotel_pb.HubRateData;
      reader.readMessage(value,hotel_hotel_pb.HubRateData.deserializeBinaryFromReader);
      msg.setRate(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partner.PriceCheckRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partner.PriceCheckRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partner.PriceCheckRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partner.PriceCheckRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      hotel_hotel_pb.HubRateData.serializeBinaryToWriter
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.hotel.web_partner.PriceCheckRes.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.PriceCheckRes} returns this
 */
proto.hotel.web_partner.PriceCheckRes.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional hotel.HubRateData rate = 2;
 * @return {?proto.hotel.HubRateData}
 */
proto.hotel.web_partner.PriceCheckRes.prototype.getRate = function() {
  return /** @type{?proto.hotel.HubRateData} */ (
    jspb.Message.getWrapperField(this, hotel_hotel_pb.HubRateData, 2));
};


/**
 * @param {?proto.hotel.HubRateData|undefined} value
 * @return {!proto.hotel.web_partner.PriceCheckRes} returns this
*/
proto.hotel.web_partner.PriceCheckRes.prototype.setRate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partner.PriceCheckRes} returns this
 */
proto.hotel.web_partner.PriceCheckRes.prototype.clearRate = function() {
  return this.setRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partner.PriceCheckRes.prototype.hasRate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool is_success = 3;
 * @return {boolean}
 */
proto.hotel.web_partner.PriceCheckRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web_partner.PriceCheckRes} returns this
 */
proto.hotel.web_partner.PriceCheckRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string error_code = 4;
 * @return {string}
 */
proto.hotel.web_partner.PriceCheckRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partner.PriceCheckRes} returns this
 */
proto.hotel.web_partner.PriceCheckRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


goog.object.extend(exports, proto.hotel.web_partner);
