import { getApiErrorMessages } from '@core/utils';
import { useAppMutation } from '@sky-booking-config/hooks/internals';
import { IOfficeInfo } from '@tixlabs/grpc-client';
import {
  partnerService,
} from '@tixlabs/grpc-client/web-partnership';
import { SelectOptionItem } from '@tixlabs/types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export type TPagination = {
  pageCurrent: number;
  totalPage: number;
  totalRecord: number;
  pageLimit: number;
};

type TOfficeIdListForm = {
  agentCode: string;
  provider: number;
};

const useManageOfficeId = () => {
  const manageOfficeIdMethod = useForm<TOfficeIdListForm>({
    defaultValues: {
      agentCode: '',
      provider: 0,
    },
  });
  const navigate = useNavigate();

  const [officeIdList, setOfficeIdList] = useState<IOfficeInfo[]>([]);
  const [pagination, setPagination] = useState<TPagination>({
    pageCurrent: 1,
    pageLimit: 10,
    totalPage: 1,
    totalRecord: 1,
  });
  const [allProviders, setAllProviderOption] = useState<SelectOptionItem[]>([]);

  const { mutateAsync: listMyOffices, isLoading: isLoadingGetUserList } =
    useAppMutation({
      mutationKey: ['partnerUserServiceClient', 'getUsersV2'],
      mutationFn: partnerService.listMyOffices,
    });

  const { mutateAsync: getProviders, isLoading: isLoadingProviders } =
    useAppMutation({
      mutationKey: ['partnerService', 'getProviders'],
      mutationFn: partnerService.getProviders,
    });

  const handleSubmit = async (formData: TOfficeIdListForm) => {
    try {
      const {
        isSuccess,
        errorCode,
        itemsList,
        pagination: paginationRes,
      } = await listMyOffices({
        filter: {
          ...formData,
        },
        pagination: {
          pageNumber: 1,
          pageLimit: pagination.pageLimit,
        },
      });

      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }

      setOfficeIdList(itemsList);

      paginationRes && setPagination(paginationRes);
    } catch (error) {
      console.log('listMyOffices: ', error);
    }
  };

  const changePage = async (pageNumber: number) => {
    const formData = manageOfficeIdMethod.getValues();
    try {
      const {
        isSuccess,
        errorCode,
        itemsList,
        pagination: paginationRes,
      } = await listMyOffices({
        // filter: {
        //   ...formData,
        // },
        pagination: {
          pageNumber,
          pageLimit: pagination.pageLimit,
        },
      });

      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }

      setOfficeIdList(itemsList);

      paginationRes && setPagination(paginationRes);
    } catch (error) {
      console.log('listMyOffices: ', error);
    }
  };

  const getAllProviderOption = async () => {
    try {
      const { itemsList } = await getProviders({});
      const listOption = itemsList.map<SelectOptionItem>((item) => {
        return { label: item.name, value: item.value };
      });
      setAllProviderOption(listOption);
    } catch (error) {
      const msg = getApiErrorMessages(error)[0];
      console.log('Error get provider:', msg);
    }
  };

  return {
    allProviders,
    officeIdList,
    pagination,
    isLoadingGetUserList,
    manageOfficeIdMethod,
    changePage,
    handleSubmit,
    getAllProviderOption,
  };
};

export default useManageOfficeId;
